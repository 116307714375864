import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useContext, useEffect, useState } from "react";

import { SnackBarContext } from "../../../contexts/snackBarContext";
import { depotURL } from "../../../static/constants/backendRoutes";
import UseAuth from "../../auth/useAuth";
import {
  errorHandler,
  fetchCountries,
  fetchStates,
  Nominatim,
} from "../../utils";
import InputMap from "../maps/inputMap";

const resetFieldsTouched = {
  address1: false,
  city: false,
  state_code: false,
  country_code: false,
  zipcode: false,
};

/**
 * new/edit depot dialog box
 * @param {*} presetProjectId
 * @param {Object} [projectLookup={}]
 * @param {*} open
 * @param {*} setOpen
 */
export default function DepotDialog({
  open,
  setOpen,
  presetProjectId,
  projectLookup = {},
  allDepots,
  setAllDepots,
  rowUpdateData,
  setRowUpdateData,
}) {
  /** @type {[{name: String, iso2: String, phone_code: String, emoji: String}[]]} */
  const [countryList, setCountryList] = useState([]);
  /** @type {[ {name: String, state_code: String}[]]} */
  const [stateList, setStateList] = useState([]);
  const [loading, setLoading] = useState(false);

  //fields touched is used to prevent over-querying of nominatim without changes to address
  const [fieldsTouched, setFieldsTouched] = useState({ ...resetFieldsTouched });
  const { snackBarElement } = useContext(SnackBarContext);

  const countryLookup = countryList.reduce(
    (obj, country) => Object.assign(obj, { [country.iso2]: country.name }),
    {}
  );

  const stateLookup = stateList.reduce(
    (obj, state) => Object.assign(obj, { [state.state_code]: state.name }),
    {}
  );

  useEffect(() => {
    async function fetchData() {
      const countryList = await fetchCountries(snackBarElement);
      setCountryList(countryList);
    }
    fetchData();
  }, []);

  useEffect(() => {
    //fires every time the user changes the country
    async function fetchData() {
      const stateList = await fetchStates(
        rowUpdateData.country_code,
        snackBarElement
      );
      setStateList(stateList);
    }
    // if the dialog box is open, and there is a pre-selected project, you should fetch the state inputs (either in edit view, or there is a project ID in url)
    if (open && rowUpdateData?.project_id && countryList?.length) fetchData();
  }, [open, countryList, rowUpdateData?.country_code]);

  const handleCloseDialog = () => {
    setOpen(false);
    setLoading(false);
    setStateList([]);
  };

  /**
   * creates a new depot
   * @param {newDepot JSON} data
   * @returns {number} 0 for success, -1 for failure
   */
  const handleSubmit = async (data) => {
    const locationData = await Nominatim(
      //calculates the latitude/longitude
      data.address1.trim(),
      data.city.trim(),
      stateLookup?.[data.state_code],
      countryLookup?.[data.country_code],
      data.zipcode.trim()
    );

    if (Object.keys(locationData).length <= 0) {
      //if it can't calulate lat/long, throws error
      snackBarElement.current.displayToast(
        "Unable to Geotag Location",
        "error"
      );
      setLoading(false);
      return -1;
    } else if (
      Math.abs(data.latitude - Number(locationData.lat)) > 0.03 ||
      Math.abs(data.longitude - Number(locationData.long)) > 0.03
    ) {
      snackBarElement.current.displayToast(
        "Entered Address Does Not Match Pinned Location",
        "error",
        5000
      );
      setLoading(false);
      return -1;
    } else {
      const depot = {
        name: data.name,
        address1: data.address1,
        address2: data?.address2 ?? "",
        city: data.city,
        state_code: data.state_code,
        country_code: data.country_code,
        zipcode: data.zipcode,
        latitude: data.latitude,
        longitude: data.longitude,
        //if projectId is specified in queryParams, use that, otherwise, allow user to choose
        project_id: data.project_id,
        feeder_id: data?.feeder_id ?? "",
      };

      //sends the new depot to the backend
      fetch(depotURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("get")}`,
        },
        body: JSON.stringify(depot),
      })
        .then((response) => {
          if (response.ok) {
            snackBarElement.current.displayToast("Depot Added!");
            return response.json().then(({ data: newDepot }) => {
              setAllDepots([newDepot, ...allDepots]);
              handleCloseDialog();
              return 0;
            });
          }
          //else
          errorHandler(response, snackBarElement);
          setLoading(false);
          return -1;
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(String(err), "error");
          setLoading(false);
          return -1;
        });
    }
  };

  /**
   * updates the depot values
   * @param {*} depot_data
   * @returns {number} 0 for success, -1 for failure
   */
  const handleUpdate = async (depot_data) => {
    const originalData = allDepots.find((depot) => depot_data.id === depot.id);
    if (JSON.stringify(originalData) == JSON.stringify(depot_data)) {
      //if data is unaltered, then display an info card, and return
      snackBarElement.current.displayToast(
        "Depot data was not altered from Original",
        "info"
      );
      setLoading(false);
      return -1;
    }

    const locationData = await Nominatim(
      //calculates lat/long, to compare against the stored lat/long
      depot_data.address1.trim(),
      depot_data.city.trim(),
      stateLookup?.[depot_data.state_code].trim(),
      countryLookup?.[depot_data.country_code].trim(),
      depot_data.zipcode.trim()
    );
    if (Object.keys(locationData).length <= 0) {
      snackBarElement.current.displayToast("Unable to Geotag Address", "error");
      setLoading(false);
      return -1;
    } else if (
      Math.abs(depot_data.latitude - Number(locationData.lat)) > 0.03 ||
      Math.abs(depot_data.longitude - Number(locationData.long)) > 0.03
    ) {
      snackBarElement.current.displayToast(
        "Entered Address Does Not Approximately Match Pinned Location",
        "error",
        5000
      );
      setLoading(false);
      return -1;
    } else {
      const depot_body = {
        id: depot_data.id,
        name: depot_data.name,
        address1: depot_data.address1,
        address2: depot_data?.address2 ?? "",
        city: depot_data.city,
        state_code: depot_data.state_code,
        country_code: depot_data.country_code,
        zipcode: depot_data.zipcode.trim(),
        latitude: depot_data.latitude,
        longitude: depot_data.longitude,
        feeder_id: depot_data.feeder_id,
      };

      return fetch(depotURL, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("get")}`,
        },
        body: JSON.stringify(depot_body),
      })
        .then((response) => {
          if (response.ok) {
            snackBarElement.current.displayToast("Depot Updated!");
            return response.json().then(({ data: newDepot }) => {
              const index = allDepots.findIndex(
                (depot) => depot.id === newDepot.id
              );
              allDepots[index] = newDepot;
              setAllDepots([...allDepots]);
              handleCloseDialog();
              return 0; //success
            });
          }
          //else
          errorHandler(response, snackBarElement);
          setLoading(false);
          return -1;
        })
        .catch((err) => {
          snackBarElement.current.displayToast(
            "Something is not right, try again",
            "error"
          );
          console.log(err);
          setLoading(false);
          return -1;
        });
    }
  };

  /**
   * handles a field update
   * @param {{target: { name: String, value: any}}} e onChange event
   */
  const handleFieldUpdate = (e) => {
    const { name, value } = e.target;
    setRowUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFieldsTouched((prevState) => ({ ...prevState, [name]: true }));
  };

  /**
   * checks for valid inputs
   * @param {String} field name of field to check if it is valid (ALL Fields checks for all fields)
   * - "name"
   * - "address1"
   * - "city"
   * - "state_code"
   * - "country_code"
   * - "zipcode"
   * - "allFields" (checks for all prior fields and lat/long)
   * @returns {Boolean} true if invalid/error, false if valid
   */
  const checkError = () => {
    const REQUIRED_FIELDS = [
      "name",
      "address1",
      "city",
      "state_code",
      "country_code",
      "zipcode",
    ];
    if (!rowUpdateData) return true;
    // checking if error exists in all of the required fields
    const allValidStrings = REQUIRED_FIELDS.some((el) => {
      return !rowUpdateData[el] || !rowUpdateData[el].replace(/\s/g, "").length;
    });
    return (
      allValidStrings || //all required fields
      !(rowUpdateData.latitude && rowUpdateData.longitude) || //latitude and longitude
      !(rowUpdateData.project_id in projectLookup) //selected an (existing) project
    );
  };

  /**
   * Converts the Street Address into Latitude/longitude values, and stores them in rowUpdateData
   * @param {Object} address
   * @param {String} address.address1
   * @param {String} address.city
   * @param {String} address.state_code
   * @param {String} address.country_code
   * @param {String} address.zipcode
   */
  const addressToMap = async (address) => {
    try {
      const { address1, city, state_code, country_code, zipcode } = address;
      const locationData = await Nominatim(
        address1,
        city,
        stateLookup?.[state_code],
        countryLookup?.[country_code],
        zipcode
      );

      if (Object.keys(locationData).length <= 0) {
        //if it can't calulate lat/long, displays error
        snackBarElement.current.displayToast(
          "Unable to Geotag Location, Check Address for Typos",
          "error",
          5000
        );
      } else {
        setRowUpdateData((val) => ({
          ...val,
          latitude: locationData.lat,
          longitude: locationData.long,
          bounds: locationData.bounds, //for moving the map view to the location
          city: locationData.address.city,
          state_code: locationData.address.state_code,
          country_code: locationData.address.country_code,
          zipcode: locationData.address.zipcode,
        }));
        setFieldsTouched({ ...resetFieldsTouched });
      }
    } catch (e) {
      console.log(e);
      snackBarElement.current.displayToast(
        "Unable to Geotag Location",
        "error",
        5000
      );
    }
    setLoading(false);
  };

  // both fullWidth and fullwidth, for select/normal textfields
  const dialogAddressTextFieldProps = {
    required: true,
    disabled: !Boolean(rowUpdateData?.project_id),
    fullWidth: true,
    onChange: handleFieldUpdate,
  };

  //todo: instead of using controlled state values, look into the viability of making this a standard html form
  return (
    <Dialog
      component="form"
      open={open}
      maxWidth="lg"
      onSubmit={(e) => {
        e.preventDefault();
        setLoading(true);
        rowUpdateData.id
          ? handleUpdate(rowUpdateData)
          : handleSubmit(rowUpdateData);
      }}
      onClose={() => handleCloseDialog()}
    >
      <DialogTitle>
        {rowUpdateData.id ? "Edit" : "Add"} Depot details here
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Enter Depot Address Below</DialogContentText>
        <Grid sx={{ paddingLeft: "15px" }} container rowSpacing={4}>
          <Grid item xs={5}>
            <Grid container rowSpacing={1.5}>
              <Grid item xs={11} />
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  required
                  id="project_id"
                  name="project_id"
                  label="Project"
                  value={rowUpdateData.project_id ?? ""}
                  disabled={
                    presetProjectId in projectLookup || rowUpdateData.id
                  }
                  select
                  onChange={(e) => {
                    //finds the country associated with the selected project
                    const country = {
                      target: {
                        name: "country_code",
                        value: projectLookup[e.target.value]?.country_code,
                      },
                    };
                    handleFieldUpdate(country);
                    handleFieldUpdate(e);
                  }}
                >
                  {Object.values(projectLookup).map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  id="name"
                  name="name"
                  label="Depot Name"
                  variant="standard"
                  required
                  fullWidth
                  value={rowUpdateData?.name ?? ""}
                  onChange={handleFieldUpdate}
                />
              </Grid>
              <Grid item xs={11}></Grid>
              <Grid item xs={11}>
                <TextField
                  {...dialogAddressTextFieldProps}
                  id="addressLine1"
                  name="address1"
                  label="Address Line 1"
                  autoComplete="street-address"
                  variant="standard"
                  value={rowUpdateData?.address1 ?? ""}
                />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  {...dialogAddressTextFieldProps}
                  id="addressLine2"
                  name="address2"
                  label="Address Line 2"
                  variant="standard"
                  required={false}
                  value={rowUpdateData?.address2 ?? ""}
                />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  {...dialogAddressTextFieldProps}
                  id="city"
                  name="city"
                  label="City"
                  autoComplete="address-level2"
                  variant="standard"
                  value={rowUpdateData?.city ?? ""}
                />
              </Grid>
              <Grid item xs={11}>
                {!stateList?.length ? (
                  <TextField
                    disabled
                    required
                    fullWidth
                    select
                    label="State"
                    value=""
                  >
                    <Skeleton animation="wave" width="100%">
                      <MenuItem>.</MenuItem>
                    </Skeleton>
                  </TextField>
                ) : (
                  <TextField
                    {...dialogAddressTextFieldProps}
                    id="state_code"
                    name="state_code"
                    label="State"
                    autoComplete="address-level1"
                    value={rowUpdateData?.state_code ?? ""}
                    select
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                    }}
                  >
                    {stateList.map((state) => (
                      <MenuItem key={state.state_code} value={state.state_code}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={11}>
                {!countryList?.length ? (
                  <TextField
                    disabled
                    required
                    fullWidth
                    select
                    label="Country"
                    value=""
                  >
                    <Skeleton animation="wave" width="100%">
                      <MenuItem>.</MenuItem>
                    </Skeleton>
                  </TextField>
                ) : (
                  <TextField
                    {...dialogAddressTextFieldProps}
                    id="country_code"
                    name="country_code"
                    label="Country"
                    value={rowUpdateData?.country_code ?? ""}
                    select
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                    }}
                    onChange={(e) => {
                      //clears out the state's value if the country changes
                      const state = {
                        target: { name: "state_code", value: "" },
                      };
                      handleFieldUpdate(state);
                      //Clears the stateList, and a useEffect later re-updates the statelists with the newly selected country's states
                      setStateList([]);
                      handleFieldUpdate(e);
                    }}
                  >
                    {countryList.map((country) => (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={11}>
                <TextField
                  {...dialogAddressTextFieldProps}
                  id="zipcode"
                  name="zipcode"
                  label="Zipcode"
                  autoComplete="postal-code"
                  variant="standard"
                  value={rowUpdateData?.zipcode ?? ""}
                />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  {...dialogAddressTextFieldProps}
                  required={false}
                  id="feeder_id"
                  name="feeder_id"
                  label="Feeder ID (Optional)"
                  variant="standard"
                  value={rowUpdateData?.feeder_id ?? ""}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7} justifyContent={"center"} alignItems={"center"}>
            {/* Displays a map with a draggable pin, that allows user to select a specific lat/long for the map */}
            <InputMap
              pinCoords={rowUpdateData}
              setPinCoords={setRowUpdateData}
              setLoading={setLoading}
            />
          </Grid>
          <Grid item xs={6} textAlign={"left"}>
            <LoadingButton
              className="btn"
              variant="outlined"
              loading={loading}
              // only clickable if at least 1 address field has been modified by the user
              disabled={
                !Object.keys(resetFieldsTouched).some(
                  (key) => fieldsTouched?.[key]
                ) || !(rowUpdateData?.country_code && rowUpdateData?.state_code) // needs, at minimum, a country_code and state_code to find depot
              }
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                addressToMap(rowUpdateData);
              }}
            >
              Find Depot
            </LoadingButton>
          </Grid>
          <Grid item xs={5} textAlign={"right"}>
            {rowUpdateData?.bounds && (
              <Alert severity="info">
                If Pinned Location Seems Incorrect, Move Pin to Depot Location
              </Alert>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog()}>Cancel</Button>
        <Tooltip
          title={
            checkError()
              ? "Please Verify Address Before Submitting"
              : "Submit Depot"
          }
          placement={"top-end"}
        >
          <span>
            <LoadingButton
              disabled={checkError()}
              loading={loading}
              type="submit"
            >
              Submit
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}
