const firstVisitTourOptions = () => ({
  steps: [
    {
      title: "Welcome to EVopt",
      intro:
        "Looks like this may be your first time here, if would you like a quick walkthrough of the first steps of the project, click next.",
    },
    {
      element: document.querySelector(".create-new-btn"),
      intro: "First, create a project",
    },
    {
      element: document.querySelector(".MuiStep-root [href='/depot']"),
      intro:
        "Once you have created a project, head here to add some depots to your project!",
    },
    {
      element: document.querySelector(
        ".MuiStep-root [href='/resource-inventory']"
      ),
      intro: "Then, define what resources those depots have access to!",
    },
    {
      element: document.querySelector(".MuiStep-root [href='/fleet-input']"),
      intro:
        "Finally, you can use the data you entered to begin electrification analysis!",
    },
    {
      element: document.querySelector("#projectTourBeacon"),
      title: "Tutorials",
      intro:
        "If you ever feel lost or unsure of what to do, you can click these buttons for a brief walkthrough of a page.",
    },
    {
      element: document.querySelector("svg[data-testid=MenuIcon]"),
      title: "Navbar toggle",
      intro: "You can click this button to expand/hide the navbar on the left.",
    },
    {
      element: document.querySelector("#profile-dropdown"),
      title: "Profile",
      intro:
        "You can click this to view your account profile, submit an issue ticket, or logout",
    },
  ],
  disableInteraction: true,
  dontShowAgain: true,
  dontShowAgainCookie: "introjs-onboardingTourDontShowAgain",
});

const projectTourOptions = () => ({
  steps: [
    {
      element: document.querySelector(".MuiContainer-root .MuiPaper-root"),
      title: "Project View Overview",
      intro:
        "This table contains a list of all projects within your organization's account. From here any user from your organization can access and manage ongoing projects or create new ones.",
    },
    {
      element: document.querySelector(".create-new-btn"),
      title: "Create Project",
      intro: "To create a new project, click this button.",
    },
    {
      element: document.querySelector("td a[href^='/simulations']"), //the `^=` selects buttons with links that START WITH "/simulations"
      title: "View Analysis",
      intro: `You can view all the analysis of a <strong>specific</specific> project by clicking this button.`,
    },
    {
      element: document.querySelector("td [data-testid=MoreHorizIcon]"),
      title: "Edit/Delete Project",
      intro: `Click this to open a dropdown where you can choose to edit an indiviual project's data, or delete the project associated with the row.`,
    },
    {
      element: document.querySelector("td a[href^='/depot']"),
      title: "Edit/Add Depots",
      intro: `Each Project has depots associated with them. You can access and manage the depots of a <strong>specific</strong> project by clicking this button.<br/>
      <br/>
      <strong><u>Note</u>:</strong> You must have at least one depot in order to start an Analysis.`,
    },
    {
      element: document.querySelector("td a[href^='/depot']"),
      title: "Edit/Add Depots",
      intro: `Alternatively, you can add, edit, and view the depots of <strong>all</strong> projects by clicking this button.`,
    },
  ],
  disableInteraction: true,
  dontShowAgain: true,
  dontShowAgainCookie: "introjs-projectTourDontShowAgain",
});

const depotTourOptions = () => ({
  steps: [
    {
      element: document.querySelector(".MuiContainer-root .MuiPaper-root"),
      title: "Depot View Overview",
      intro:
        "This table contains the list of all depots within projects. It can also show depots associated with a specific project if accessed from the projects page.",
      tooltipPosition: "right",
    },
    {
      element: document.querySelector(".MuiContainer-root .MuiStack-root"),
      title: "Table/Map Toggle",
      intro: `You can switch between table and map view by clicking these chips.`,
    },
    {
      element: document.querySelector(".create-new-btn"),
      title: "Create Depot",
      intro: "To create a new depot, click this button.",
    },
    {
      element: document.querySelector("td [data-testid=MoreHorizIcon]"),
      title: "Edit/Delete Depots",
      intro: `You can edit or delete an individual depot's data by clicking this icon.`,
    },
    {
      element: document.querySelector("#climateData"),
      title: "Edit Climate Data",
      intro: `Select this button to update climate data at the depot.`,
    },
    {
      element: document.querySelector("td a"),
      title: "Edit/Add Resources",
      intro: `You can add, edit, and view resources of a <strong>specific</strong> depot by clicking this button in a depot's row.`,
    },
    {
      element: document.querySelector(
        ".MuiStep-root [href='/resource-inventory']"
      ),
      title: "Edit/Add Resources",
      intro: `Alternatively, you can add, edit, and view resources of <strong>all</strong> depots by clicking this button.`,
    },
  ],
  disableInteraction: true,
});

const resourceInventoryTourOptions = () => ({
  steps: [
    {
      element: document.querySelector(".create-new-btn"),
      title: "Create Resource",
      intro: `To add a new vehicle resource to a depot, click this button.</br>
      </br>
      Resources are a list of the type of vehicles that operate out of this depot currently.`,
    },
    {
      element: document.querySelector("td [data-testid=DeleteIcon]"),
      title: "Delete Resource",
      intro: `You can delete Resources by clicking these icons.</br>
      </br>
      <strong><u>NOTE</u>:</strong> Once an analysis has been created for a depot, users cannot delete vehicles from the depot.`,
    },
  ],
});

const fleetInputTourOptions = () => ({
  steps: [
    {
      element: document.querySelector("#fleet-input-step1"),
      title: "Step 1",
      intro: "Select the Analysis' Project and Depot",
    },
    {
      element: document.querySelector("#fleet-input-step2"),
      title: "Step 2",
      intro: "Provide a name and description for the analysis.",
    },
    {
      element: document.querySelector("#fleet-input-step3"),
      title: "Step 3",
      intro: "Choose your preferred method of initial data input.",
    },
    {
      element: document.querySelector("#new-analysis-button"),
      title: "Step 4",
      intro: "Press the next button",
    },
  ],
  tooltipPosition: "bottom-middle-aligned",
});

const nestableListTourOptions = () => ({
  steps: [
    {
      element: document.querySelector("#link-blocks-dialog"),
      title: "Link Blocks Process",
      intro: `This interface is used to select two (or more) blocks to be connected into a single 'run.'
              Once linked, You will have the ability to combine the grouped blocks into a single run during the route energy analysis.<br/>
              <br/>
              <strong><u>Note</u>:</strong> This is commonly used to connect morning and afternoon blocks for school buses where charging will not be available or utilized between the two blocks.<br/>
              (For example, eliminating midday charging)<br/>
              <br/>
              Linking (and subsequently combining) blocks can removes long periods between on-route operation where the vehicle may be keyed off, but not charging.`,
    },
    {
      element: document.querySelector("ul span li"),
      title: `Groups`,
      intro: `This is a group of blocks, it can contain just one or multiple blocks.
              You can click the '+' to expand the groups and view which blocks are in each group.<br/>
              <br/>
              <strong><u>Note</u>:</strong> Blocks are automatically linked if any blocks had the same Route ID when imported into the analysis.`,
    },
    {
      element: document.querySelector("[data-testid=MoveUpIcon]"),
      title: "Moving Blocks",
      intro: `To move a block from one group to another, press and hold the drag icon and move that block to a different group.`,
    },
    {
      element: document.querySelector("[data-testid=CallSplitIcon]"),
      title: `Splitting Groups`,
      intro: `Clicking this icon splits a group's runs into their own individual groups, allowing you to avoid combining certain blocks.
        The first block will remain in this group, and all other blocks will create new, separate groups at the end of the list of groups.`,
    },
    {
      element: document.querySelector("#filter-combine-runs"),
      title: `Filtering Groups`,
      intro:
        "This allows you to search for and filter groups of runs. More than one group can be filtered as an easier way to move blocks between groups.",
      disableInteraction: false,
    },
    {
      element: document.querySelector("#expand-collapse-all-buttons"),
      intro:
        "These buttons allow you to either expand or collapse all run groups.",
    },
  ],
});

/**
 * @param {Boolean} isCombinable if there are rows that can be combined, the tour is slightly different
 */
const routeEnergyTourOptions = (isCombinable) => ({
  steps: [
    {
      element: document.querySelector(".MuiContainer-root .MuiPaper-root"),
      title: "Route Energy Table",
      intro:
        "This table displays the calculated route energy data for the routes.",
    },
    {
      element: document.querySelector("th .PrivateSwitchBase-input"),
      title: "Checkboxes",
      intro: `These checkboxes are used to toggle which routes are to be kept and used going into the next page's calculations.
        The top-most checkbox is used to select/deselect all routes at once.`,
    },
    {
      element: document.querySelector("td [data-testid=EditIcon]"),
      title: "Edit Row",
      intro: `You can edit an individual row's energy efficiency by clicking these edit icons.`,
    },
    {
      element: document.querySelector(".MuiBox-root [data-testid=EditIcon]"),
      title: "Edit All",
      intro: `Alternatively, you can edit the energy efficiency of all rows at once using this icon.`,
    },
    {
      element: document.querySelector(".MuiContainer-root .MuiStack-root"),
      title: "Table/Chart Toggle",
      intro: `You can switch between table and chart view by clicking these chips.`,
    },
    {
      element: document.querySelector("#route-energy-options"),
      title: isCombinable ? "Options" : "Battery Sizing Inputs",
      intro: `You can define your vehicle's battery lifetime and count here.
        ${
          isCombinable
            ? "<br /><br />You can also combine the blocks defined on the previous page here."
            : ""
        }`,
    },
    {
      element: document.querySelector("#next-page"),
      title: "Next Page",
      intro: `When you are satisfied with your selection of rows, click this button to continue the analysis.`,
    },
  ],
  disableInteraction: true,
});

const batterySizingTableRouteTourOptions = () => ({
  steps: [
    {
      element: document.querySelector(".MuiContainer-root .MuiPaper-root"),
      title: "Battery Sizing Table Route View",
      intro:
        "This table displays the calculated battery sizing data for the routes.",
    },
    {
      element: document.querySelector("th .PrivateSwitchBase-input"),
      target: "th .PrivateSwitchBase-input", //target is used, in conjunction with the onbeforechange in batterySizing.js, to relocate the element in the event of a re-render
      title: "Checkboxes",
      intro: `These checkboxes are used to toggle which routes are to be kept and used going into the next page's calculations.
        The top-most checkbox is used to select/deselect all routes at once.`,
    },
    {
      element: document.querySelector(".MuiContainer-root .MuiStack-root"),
      title: "Table/Chart Toggle",
      intro: `You can switch between table and chart view by clicking these chips.`,
    },
    {
      element: document.querySelector("#route-fleet-stack"),
      target: "#route-fleet-stack",
      title: "Route/Fleet Toggle",
      intro: `You can also switch between the route and fleet table views using these chips.\n\n
          When in the fleet view of the table, you can view the efficiency of each vehicle's battery of the course of it's lifetime.\n\n
          Click the '>' icon to open up a vehicle's data table.`,
    },
    // {
    //   element: document.querySelector(".MuiContainer-root .MuiPaper-root"),
    //   title: "Battery Sizing Table Fleet View",
    //   intro: `When in the fleet view of the table, you can view the efficiency of each vehicle's battery of the course of it's lifetime.\n\n
    //       Click the '>' icon to open up a vehicle's data table.`,
    // },
    {
      element: document.querySelector("#charger-options-button"),
      title: "Charger Options",
      intro: `Click this button to manually select the chargers to be used.`,
    },
    {
      element: document.querySelector("#next-page"),
      title: "Next Page",
      intro: `When you are satisfied with your selection of rows, click this button to continue the analysis.`,
    },
  ],
  disableInteraction: true,
});

const chargerTourOptions = () => ({
  steps: [
    {
      element: document.querySelector(".MuiDialog-paper"),
      title: "Charger Options",
      intro: `This dialog box is used to choose which chargers the analysis will take into account going forward.`,
    },
    {
      element: document.querySelector(
        "#selected-charger-options .MuiPaper-root"
      ),
      title: "Selected Chargers",
      intro: `All chargers in this box are considered "selected," and will be included as options in the analysis.`,
    },
    {
      element: document.querySelector("#unselected-charger-options"),
      title: "Unselected Chargers",
      intro: `All chargers in these boxes are considered "unselected," and will be not be included in the analysis.`,
    },
    {
      element: document.querySelector("#swap-charger-options"),
      title: "Move Chargers",
      intro: `To move charger options from one group to the other,
        check the charger's boxes and then use these buttons to move all checked chargers.`,
    },
    {
      element: document.querySelector("#new-charger-email"),
      title: "Request a New Rating",
      intro: `If you feel that your desired charger option is missing from this form,
        feel free to send us an email requesting a new charger.`,
    },
    {
      element: document.querySelector("#cancel-submit-buttons"),
      title: "Submit",
      intro: `Once you have selected all your desired charger options for the analysis, click "save and contine."<br /><br />
        Alternatively, you can discard your changes to the charger options by clicking "cancel."`,
    },
  ],
});

export {
  batterySizingTableRouteTourOptions,
  chargerTourOptions,
  depotTourOptions,
  firstVisitTourOptions,
  fleetInputTourOptions,
  nestableListTourOptions,
  projectTourOptions,
  resourceInventoryTourOptions,
  routeEnergyTourOptions,
};

