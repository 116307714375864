import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { DataContext } from "../../contexts/dataContext";

const templateUser = {
  first_name: "",
  last_name: "",
  email: "",
  role_id: "",
  user_role_id: "",
};

/**
 * A user's dialog box's form contents for the "User" admin page
 * @param {Object} props
 * @param {Object} props.userRoles object mapping user_role Id's to user role titles
 * @param {Object[]} props.partnerNames list of Partner Names that the currently signed in user can view
 * @param {Object[]} props.customerNames list of Customer Names that the currently signed in user can view
 * @param {Function} props.formClose function to close dialog box
 * @param {Function} props.formSubmit function to submit the dialog box (invites users)
 * @param {Boolean} props.disableButtons boolean that disables the "send invite" button when submitting users
 * @returns {React Element}
 */
export default function UserDialog(props) {
  const {
    userRoles,
    partnerNames,
    customerNames,
    formClose,
    formSubmit,
    disableButtons,
  } = props;
  const [users, setUsers] = useState([{ ...templateUser }]);
  //todo: since partner_id and customer_id have been consolidated into a single organization_id; consider modifying code to remove this
  const [parent, setParent] = useState({ partner_id: "", customer_id: "" });

  const { accessRights } = useContext(DataContext);

  const handleChange = (event, field, rowNumber) => {
    const newValue =
      field != "role_id"
        ? event.currentTarget.value
        : event.currentTarget.dataset.value;
    setUsers((allUsers) => {
      if (field == "role_id") {
        allUsers[rowNumber].user_role_id = newValue;
        allUsers[rowNumber].user_role = userRoles[newValue];
      }
      allUsers[rowNumber][field] = newValue;
      return [...allUsers];
    });
  };

  //todo: This is poor practice, and should be changed so that each user row has a unique ID assigned at the row's creation and is used as key
  const userInputRow = (user, rowNumber) => (
    <Fragment key={rowNumber}>
      <Grid item xs={3} key={`firstName_${rowNumber}`}>
        <TextField
          fullWidth
          required
          label="First Name"
          value={user.first_name}
          disabled={disableButtons}
          onChange={(event) => handleChange(event, "first_name", rowNumber)}
        />
      </Grid>
      <Grid item xs={3} key={`lastName_${rowNumber}`}>
        <TextField
          fullWidth
          required
          label="Last Name"
          value={user.last_name}
          disabled={disableButtons}
          onChange={(event) => handleChange(event, "last_name", rowNumber)}
        />
      </Grid>
      <Grid item xs={3} key={`email_${rowNumber}`}>
        <TextField
          fullWidth
          required
          label="Email"
          type="email"
          value={user.email}
          disabled={disableButtons}
          onChange={(event) => handleChange(event, "email", rowNumber)}
        />
      </Grid>
      <Grid item xs={2} key={`role_${rowNumber}`}>
        <TextField
          fullWidth
          required
          label="Role"
          select
          SelectProps={{
            //set an upper limit to the height of the dropdown
            MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
          }}
          value={user.role_id}
          disabled={disableButtons}
        >
          {Object.entries(userRoles).map(([role_id, role_name]) => (
            <MenuItem
              key={role_id}
              value={role_id}
              onClick={(event) => handleChange(event, "role_id", rowNumber)}
            >
              {role_name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        item
        xs={1}
        key={`deleteIcon_${rowNumber}`}
        mt={2}
        pr={3}
        className="grid-right"
      >
        {/* todo: look into making this an iconButton */}
        <Delete
          disabled={disableButtons}
          onClick={() =>
            setUsers((users) => {
              //deletes the row
              users.splice(rowNumber, 1);
              return [...users];
            })
          }
        />
      </Grid>
    </Fragment>
  );

  return (
    <form onSubmit={(event) => formSubmit(event, users, parent)}>
      <DialogTitle>Invite Users</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          {accessRights.admin.read_partner ? (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Partner (Optional)"
                select
                SelectProps={{
                  //set an upper limit to the height of the dropdown
                  MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                }}
                value={parent.partner_id}
                disabled={Boolean(parent.customer_id)}
              >
                <MenuItem
                  value=""
                  onClick={() => setParent({ partner_id: "", customer_id: "" })}
                >
                  - Customer Level User -
                </MenuItem>
                {partnerNames.map((partner) => (
                  <MenuItem
                    key={partner.id}
                    value={partner.id}
                    onClick={() =>
                      setParent({ partner_id: partner.id, customer_id: "" })
                    }
                  >
                    {partner.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <Grid item xs={6} />
          )}
          {accessRights.admin.read_customer ? (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Customer (Optional)"
                select
                SelectProps={{
                  //set an upper limit to the height of the dropdown
                  MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                }}
                value={parent.customer_id}
                disabled={Boolean(parent.partner_id)}
              >
                <MenuItem
                  value=""
                  onClick={() => setParent({ partner_id: "", customer_id: "" })}
                >
                  - Partner Level User -
                </MenuItem>
                {customerNames.map((customer) => (
                  <MenuItem
                    key={customer.id}
                    value={customer.id}
                    onClick={() =>
                      setParent({ partner_id: "", customer_id: customer.id })
                    }
                  >
                    {customer.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <Grid item xs={6} />
          )}
          {users.map((user, index) => userInputRow(user, index))}
        </Grid>
        <br />
        <br />
        <Button
          variant="outlined"
          className="btn"
          sx={{ align: "right" }}
          onClick={() => setUsers((users) => [...users, { ...templateUser }])}
        >
          <Add fontSize="small" />
          Add Another
        </Button>
      </DialogContent>
      <DialogActions>
        <Button className="btn" variant="outlined" onClick={() => formClose()}>
          Cancel
        </Button>
        <LoadingButton
          className="btn"
          variant="contained"
          loading={disableButtons}
          type="submit"
        >
          Send Invite
        </LoadingButton>
      </DialogActions>
    </form>
  );
}
