import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import { organizationProfileURL } from "../../static/constants/backendRoutes";
import BannerImage from "../../static/images/banner.jpeg";
import UseAuth from "../auth/useAuth";
import { errorHandler, stringCapitalize, useQuery } from "../utils";
import { LocationDropDowns } from "./defaultDialog";

export default function OrganizationProfile() {
  const OrganizationID = useQuery().get("orgId");
  const [organizationData, setOrganizationData] = useState(undefined);
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [orgLogo, setOrgLogo] = useState();

  const { accessRights, setLogo } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  /** additional props shared by all input Textfields */
  const inputProps = {
    fullWidth: true,
    inputProps: { disabled: !editMode }, //prevents user from editting input when not in edit mode
    variant: editMode ? "filled" : "standard",
  };

  useEffect(() => {
    if (OrganizationID) {
      const headers = { Authorization: `Token ${UseAuth("get")}` };

      /**
       * fetches the organization profile
       */
      fetch(`${organizationProfileURL}?organization_id=${OrganizationID}`, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            setError(false);
            response.json().then(({ data }) => setOrganizationData(data));
          } else {
            errorHandler(
              response,
              snackBarElement,
              "Failed to get Organization Profile details"
            );
            setError(true);
          }
        })
        .catch((error) => {
          setError(true);
          console.log(error);
          snackBarElement.current.displayToast(
            "Network Error occurred while trying to get organization profile details",
            "error",
            5000
          );
        });
    }
  }, []);

  /**
   * Sends updated Organization details to backend
   * @param {SubmitEvent} event
   */
  function handleUpdate(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());

    //checks that at least 1 field has been altered, before making backend update
    const isModified = !Object.entries(formJSON).every(
      ([key, value]) => value == (organizationData && organizationData[key])
    );
    if (!isModified) {
      snackBarElement.current.displayToast(
        "Organization was not altered from Original",
        "info"
      );
      setEditMode(false);
      return;
    }

    formData.append("name", organizationData.name);
    formData.append("organization_id", OrganizationID);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
    };
    setButtonLoading(true);

    fetch(organizationProfileURL, { method: "PATCH", headers, body: formData })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(
            "Organization Updated",
            "success"
          );
          if (
            window.location.host.split(".")[0] == organizationData.subdomain &&
            formJSON?.logo?.size
          )
            setLogo(URL.createObjectURL(formJSON.logo));
          setOrganizationData({ ...organizationData, ...formJSON });
          setEditMode(false);
        } else {
          errorHandler(response, snackBarElement);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Something went wrong",
          "error",
          4000
        );
      })
      .finally(() => {
        setButtonLoading(false);
      });
  }

  /**
   * @param {React.InputHTMLAttributes<HTMLInputElement>.onChange?: React.ChangeEventHandler<HTMLInputElement>} event
   */
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file?.size >= 20971520) {
      alert("File size is too large! Please select a smaller image.");
      return undefined;
    }
    setOrgLogo(file);
  };

  return (
    <>
      <Box
        sx={{
          height: "25vh",
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          zIndex: -1,
        }}
      />

      {!OrganizationID ? (
        // some quick error checks
        <Typography variant="h3" gutterBottom component="div">
          <br />
          Organization Not Specified
        </Typography>
      ) : error ? (
        // error page
        <h3>
          <br />
          Something Went Wrong Retrieving Organization Data
        </h3>
      ) : !organizationData ? (
        //if profile has not yet been recieved from the backend, display loading
        <div>
          <br /> Loading... <br />
          <CircularProgress variant="indeterminate" />
        </div>
      ) : (
        <form onSubmit={handleUpdate}>
          <br />
          <Container sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h5"
              component="div"
              align="left"
              className="page-title"
            >
              <span className="header-green">
                {organizationData.name.replaceAll(" ", "\xa0")}
              </span>
              &nbsp;Profile
            </Typography>{" "}
            <Container fixed>
              {organizationData.is_active ? (
                <Typography
                  variant="h6"
                  align="left"
                  style={{ fontWeight: 500, color: "green" }}
                >
                  Active
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  align="left"
                  style={{ fontWeight: 500, color: "darkgrey" }}
                >
                  Inactive
                </Typography>
              )}
            </Container>
            {editMode && (
              <>
                {orgLogo && (
                  <>
                    <b>Logo:</b>&nbsp;{orgLogo.name.replaceAll(" ", "_")}
                  </>
                )}
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ px: 5, mx: 2 }}
                >
                  Change&nbsp;Logo
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    id="logo"
                    name="logo"
                    onChange={handleLogoUpload}
                  />
                </Button>
              </>
            )}
          </Container>
          <Container>
            <br />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="brand_status"
                  name="brand_status"
                  label="Brand Status"
                  defaultValue={
                    TYPE_STRINGS.BRAND_STATUS[organizationData.brand_status]
                  }
                  fullWidth
                  inputProps={{ disabled: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="subdomain"
                  name="subdomain"
                  label="Subdomain"
                  defaultValue={organizationData.subdomain}
                  fullWidth
                  inputProps={{ disabled: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="website"
                  name="website"
                  label="Website"
                  type="url"
                  defaultValue={organizationData.website}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  multiline
                  maxRows={4}
                  defaultValue={organizationData.description}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="phone_number"
                  name="phone_number"
                  label="Phone"
                  type="tel"
                  defaultValue={organizationData.phone_number}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6} textAlign="start">
                <TextField
                  id="organization_type"
                  name="organization_type"
                  label="Organization Type"
                  type="text"
                  defaultValue={stringCapitalize(
                    organizationData.organization_type
                  )}
                  fullWidth
                  inputProps={{ disabled: true }}
                />
              </Grid>
              {editMode ? (
                <LocationDropDowns
                  profile={organizationData}
                  props={inputProps}
                />
              ) : (
                <>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="country_code"
                      name="country_code"
                      label="Country"
                      defaultValue={organizationData.country_code}
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="state_code"
                      name="state_code"
                      label="State"
                      defaultValue={organizationData.state_code}
                      {...inputProps}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6} sm={3}>
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  defaultValue={organizationData.city}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="zipcode"
                  name="zipcode"
                  autoComplete="postal-code"
                  label="Zipcode"
                  defaultValue={organizationData.zipcode}
                  {...inputProps}
                />
              </Grid>
            </Grid>
            <br />
            <br />
            {organizationData?.organization_type == "partner" && (
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "30%", mr: "4.5%" }}
                component={Link}
                to={`/customer?partner_id=${OrganizationID}`}
              >
                Manage Customers
              </Button>
            )}
            <Button
              variant="outlined"
              className="btn"
              sx={{ width: "30%", mr: "4.5%" }}
              component={Link}
              to={`/user?orgId=${OrganizationID}`}
            >
              Manage Users
            </Button>
            {editMode ? (
              <LoadingButton
                variant="contained"
                className="btn"
                sx={{ width: "30%" }}
                type="submit"
                loading={buttonLoading}
              >
                Save Changes
              </LoadingButton>
            ) : (
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "30%" }}
                onClick={(e) => {
                  e.preventDefault(); //prevents auto-submitting form
                  setEditMode(true);
                }}
                disabled={!accessRights.admin.update_organization_profile}
              >
                Edit Organization
              </Button>
            )}
          </Container>
        </form>
      )}
    </>
  );
}
