export default { 1: "Metric", 2: "Imperial" }; // "🇺🇸 Freedom Units 🦅"

export const unitSmallMap = { 1: "m", 2: "ft", undefined: "ft" };

export const unitSmallAbbr = { 1: "m", 2: "'", undefined: "'" };

export const unitLargeMap = { 1: "Kilometers", 2: "Miles", undefined: "Miles" };

export const unitLargeAbbr = { 1: "km", 2: "mi", undefined: "mi" };

export const unitVolumeMap = { 1: "Liter", 2: "Gallon", undefined: "Gallon" };

export const unitVolumeAbbr = { 1: "l", 2: "gal", undefined: "gal" };

export const unitGGEMap = { 1: "kg", 2: "GGE", undefined: "GGE" };

export const unitMassMap = { 1: "tonnes", 2: "tons", undefined: "tons" };

export const unitPoundMap = {
  1: "kilograms",
  2: "pounds",
  undefined: "pounds",
};

export const unitPoundMapAbbr = { 1: "kgs", 2: "lbs", undefined: "lb" };

export const unitAreaMap = { 1: "Hectare", 2: "Acre", undefined: "Acre" };
