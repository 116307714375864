import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import FilterAlt from "@mui/icons-material/FilterAlt";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookie from "js-cookie";
import { forwardRef, useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { localDb } from "../contexts/localDb";
import { countriesURL, statesURL } from "../static/constants/backendRoutes";
import localDbTableNames from "../static/constants/localDbTableNames.json";
import UseAuth from "./auth/useAuth";
import { clearAnalysisCookies } from "./secondary/unitConversions";

/**
 * @deprecated
 */
function SnackBarContent(data) {
  if (typeof data === "string") return data;

  let text = data[Object.keys(data)[0]][0];
  if (text.length <= 1 || text === undefined) return data[Object.keys(data)[0]];

  if (text[text.length - 1] === ".") text = text.slice(0, -1);
  return text;
}

//todo: improve this to check for different status codes, and maybe create an axios-compatible version
/**
 * given an error, logs and displays the error message to the user
 * note: only works on the newly standardized error messages
 * @param {Response | Object} response - the backend response containing the error (Response from fetch, Object from axios)
 * @param {{current: {displayToast: Function}}} snackBarElement the snackbar element, passed in from context provider
 * @param {String} [message] - the default error message, for if the backend status code was unaccounted for
 */
function errorHandler(
  response,
  snackBarElement,
  errorMessage = "Something went wrong"
) {
  if (response.response) {
    // if the error occurred in axios, instead of fetch
    response = response.response;
    console.log(response);
    response.json = () => new Promise((resolve) => resolve(response?.data));
  }
  console.log(response);

  switch (response.status) {
    case 403:
    case 404:
    case 409:
      response
        .json()
        .then(({ errors: errorDetails }) =>
          snackBarElement?.current?.displayToast(
            errorDetails?.display_error || errorMessage,
            "error",
            5000
          )
        )
        .catch((e) => {
          console.error("Failed to parse error response", e);
          snackBarElement?.current?.displayToast(errorMessage, "error", 5000);
        });
      break;
    case 400:
      response
        .json()
        .then((errorObj) => {
          if ("errors" in errorObj)
            snackBarElement?.current?.displayToast(
              errorObj.errors?.display_error || errorMessage,
              "error",
              5000
            );
          else
            snackBarElement?.current?.displayToast(
              Object.keys(errorObj)[0] + " is missing/invalid" ||
                "Invalid Inputs",
              "error",
              5000
            );
        })
        .catch((e) => {
          console.error(e);
          snackBarElement?.current?.displayToast(
            "Invalid Inputs",
            "error",
            5000
          );
        });
      break;
    case 401:
      clearLocalDb();
      snackBarElement?.current?.displayToast(
        "Insufficient/Missing User Permissions",
        "error",
        5000
      );
      setTimeout(() => {
        UseAuth("remove");
        window.location.assign("/login");
      }, [1000]);
      break;
    default:
      snackBarElement?.current?.displayToast(errorMessage, "error", 3000);
      break;
  }
}

/**
 * calculates lat/long values given an address
 * @param {String} street
 * @param {String} city
 * @param {String} state
 * @param {String} country
 * @param {String} postalcode
 * @return {{lat: Number, long:Number, bounds: Number[][], address: {city: String, state:String, country: String}}[]}
 *
 * @example () => {lat: Number, long: Number}
 */
async function Nominatim(street, city, state, country, postalcode) {
  let { data } = await axios.get(
    `https://nominatim.openstreetmap.org/search?street=${street}&state=${state}&country=${country}&limit=1&format=json&addressdetails=1` +
      (city ? `&city=${city}` : "") +
      (postalcode ? `&postalcode=${postalcode}` : "")
  );

  if (!data || data.length <= 0) {
    //if can't find in first attempt, try again, but this time, without the street
    data = await axios.get(
      `https://nominatim.openstreetmap.org/search?q=${
        street ? street + " " : ""
      }${city && state != "Delhi" ? city + " " : ""}${
        state ? state + " " : ""
      }${
        postalcode ? postalcode + " " : ""
      }${country}&limit=1&format=json&addressdetails=1&fuzzy=1`
    );
    data = data.data;
  }

  if (!data || data.length <= 0) {
    //if fails a second time, just return empty object
    return {};
  } else {
    const [country_code, state_code] =
      data[0]?.address?.["ISO3166-2-lvl4"]?.split("-") ||
      data[0]?.address?.["ISO3166-2-lvl5"]?.split("-") ||
      data[0]?.address?.["ISO3166-2-lvl6"]?.split("-") ||
      data[0]?.address?.["ISO3166-2-lvl1"]?.split("-") ||
      data[0]?.address?.["ISO3166-2-lvl2"]?.split("-") ||
      data[0]?.address?.["ISO3166-2-lvl3"]?.split("-");
    return {
      lat: data[0].lat,
      long: data[0].lon,
      bounds: data[0].boundingbox && [
        [data[0].boundingbox[0], data[0].boundingbox[2]],
        [data[0].boundingbox[1], data[0].boundingbox[3]],
      ],
      address: {
        zipcode: data[0]?.address?.postcode,
        country: data[0]?.address?.country,
        country_code: country_code,
        state_code: state_code,
        state:
          data[0]?.address?.state ??
          data[0]?.address?.region ??
          data[0]?.address?.county,
        city:
          data[0]?.address?.city ??
          data[0]?.address?.town ??
          data[0]?.address?.village ??
          data[0]?.address?.municipality ??
          data[0]?.address?.township ??
          data[0]?.address?.suburb ??
          data[0]?.address?.state_district,
      },
    };
  }
}

/**
 * @typedef {Object} Address
 * @property {String} house_number
 * @property {String} road street
 * @property {String} town
 * @property {String} city (alternative for when there is no town)
 * @property {String} county (currently unused)
 * @property {String} state
 * @property {String} ISO3166-2-lvl4 (currently unused)
 * @property {String} postcode zipcode
 * @property {String} country
 * @property {String} country_code (currently unused)
 *
 * @typedef {Object} ErrorObject
 * @property {String} error error message
 */

/**
 * calculates address values given latitude and longitude values
 * @param {Number} latitude
 * @param {Number} longitude
 * @return {Promise<Address|ErrorObject>}
 */
async function ReverseNominatim(latitude, longitude) {
  const { data } = await axios.get(
    `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
  ); //returns address object
  if (data.error) {
    return data;
  } else {
    const [country_code, state_code] =
      data?.address?.["ISO3166-2-lvl4"]?.split("-") ||
      data?.address?.["ISO3166-2-lvl5"]?.split("-") ||
      data?.address?.["ISO3166-2-lvl6"]?.split("-") ||
      data?.address?.["ISO3166-2-lvl1"]?.split("-") ||
      data?.address?.["ISO3166-2-lvl2"]?.split("-") ||
      data?.address?.["ISO3166-2-lvl3"]?.split("-");
    return { ...data.address, country_code, state_code };
  }
}

/**
 * fetches all countries from the backend
 * @param {{current: {displayToast: Function}}} snackBarElement the snackbar element, passed in from context provider
 * @returns {{name: String, iso2: String, phone_code: String, emoji: String}[]} an array of country details
 */
function fetchCountries(snackBarElement) {
  const headers = {
    Authorization: `Token ${UseAuth("get")}`,
    "Content-Type": "application/json",
  };

  return fetch(countriesURL, { method: "GET", headers })
    .then((res) => {
      if (res.ok) {
        return res.json().then(({ data }) => data);
      } else {
        console.log(res);
        snackBarElement?.current?.displayToast(
          "Failed to find countries",
          "error"
        );
        return [];
      }
    })
    .catch((e) => {
      console.log(e);
      snackBarElement?.current?.displayToast(
        "Failed to find countries",
        "error"
      );
      return [];
    });
}

/**
 * fetches the states associated with the country from the backend
 * @param {String} country_code the iso2 (iso 3166) country code to find states for
 * @param {{current: {displayToast: Function}}} snackBarElement the snackbar element, passed in from context provider
 * @returns {{name: String, state_code: String}[]} the list of state names and their corresponding state_codes
 */
function fetchStates(country_code, snackBarElement) {
  const headers = {
    Authorization: `Token ${UseAuth("get")}`,
    "Content-Type": "application/json",
  };
  return fetch(`${statesURL}?country_code=${country_code}`, {
    method: "GET",
    headers,
  })
    .then((res) => {
      if (res.ok) {
        return res.json().then(({ data }) => data);
      } else {
        console.log(res);
        snackBarElement?.current?.displayToast(
          "Failed to find states in country",
          "error"
        );
        return [];
      }
    })
    .catch((e) => {
      console.log(e);
      snackBarElement?.current?.displayToast(
        "Failed to find states in country",
        "error"
      );
      return [];
    });
}

/**
 * Icons to support material-table
 * @param {String} key - assign value for "create ____" in top-right
 * @param {setState} setIsNavDisabled - disables the next/previous step buttons while editing/adding rows
 * @returns
 */
const Icons = (key = "Row", setIsNavDisabled = undefined) => {
  return {
    Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <DoneIcon {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <FilterAlt {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => (
      <EditIcon
        {...props}
        ref={ref}
        onClick={() =>
          setIsNavDisabled &&
          typeof setIsNavDisabled === "function" &&
          setIsNavDisabled(true)
        }
      />
    )),
    Add: forwardRef((props, ref) => (
      <div
        style={{
          border: "1px solid black",
          borderRadius: "16px",
        }}
        onClick={() =>
          setIsNavDisabled &&
          typeof setIsNavDisabled === "function" &&
          setIsNavDisabled(true)
        }
        className="create-new-btn"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={0.5}
          sx={{
            padding: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddIcon fontSize="small" sx={{ color: "black" }} />
          <Typography
            variant="button"
            display="block"
            sx={{
              textTransform: "uppercase",
              color: "black",
              paddingRight: "2px",
            }}
            noWrap
          >
            New {key}
          </Typography>
        </Stack>
      </div>
    )),
  };
};

/**
 * returns the currently active subdomain
 * @returns {string} the current site's subdomain
 */
function getSubdomain() {
  let subdomain = window.location.host.split(".");
  return subdomain.length >= 4 ? subdomain[0] : "";
}

/** clears all data stored in the localDb (indexDb) */
function clearLocalDb() {
  clearAnalysisCookies();
  localDb.transaction(
    "rw",
    ...localDbTableNames.map((table) => localDb[table]),
    () => localDbTableNames.forEach((table) => localDb[table].clear())
  );
}

/**
 * removes all specified dexiedb data values (note: leaves specific inputs unaltered, as they can persist in the "edit analysis inputs" part of subheader)
 * @param {("simulation"|"project"|"blocks"|"routeEnergy"|"battery"|"fleetSizing"|"evAssessment"|"financial"|"tco")[]} keys the keys to be cleared
 */
function partialClearLocalData(keys) {
  //todo: look into the viability of using the page's STEP_NUMBER instead of a keys list as input
  localDb.transaction("rw", ...keys.map((key) => localDb[key]), () =>
    keys.forEach((key) => {
      switch (key) {
        case "blocks":
        case "routeEnergy":
        case "battery":
          // case "financial":
          localDb[key].delete("data"); // leave in the input values, as they are part of the simulation subtitle "edit analysis" dialog shared on all pages
          break;
        default:
          localDb[key].clear();
      }
    })
  );
}

/**
 * gets the stored key data from the indexdb
 * @param {("simulation"|"project"|"blocks"|"routeEnergy"|"battery"|"fleetSizing"|"evAssessment"|"financial"|"tco")} key the indexDb tablename that the data is stored under
 * @param {("all"|"data"|"input")} [value="all"] what value to fetch (to avoid unnecessarily querying large datasets)
 * @returns {{data: JSON|undefined, input: JSON|undefined}}
 */
async function getLocalData(key, value = "all") {
  let data, input;
  switch (value) {
    case "data":
      data = await localDb[key].get("data");
      return { data: data?.value };
    case "input":
      input = await localDb[key].get("input");
      return { input: input?.value };
    case "all":
    default: //default in case function provided with an invalid value
      [data, input] = await localDb[key].bulkGet(["data", "input"]);
      return { data: data?.value, input: input?.value };
  }
}

/**
 * sets the input & data in the indexdb at the key,
 * returns a promise of the number of changes made to the DB
 * @param {("simulation"|"project"|"blocks"|"routeEnergy"|"battery"|"fleetSizing"|"evAssessment"|"financial"|"tco")} key the indexDb tablename that the data is stored under
 * @param {{data: JSON|undefined, input: JSON|undefined}} data an object containing the data to be updated, pass in undefined to clear data
 * @returns {Promise<String[]>}
 */
function storeLocalData(key, { data = null, input = null }) {
  let updates = [];
  // use null to determine whether or not to overwrite the other field
  if (data !== null) updates.push({ key: "data", value: data });
  if (input !== null) updates.push({ key: "input", value: input });
  return localDb[key].bulkPut(updates, { allKeys: true });
}

/**
 * Converts Minutes From Midnight into "HH:MM AM/PM" format
 * @param {Number} minutes Minutes From Midnight (must be greater than 0)
 * @returns {String} "HH:MM AM/PM" or "N/A"
 */
function MFM_to_AMPM(minutes) {
  // %1440 for when >=24 hours after Midnight, %720 to decrease to 12 hours, %60 to get minutes
  // || 12 for when 0 hours after 12, .padStart to prepend 0's when < "10"
  if (!isFinite(minutes)) return "N/A";
  return `${String(Math.floor((minutes % 720) / 60) || 12).padStart(
    2,
    "0"
  )}:${String(Math.floor(minutes % 60)).padStart(2, "0")}\xa0${
    minutes % 1440 < 720 ? "A" : "P"
  }M`;
}

/**
 * Converts Minutes From Midnight into "x hrs, y mins" format
 * @param {{minutes: Number}} param0 Minutes From Midnight
 * @returns {String} "x hrs y mins"
 */
function MFM_to_TimeRemaining(minutes) {
  if (!isFinite(minutes)) return "N/A";
  return `${String(Math.floor(minutes / 60)).padStart(1, "0")} hrs ${String(
    minutes % 60
  ).padStart(1, "0")} mins`;
}

/**
 * Converts "HH:MM" (NOT "HH:MM AM/PM") time format into Minutes From Midnight Number
 * @param {String} time time string in HH:MM format
 * @returns {Number} Minute From Midnight
 */
function Military_to_MFM(time) {
  const HHMM = time?.split(":");
  if (HHMM?.length != 2) return undefined;
  return +HHMM[0] * 60 + +HHMM[1];
}

/**
 * Converts "HH:MM" time format into "HH:MM AM/PM" format
 * @param {String} time time in HH:MM format
 * @returns {String} HH:MM AM/PM format
 */
function Military_to_AMPM(time) {
  const HHMM = time.split(":");
  return `${String(+HHMM[0] % 12 || 12).padStart(2, "0")}:${String(
    HHMM[1]
  ).padStart(2, "0")} ${HHMM[0] >= 12 ? "PM" : "AM"}`;
}

/**
 * Converts Minutes From Midnight into "HH:MM AM/PM" format
 * @param {Number} minutes Minutes From Midnight (must be greater than 0)
 * @returns {String} "HH:MM" or "N/A"
 */
function MFM_to_Military(minutes) {
  if (!isFinite(minutes)) return "N/A";
  return `${String(Math.floor((minutes % 1440) / 60)).padStart(
    2,
    "0"
  )}:${String(minutes % 60).padStart(2, "0")}`;
}

/**
 * Converts "HH:MM AM/PM" format into "HH:MM"
 * @param {String} minutes "HH:MM AM/PM" (or "HH:MM:SS AM/PM" or "HH:MM:SS")
 * @returns {String} "HH:MM" or "N/A"
 */
function AMPM_to_Military(time) {
  const hours = +time.split(":")[0];
  const minutes = +time.split(":")[1].slice(0, 2);
  //if there is no "M" at end, then don't bother with the more AM/PM handling
  if (time.at(-1).toUpperCase() != "M")
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  const is_AM = time.at(-2).toUpperCase() == "A"; //if true, AM, if false, PM
  return `${
    hours == 12 && is_AM
      ? "00"
      : String(hours + (is_AM || hours == 12 ? 0 : 12)).padStart(2, "0")
  }:${String(minutes).padStart(2, "0")}`;
}

/**
 * converts JSX to string
 * @param {import("react").ReactNode} jsxElement
 */
const jsxToString = (jsxElement) =>
  renderToStaticMarkup(jsxElement) //converts jsx to string
    .replaceAll("<br/>", " ")
    .replaceAll(/<[^>]*>/g, "") //handles the <sub> and <br>'s
    .trim();

/**
 * memoized function that retrieves search parameters (i.e. projectId, simulationId, etc.)
 * usage: useQuery().get("projectId");
 * @returns {URLSearchParams} query parameters
 */
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
}

/**
 * rounds a number to it's nearest second decimal place
 * @param {Number} num A Number to be rounded
 * @param {Number} [decimalPlaces=2] the number of decimal places to round to
 * @returns {Number} 0.00- format
 */
function roundNumber(num, decimalPlaces = 2) {
  if (num === undefined) {
    return "NA";
  }
  return (
    Math.round((Number(num) + Number.EPSILON) * 10 ** decimalPlaces) /
    10 ** decimalPlaces
  );
}

/**
 * Converts a lengthy string to a truncated one with ... after the truncate Limit.
 * @param {String} string the string that needs to be truncated
 * @param {String} allowedLength only truncate if string length is larger than allowedLength
 * @param {Number} truncateLimit beyong this length all characters will be replaced by a ...
 * @returns {String} returns the truncated string if length is larger than allowed length else returns the string as it is
 */
function stringTruncate(string, allowedLength, truncateLimit) {
  if (typeof string !== "string")
    throw new Error(
      "TypeError:cannot truncate a non-string type. must be a string"
    );
  if (string.length < allowedLength) return string;
  return string.slice(0, truncateLimit) + "...";
}

/**
 * Capitalizes the first character of string.
 * @param {String} string the string that needs to be capitalised
 * @returns {String} returns the capitalised string
 */
function stringCapitalize(string) {
  if (typeof string !== "string")
    throw new Error(
      "TypeError:cannot capitalize a non-string type. must be a string"
    );
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Prevents the "Enter" key from submitting a row in material table
 * @param {Event} e - keyDown event
 */
const handleKey = (e) => {
  if (e.code == "Enter") e.stopPropagation();
};

const numberUS = Intl.NumberFormat(navigator.language, {
  style: "decimal",
});

/**
 * accepts either a function or a value, and returns either the passed in value, or the function executed on the second param
 * primarily used to prevent any existing (or future) materialReactTableProps from being overwritten by partial definitions of options on a per-table basis
 * @param {Function | value | undefined} fn a function to be executed on the second param or a value
 * @param {value} [arg=undefined] the value to be passed into the function
 * @returns {value} Either the output of fn(arg) or the value passed in for fn
 */
function parseFromValuesOrFunc(fn, arg = undefined) {
  return fn instanceof Function ? fn(arg) : fn;
}

/** a short hand helper function that adds a "unit-of-measurement" style to units
 * @param {string} units the text to be formatted (Typically units)
 * @param {object} [props={}] additional props, if any, to apply to the <em>
 * @returns {import("react").ReactNode} the string, wrapped in the em
 */
const unitWrapper = (units, props = {}) => (
  <em className="unit-of-measurement" {...props}>
    {units}
  </em>
);

/**
 * Given a list of lists, creates a CSV
 * partially sourced from https://stackoverflow.com/a/14966131 and filefy package
 * @param {string[][]} data the data to save to a CSV.
 * - is a list (table) of lists (rows)
 * - first row (list) should be the column headers
 * - an empty cell is just an empty string in a list
 * @param {string} [fileName="data"] the name of the CSV file to be downloaded
 */
async function exportCSV(data, fileName = "data") {
  let newData = await getHeaderData();
  newData = newData.concat(data);
  if (window.navigator.msSaveOrOpenBlob) {
    //microsoft edge handling
    var blob = new Blob(newData);
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    let CSVContent =
      "data:text/csv;charset=utf-8,\uFEFF" +
      newData.map((row) => row.join(",")).join("\n");

    const encodedURI = encodeURI(CSVContent);

    //create a link containing file download, and auto-click it
    var link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
  }
}

/**
 * returns an array of arrays, to be used for excel and csv initialization
 * @returns {Array[]}
 */
async function getHeaderData() {
  const outputArray = [["Project Name", "Depot Name", "Analysis Name"]];
  const projectPromise = getLocalData("project", "data");
  const simulationPromise = getLocalData("simulation", "data");
  const [{ data: projectData }, { data: simulationData }] = await Promise.all([
    projectPromise,
    simulationPromise,
  ]);
  outputArray.push([
    projectData.name,
    Cookie.get("depot_name"),
    simulationData.name,
  ]);
  outputArray.push([]);
  if (simulationData.description) {
    outputArray[0].push("Analysis Description");
    outputArray[1].push(simulationData.description);
  }
  if (Cookie.get("feeder_id")) {
    outputArray[0].push("Feeder ID");
    outputArray[1].push(Cookie.get("feeder_id"));
  }

  return outputArray;
}

/**
 * defines xlsx header
 * @returns {{worksheet: XLSX.WorkSheet, sheet_options: import("xlsx").SheetJSONOpts}}
 */
async function initXLSX() {
  const aoa = await getHeaderData();
  return {
    worksheet: XLSX.utils.aoa_to_sheet(aoa),
    sheet_options: { origin: "A4" }, //starts any future sheets from the fourth row, to allow room for specialized header
  };
}

/**
 * Given a list of JSONs, creates a XLSX file
 * @param {JSON[]} data the data to save to a XLSX.
 * - is a list (table) of JSONs (rows)
 * - JSON keys are column headers
 * - an empty cell is just an empty string in a list
 * @param {string} [fileName="data"] the name of the XLSX file to be downloaded
 * @param {object} [options] function options
 * @param {boolean} [options.excludeHeader=false] hides the initXLSX header
 */
async function exportXLSX(
  data,
  fileName = "data",
  options = { excludeHeader: false }
) {
  //note: the exportCSV function could be made practically identical to this one, but it may not work for things like tcoTable
  let worksheet;
  if (options.excludeHeader) worksheet = XLSX.utils.json_to_sheet(data);
  else {
    const initial_xlsx = await initXLSX();
    worksheet = initial_xlsx.worksheet;
    XLSX.utils.sheet_add_json(worksheet, data, initial_xlsx.sheet_options);
  }
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

/**
 *
 * @param {React.ChangeEvent<HTMLInputElement>} e
 * @returns {Array<string | undefined>[]} output an array of arrays
 */
function readExcelFileAsync(e) {
  return new Promise((resolve, reject) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //get first worksheet
      resolve(
        XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false,
          raw: false,
        })
      );
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export {
  AMPM_to_Military,
  clearLocalDb,
  errorHandler,
  exportCSV,
  exportXLSX,
  fetchCountries,
  fetchStates,
  getLocalData,
  getSubdomain,
  handleKey,
  Icons,
  initXLSX,
  jsxToString,
  MFM_to_AMPM,
  MFM_to_Military,
  MFM_to_TimeRemaining,
  Military_to_AMPM,
  Military_to_MFM,
  Nominatim,
  numberUS,
  parseFromValuesOrFunc,
  partialClearLocalData,
  readExcelFileAsync,
  ReverseNominatim,
  roundNumber,
  SnackBarContent,
  storeLocalData,
  stringCapitalize,
  stringTruncate,
  unitWrapper,
  useQuery,
};

