import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  changePasswordURL,
  userProfileURL,
} from "../../static/constants/backendRoutes";
import honorifics from "../../static/constants/honorifics.json";
import BannerImage from "../../static/images/banner.jpeg";
import UseAuth from "../auth/useAuth";
import { errorHandler, useQuery } from "../utils";

export default function UserProfile() {
  const UserID = useQuery().get("user_id");
  const [userProfile, setUserProfile] = useState(undefined);
  const [error, setError] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  //for if the user wants to view what they're typing for the OLD password when changing passwords
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  /** additional props shared by all input Textfields */
  const inputProps = {
    required: true,
    fullWidth: true,
    inputProps: { disabled: !editMode }, //prevents user from editting input when not in edit mode
    variant: editMode ? "filled" : "standard",
  };

  const { accessRights } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    /**
     * fetches the currently signed in user's profile data from backend
     */
    async function fetchProfile() {
      const headers = { Authorization: `Token ${UseAuth("get")}` };

      fetch(userProfileURL + (UserID ? `?user_id=${UserID}` : ""), {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok)
            response.json().then((data) => setUserProfile(data.data));
          else {
            errorHandler(
              response,
              snackBarElement,
              "Failed to get user profile details"
            );
            setError(true);
          }
        })
        .catch((error) => {
          console.log(error);
          snackBarElement.current.displayToast(
            "Network Error occurred while trying to get user profile details",
            "error",
            5000
          );
          setError(true);
        });
    }

    fetchProfile();
  }, [UserID]);

  if (error) {
    //if error
    return (
      <h3 className="centered">Something Went Wrong Retrieving Account Data</h3>
    );
  } else if (!userProfile) {
    //if profile has not yet been recieved from the backend, display loading
    return (
      <div className="centered">
        Loading...
        <br />
        <CircularProgress variant="indeterminate" />
      </div>
    );
  }

  /**
   * Sends updated user profile details to backend
   * @param {SubmitEvent} event
   */
  function handleUpdateProfile(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let formJSON = Object.fromEntries(formData.entries());

    //checks that at least 1 field has been altered, before making backend update
    const isModified = !Object.entries(formJSON).every(
      ([key, value]) => value == (userProfile && userProfile[key])
    );
    if (!isModified) {
      snackBarElement.current.displayToast(
        "User Profile was not altered from Original",
        "info"
      );
      setEditMode(false);
      return;
    }

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };
    setButtonLoading(true);

    fetch(userProfileURL, {
      method: "PATCH",
      headers,
      body: JSON.stringify({ ...formJSON, user_id: UserID ?? undefined }), // if updating your own profile, don't bother sending any user_id; "??" is used, as UserId should be undefined instead of null in some scenarios
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(
            "User Profile Updated",
            "success"
          );
          // update the userProfile without using "setUserProfile", to avoid warning about "reassigning defaultValues" (specifically, the select's) in console between the userProfile re-render and the editMode rerender
          Object.keys(formJSON).forEach(
            (key) => (userProfile[key] = formJSON[key])
          );
          // setUserProfile({ ...userProfile, ...formJSON });
          setEditMode(false);
        } else {
          errorHandler(response, snackBarElement);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Something went wrong",
          "error",
          4000
        );
      })
      .finally(() => {
        setButtonLoading(false);
      });
  }

  //todo: consider replacing this with a general form component
  /** Changes the Password on backend
   * when the dialog box's "submit" button is clicked.
   * Then, displays "password changed" closes the dialog box
   */
  function handleChangePassword() {
    const newPassword = {
      //retrieves the password values from the dialog box's TextFields
      old_password: document.getElementById("old_password").value,
      new_password1: document.getElementById("new_password1").value,
      new_password2: document.getElementById("new_password2").value,
    };

    if (newPassword.new_password1 !== newPassword.new_password2) {
      snackBarElement.current.displayToast(
        "New passwords do not match",
        "error"
      );
      return;
    }

    fetch(changePasswordURL, {
      method: "POST",
      headers: {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPassword),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((message) => {
            snackBarElement.current.displayToast("Password Updated"); //displays success message in top-right
            setOpenPasswordDialog(false); //closes "Change Password" dialog box
            setShowOldPassword(false); //reverts the OLD password box back to "****" mode, in case the user previously made it visible
          });
        } else {
          console.log(response);
          snackBarElement.current.displayToast(
            "Failed to Change Password",
            "error"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Failed to Change Password",
          "error"
        );
      });
  }

  return (
    <>
      <Box
        sx={{
          height: "25vh",
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          zIndex: -1,
        }}
      >
        <Avatar
          sx={{
            width: 80,
            height: 80,
            fontSize: "40px",
            backgroundColor: "#387af6",
            fontWeight: "500",
            left: "5vh",
            bottom: "-5vh",
            position: "absolute",
          }}
        >
          {userProfile?.first_name?.charAt(0)?.toUpperCase()}
        </Avatar>
      </Box>
      <Container
        component="form"
        onSubmit={handleUpdateProfile}
        sx={{ mt: "5vh" }}
      >
        <br />
        <Typography
          variant="h5"
          gutterBottom
          align="left"
          className="page-title"
        >
          Account Profile
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={3} sm={2} md={1}>
            <TextField
              id="title"
              name="title"
              label="Title"
              type="text"
              select //makes this textfield a dropdown
              defaultValue={userProfile.title}
              {...inputProps}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  //makes the text field color match the others when disabled
                  WebkitTextFillColor: "black",
                },
              }}
              SelectProps={{
                IconComponent: () => null, //hides the dropdown arrow Icon (would cover up "Miss." title otherwise)
                inputProps: {
                  sx: {
                    textAlign: "left", //prevents the text from center-aligning
                    pr: "0px !important", // ignores the spacing provided for the dropdown arrow Icon
                  },
                },
              }}
            >
              {honorifics.map((title) => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={9} sm={10} md={5}>
            <TextField
              id="first_name"
              name="first_name"
              label="First Name"
              type="text"
              defaultValue={userProfile.first_name}
              {...inputProps}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="last_name"
              name="last_name"
              label="Last Name"
              type="text"
              defaultValue={userProfile.last_name}
              {...inputProps}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="job_title"
              name="job_title"
              label="Job Title"
              type="text"
              defaultValue={userProfile.job_title}
              {...inputProps}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="user_role"
              name="user_role"
              label="User Role"
              type="text"
              defaultValue={userProfile.user_role}
              fullWidth
              inputProps={{ disabled: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="email"
              defaultValue={userProfile.email}
              fullWidth
              inputProps={{ disabled: true }}
            />
          </Grid>
          {userProfile?.partner && (
            <Grid item xs={12} sm={6}>
              <TextField
                id="partner"
                name="partner"
                label="Partner"
                type="text"
                defaultValue={userProfile.partner}
                fullWidth
                inputProps={{ disabled: true }}
              />
            </Grid>
          )}
          {userProfile?.customer && (
            <Grid item xs={12} sm={6}>
              <TextField
                id="customer"
                name="customer"
                label="Customer"
                type="text"
                defaultValue={userProfile.customer}
                fullWidth
                inputProps={{ disabled: true }}
              />
            </Grid>
          )}
        </Grid>
        <br />
        <br />
        <br />
        <br />
        {!UserID && (
          <Button
            variant="outlined"
            className="btn"
            sx={{ width: "45%", mr: "5%" }}
            onClick={() => setOpenPasswordDialog(true)}
          >
            Change Password
          </Button>
        )}
        {editMode ? (
          <LoadingButton
            variant="contained"
            className="btn"
            sx={{ width: "45%" }}
            type="submit"
            loading={buttonLoading}
          >
            Save Changes
          </LoadingButton>
        ) : (
          <Button
            variant="outlined"
            className="btn"
            sx={{ width: "45%" }}
            onClick={(e) => {
              e.preventDefault(); //prevents auto-submitting form
              setEditMode(true);
            }}
            disabled={!(!UserID || accessRights.admin.update_user_profile)}
          >
            Edit User Profile
          </Button>
        )}
      </Container>

      {/* The Dialog box for Changing Password */}
      <Dialog
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
        fullWidth
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            fullWidth
            id="old_password"
            label="Old Password"
            type={showOldPassword ? "text" : "password"}
            variant="standard"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            fullWidth
            autoComplete="new-password" //disables auto-fill (NOTE: not supported on Chrome)
            id="new_password1"
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            variant="standard"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            fullWidth
            autoComplete="new-password"
            id="new_password2"
            label="Re-Enter New Password"
            type={showNewPassword ? "text" : "password"}
            variant="standard"
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPasswordDialog(false)}>Cancel</Button>
          <Button type="submit" onClick={handleChangePassword}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
