import MaterialTable from "@material-table/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import Cookie from "js-cookie";
import { useContext, useEffect, useState } from "react";

import { SnackBarContext } from "../../../contexts/snackBarContext";
import { tariffURL } from "../../../static/constants/backendRoutes";
import { tariffStyleKeyLookup } from "../../../static/constants/stepInfo";
import UseAuth from "../../auth/useAuth";
import DetailedTariffInputs from "../../projectPages/dialogs/detailedTariffInputs"; //used for previews
import { errorHandler, getLocalData, stringCapitalize } from "../../utils";
import { monthNames } from "../pages/financialAnalysis";

export default function TariffImportTable({
  onClose,
  setFinancialInputs,
  storeInput,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(0);
  //VVV preview tariff states VVV
  const [previewId, setPreviewId] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [depotLookup, setDepotLookup] = useState({
    name: { 0: "Loading..." },
    currency_code: { 0: "USD" },
  }); //only used for tariff previews

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    async function fetchData() {
      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };
      const { data: sim } = await getLocalData("simulation", "data");

      fetch(`${tariffURL}?depot_id=${sim.depot_id}`, { method: "GET", headers })
        .then((response) => {
          if (response.ok) {
            response.json().then(({ data }) => {
              setDepotLookup({
                //for tariff previews
                name: { [sim.depot_id]: Cookie.get("depot_name") },
                currency_code: { [sim.depot_id]: Cookie.get("currency_code") },
              });
              setData(data);
              setIsLoading(false);
            });
          } else
            errorHandler(
              response,
              snackBarElement,
              "Failed to retrieve tariffs"
            );
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Network Error: Failed to retrieve tariffs",
            "error"
          );
          return -1;
        });
    }

    fetchData();
  }, []);

  /**
   * closes tariff preview
   */
  function handleClosePreview() {
    setPreviewOpen(false);
    setTimeout(() => setPreviewId(0), 1000);
  }

  /**
   * Imports the tariff data
   * @param {SubmitEvent} e
   */
  function handleSubmit(e) {
    e.preventDefault();

    if (selectedRowId) {
      const newTariff = data.find((tariff) => tariff.id == selectedRowId);
      const months = monthNames.map((month) => month.toLowerCase());
      const formattedData = months.map((month) =>
        Array(24)
          .fill()
          .map((_, hour) => ({
            energy: newTariff.tariffs.energy[month][hour],
            demand: newTariff.tariffs.demand[month][hour],
          }))
      );
      const periodStyle = newTariff.tariffs?.period;

      setFinancialInputs((prev) => ({
        ...prev,
        energySaved: newTariff.tariff_style,
        energyCosts: formattedData,
        periodStyle,
      }));
      storeInput(formattedData, newTariff.tariff_style, periodStyle);
      onClose();
    } else {
      snackBarElement.current.displayToast(
        "Must select a tariff to import",
        "error",
        5000
      );
    }
  }

  /** @type {import("@material-table/core").Column<never>[]} */
  const columns = [
    { title: "Utility", field: "utility_name" },
    { title: "Tariff", field: "name" },
    {
      title: "Style",
      field: "tariff_style",
      render: (rowData) =>
        stringCapitalize(tariffStyleKeyLookup[rowData.tariff_style]),
    },
  ];

  return (
    <>
      <DialogContent>
        <MaterialTable
          title="Select Tariff to Import"
          data={data}
          columns={columns}
          isLoading={isLoading}
          options={{
            selection: true, //allows user to select rows
            showSelectAllCheckbox: false, //hides "select all rows" button
            showTextRowsSelected: false, //header does not display "N Rows Selected"
            selectionProps: (rowData) => ({
              //only allows one row to be displayed as "selected" at a time
              checked: rowData.tableData.id == selectedRowId,
            }),
          }}
          onRowClick={(_e, rowData) => setSelectedRowId(rowData.tableData.id)}
          onSelectionChange={(_selectedRows, newRowSelection) =>
            setSelectedRowId(newRowSelection.tableData.id)
          }
          actions={[
            {
              icon: VisibilityIcon,
              tooltip: "Preview",
              onClick: (event, rowData) => {
                setPreviewOpen(true);
                setPreviewId(rowData.id);
              },
              position: "row", //necessary to avoid bug that would hide button caused by selection: true option (bug occurs here: https://github.com/mbrn/material-table/blob/b26e2f715abb2217f0d14e1efb15c6367fe12de1/src/material-table.js#L197)
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Import Tariff</Button>
      </DialogActions>
      {/* Preview dialog */}
      <Dialog open={previewOpen} onClose={handleClosePreview} maxWidth="lg">
        <DetailedTariffInputs
          initialData={data?.find((tariff) => tariff.id == previewId)}
          depotLookup={depotLookup}
          depotId={Object.keys(depotLookup.name)[0]}
          disabled={true}
        />
        <DialogActions>
          <Button onClick={handleClosePreview}>Close Preview</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
