import { createTheme } from "@mui/material/styles";

const secondaryBlue = "rgb(56, 122, 246)";

export default createTheme({
  palette: {
    primary: { main: "#282c34" },
    secondary: { main: secondaryBlue },
  },
  // TODO: remove all "variant: standard"'s of MUI TextFields throughout all files, and look for other universally identical props that can be set here
  components: { MuiTextField: { defaultProps: { variant: "standard" } } },
});

const ReactSpreadSheetImportTheme = {
  colors: {
    highlight: secondaryBlue,
    blue: { 500: secondaryBlue },
    green: { 500: secondaryBlue }, //makes the green into blue
    red: { 50: "#ef535080" },
    orange: { 50: "#ff980080" },
    rsi: {
      400: secondaryBlue,
      500: secondaryBlue,
      600: secondaryBlue,
    },
    customButton: { 500: "black" },
  },
  components: {
    Modal: {
      variants: { rsi: { dialog: { borderRadius: 4 } } },
      baseStyle: { closeModalButton: { position: "fixed" } },
    },
    Button: {
      defaultProps: { colorScheme: "customButton" },
      baseStyle: { borderRadius: "16px", fontWeight: 500 },
    },
  },
};

const StepperStyle = {
  "& .MuiStepLabel-root .Mui-completed": {
    color: "secondary.main", // circle color (COMPLETED)
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
    color: "primary.main", // Just text label (COMPLETED)
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: "secondary.main", // circle color (ACTIVE)
  },
  "& .MuiStepLabel-iconContainer .Mui-active": {
    transform: "scale(1.35)",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "primary.main", // Just text label (ACTIVE)
  },
  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
    fill: "white", // circle's number (ACTIVE)
  },
  "& .MuiStepLabel-iconContainer": {
    transform: "scale(1.25)",
  },
};

export { ReactSpreadSheetImportTheme, StepperStyle };
