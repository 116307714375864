import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import { DataContext } from "../../../../contexts/dataContext";
import { localDb } from "../../../../contexts/localDb";
import { SnackBarContext } from "../../../../contexts/snackBarContext";
import {
  annualLoadURL,
  fiveDayScheduleURL,
  simulationURL,
} from "../../../../static/constants/backendRoutes";
import stepInfo from "../../../../static/constants/stepInfo";
import UseAuth from "../../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../../secondary/steppers";
import Subheader from "../../../secondary/subheader";
import {
  getUnits,
  unitMiles,
  unitPerMile,
} from "../../../secondary/unitConversions";
import {
  MFM_to_AMPM,
  errorHandler,
  getLocalData,
  initXLSX,
  partialClearLocalData,
  storeLocalData,
} from "../../../utils";
import { NextPageButton } from "../../commonComponents";
import { AnnualLoadProfileInputs } from "../../dialogs/editAnalysisInputs";
import SimulationSubtitle from "../../dialogs/simulationSubtitle";
import EnergyAnalysisChart from "../../graphs/energyAnalysisChart";

const STEP_NUMBER = 5;

/**
 * the fleet Sizing page
 * @returns {JSX} fleet Sizing component
 */
export default function EnergyAnalysis() {
  const [loadProfile, setLoadProfile] = useState({
    //contains the page's data
    full_power: [],
    low_power: [],
  });
  const [subheaderContent, setSubheaderContent] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const isDownloadDropdownOpen = Boolean(downloadAnchorEl);
  const [annualDownloadOpen, setAnnualDownloadOpen] = useState(false);
  //inputs are a state instead of a standard form, due to how much they affect other fields in dialog box
  const [annualDialogInputs, setAnnualDialogInputs] = useState(undefined);
  const [sim, setSim] = useState(undefined); //the indexDb sim data

  const navigate = useNavigate();
  const { snackBarElement } = useContext(SnackBarContext); //for the toast notifications when an error occurs
  const { accessRights } = useContext(DataContext);
  const unit = getUnits();

  /**
   * gets the profile (chart) data from indexDB
   */
  useEffect(() => {
    async function fetchData() {
      getLocalData("simulation", "data").then(({ data }) => setSim(data));

      //normal chart data fetch and set
      const chartData = await getLocalData("evAssessment", "data").then(
        ({ data }) => data?.load_profile
      );
      setLoadProfile({
        full_power: chartData?.full_power || [],
        low_power: chartData?.low_power || [],
      });

      //subheader data computation & fetch + set (not in separate useEffect, since the loadProfile is static on this page)
      if (chartData?.full_power?.length && chartData?.low_power?.length) {
        let [maxUnmanaged, maxManaged] = [
          chartData.full_power[0],
          chartData.low_power[0],
        ];

        const low_power = chartData.low_power;

        chartData.full_power.forEach((full_power, index) => {
          maxUnmanaged = Math.max(full_power, maxUnmanaged);
          maxManaged = Math.max(low_power[index], maxManaged);
        });

        //note: since we only need the input, it's faster to do just a .get("input") than to do a getLocalData (due to the large size of block's data)
        //todo: combine the below promises into a single promise
        const { input: initialDownloadParameters } = await getLocalData(
          "blocks",
          "input"
        );
        setAnnualDialogInputs(initialDownloadParameters);

        // fetches the energy of every SELECTED row in the battery sizing page, and adds them all together, to retrieve the total energy expenditure PER DAY, for display in the simulation subtitle
        const sumEnergy = await getLocalData("battery", "data").then(
          ({ data }) =>
            data?.blocks
              ?.filter((row) => row?.checked || row?.tableData?.checked)
              ?.reduce(
                (partialSumEnergy, fullBatteryRow) =>
                  fullBatteryRow?.energy + partialSumEnergy,
                0
              )
        );

        setSubheaderContent([
          {
            value: Math.round(maxUnmanaged).toLocaleString(),
            label: "Unmanaged Charging Peak Power (kW)",
          },
          {
            value: Math.round(maxManaged).toLocaleString(),
            label: "Managed Charging Peak Power (kW)",
          },
          {
            value: Math.round(sumEnergy).toLocaleString(),
            label: "Total Daily Energy (kWh)",
          },
        ]);
      }
    }

    fetchData();
  }, []);

  /**
   * the bare minimum of a "next page" button function.
   * changes the backend's "current_page" value, and navigates to
   * financial analysis
   * TODO: Move financial analysis' initialization request into this function, and store result on backend and indexdb
   */
  const handleSubmit = async () => {
    setButtonLoading(true);
    try {
      if (sim.analysis_type_steps.depot_energy_analysis?.annual_load_profile) {
        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
          Accept: `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.annual_load_profile}`,
        };

        //format start and end dates for backend
        const start_date = annualDialogInputs.start_date.split("-");
        const end_date = annualDialogInputs.end_date.split("-");

        const { data: block_schedule } = await getLocalData(
          "routeEnergy",
          "data"
        );

        const body = {
          block_schedule,
          load_profile: { full_power: loadProfile.full_power },
          ...annualDialogInputs,
          start_date: `${start_date[1]}/${start_date[2]}/${start_date[0]}`,
          end_date: `${end_date[1]}/${end_date[2]}/${end_date[0]}`,
          resolution: 15,
        };

        fetch(annualLoadURL, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }).then((res) => {
          delete headers.Accept;
          if (res.ok) {
            res
              .json()
              .then(async ({ data: { annual_load, operation_days } }) => {
                const evAssessment = await localDb.transaction(
                  "rw",
                  localDb.evAssessment,
                  localDb.tco,
                  async () => {
                    //clear out the future pages' frontend data
                    partialClearLocalData(["tco"]);

                    //update the evAssessment with the loadProfile
                    // note: this can be further simplified down the line, once I've broken the indexDb tables into more detailed rows
                    await localDb.evAssessment.update("data", {
                      "value.load_profile.annual15": annual_load,
                    });
                    await localDb.evAssessment.update("data", {
                      "value.operation_days": operation_days,
                    });
                    const { data: evAssessment } = await getLocalData(
                      "evAssessment",
                      "data"
                    );
                    return evAssessment;
                  }
                );

                const backendBody = {
                  id: sim.id,
                  completed: false,
                  current_page: stepInfo[STEP_NUMBER + 1].route,
                  steps: {
                    evAssessment: evAssessment, //update evAssessment with annualLoad15
                    tco: {},
                  },
                  // note: block input's annualDialogInputs is not included here, as it is already handled in it's own update function
                };

                fetch(simulationURL, {
                  method: "PATCH",
                  headers: headers,
                  body: JSON.stringify(backendBody),
                }).then((response) => {
                  if (response.ok) {
                    snackBarElement.current.displayToast(
                      `${stepInfo[STEP_NUMBER].label} Analysis Complete`
                    );
                    navigate(stepInfo[STEP_NUMBER + 1].route);
                  } else {
                    errorHandler(
                      response,
                      snackBarElement,
                      "Failed to begin Financial Analysis"
                    );
                    setButtonLoading(false);
                  }
                });
              });
          }
        });
      } else {
        //older version of the code
        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };

        //clear out the future pages' frontend data
        partialClearLocalData(["tco"]);
        const backendBody = {
          id: sim.id,
          completed: false,
          current_page: stepInfo[STEP_NUMBER + 1].route,
          steps: {
            tco: {},
          },
          // note: block input's annualDialogInputs is not included here, as it is already handled in it's own update function
        };

        fetch(simulationURL, {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(backendBody),
        }).then((response) => {
          if (response.ok) {
            snackBarElement.current.displayToast(
              `${stepInfo[STEP_NUMBER].label} Analysis Complete`
            );
            navigate(stepInfo[STEP_NUMBER + 1].route);
          } else {
            errorHandler(
              response,
              snackBarElement,
              "Failed to begin Financial Analysis"
            );
            setButtonLoading(false);
          }
        });
      }
    } catch (e) {
      snackBarElement.current.displayToast(
        "Failed to begin Financial Analysis",
        "error",
        7000
      );
      console.log(e);
      setButtonLoading(false);
    }
  };

  /** opens the CSV download selection dropdown */
  const handleOpenDownloadCSV = (e) => setDownloadAnchorEl(e.currentTarget);
  /** closes the CSV download selection dropdown */
  const handleCloseDownloadCSV = (e) => setDownloadAnchorEl(null);
  /** opens the annual download load profile paramters dialog box */
  const handleOpenAnnualDownload = (e) => {
    setAnnualDownloadOpen(true);
    handleCloseDownloadCSV();
  };
  /** closes the annual download load profile paramters dialog box */
  const handleCloseAnnualDownload = (e) => setAnnualDownloadOpen(false);

  /**
   * Handles annual load profile updates made by user
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  const handleSaveAnnualLoadInputs = async (event) => {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    if (newDataJSON?.start_date > newDataJSON?.end_date) {
      snackBarElement?.current?.displayToast(
        "Start Date must come before End Date",
        "warning",
        5000
      );
      return;
    }
    if (
      annualDialogInputs.start_date == newDataJSON.start_date &&
      annualDialogInputs.end_date == newDataJSON.end_date &&
      annualDialogInputs.resolution == newDataJSON.resolution &&
      annualDialogInputs.weekend.operate ==
        (newDataJSON.weekendOperation === "on" ? 1 : 0) &&
      annualDialogInputs.weekend.sat == newDataJSON?.sat &&
      annualDialogInputs.weekend.sun == newDataJSON?.sun
    ) {
      snackBarElement?.current?.displayToast(
        "Inputs not altered from last save",
        "info",
        5000
      );
      return;
    }
    setButtonLoading(true);

    const updatedAnnualDialogInputs = {
      ...annualDialogInputs, //include the original annualDialogInputs, to maintain the hvac (and any other keys we add to blocks down the line)
      weekend: {
        operate: newDataJSON.weekendOperation === "on" ? 1 : 0,
        sat: newDataJSON?.sat ? parseInt(newDataJSON.sat) : 0,
        sun: newDataJSON?.sun ? parseInt(newDataJSON.sun) : 0,
      },
      start_date: newDataJSON.start_date,
      end_date: newDataJSON.end_date,
      resolution: parseInt(newDataJSON.resolution),
    };

    //and update the blocks for it
    storeLocalData("blocks", { input: updatedAnnualDialogInputs });

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };
    const backendBody = {
      id: sim.id,
      steps: { input: { blocks: updatedAnnualDialogInputs } },
    };
    fetch(simulationURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(backendBody),
    }).then((response) => {
      if (!response?.ok) {
        errorHandler(
          response,
          snackBarElement,
          "Error saving changes to server."
        );
      }
    });

    setAnnualDialogInputs(updatedAnnualDialogInputs);
    setButtonLoading(false);
  };

  /**
   * downloads a CSV of the load profile data
   * @param {String} resolution either minute, 15_min, or hour, determines how much data to return
   */
  const handleDownloadCSV = async (resolution = "minute") => {
    //per minute view
    let data = loadProfile.full_power.map((full, index) => ({
      Time: resolution == "minute" ? MFM_to_AMPM(index) : undefined,
      "Full Power": full,
      "Low Power": loadProfile.low_power[index],
    }));

    //if by the hour/15 min, average out every hour/15 min
    if (resolution === "hour" || resolution === "15_min") {
      const denom = resolution === "hour" ? 60 : 15;
      data = data.reduce(
        (averageData, minute, index) => {
          let sum = averageData.pop();
          sum = {
            "Full Power": sum["Full Power"] + minute["Full Power"],
            "Low Power": sum["Low Power"] + minute["Low Power"],
          };
          if (index % denom === denom - 1) {
            sum = {
              Time: MFM_to_AMPM(index + 1 - denom),
              "Full Power": sum["Full Power"] / denom,
              "Low Power": sum["Low Power"] / denom,
            };
            averageData.push(sum);
            averageData.push({ "Full Power": 0, "Low Power": 0 });
          } else {
            averageData.push(sum);
          }
          return averageData;
        },
        [{ "Full Power": 0, "Low Power": 0 }]
      );
      data.pop(); // removes the trailing 0
    }

    const { worksheet, sheet_options } = await initXLSX();
    XLSX.utils.sheet_add_json(worksheet, data, sheet_options);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Depot Energy");
    XLSX.writeFile(workbook, "Depot Energy.csv");
    handleCloseDownloadCSV();
  };

  /**
   * downloads an annual load profile CSV based off user inputs
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  const handleDownloadAnnualLoadProfile = async (event) => {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    if (newDataJSON?.start_date > newDataJSON?.end_date) {
      snackBarElement?.current?.displayToast(
        "Start Date must come before End Date",
        "warning",
        5000
      );
      return;
    }
    setButtonLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.annual_load_profile}`,
    };

    const { data: block_schedule } = await getLocalData("routeEnergy", "data");

    //format start and end dates for backend
    const start_date = newDataJSON.start_date.split("-");
    const end_date = newDataJSON.end_date.split("-");

    const body = {
      block_schedule,
      load_profile: { full_power: loadProfile.full_power },
      weekend: {
        operate: newDataJSON.weekendOperation === "on" ? 1 : 0,
        sat: newDataJSON?.sat ? parseInt(newDataJSON.sat) : 0,
        sun: newDataJSON?.sun ? parseInt(newDataJSON.sun) : 0,
      },
      start_date: `${start_date[1]}/${start_date[2]}/${start_date[0]}`,
      end_date: `${end_date[1]}/${end_date[2]}/${end_date[0]}`,
      resolution: parseInt(newDataJSON.resolution),
    };

    fetch(annualLoadURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(async ({ data: responseData }) => {
            const resolution = body.resolution;
            const time = moment(`20230101`, "YYYYMMDD");
            time.subtract(resolution, "minutes");
            let transposedJSON = responseData.annual_load.map((power) => ({
              DateTime: time
                .add(resolution, "minutes")
                .format("M/D/YY h:mm:ss A"),
              "Power (kW)": power,
            }));

            const { worksheet, sheet_options } = await initXLSX();
            XLSX.utils.sheet_add_json(worksheet, transposedJSON, {
              ...sheet_options,
              header: ["DateTime", "Power (kW)"],
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Depot Energy");
            XLSX.writeFile(workbook, "Depot Energy.csv");
            setButtonLoading(false);
          });
        } else {
          errorHandler(response, snackBarElement, "Something went wrong");
          setButtonLoading(false);
        }
      })
      .catch((e) => {
        snackBarElement.current.displayToast(
          "Failed to generate annual Depot Energy CSV",
          "error",
          7000
        );
        console.log(e);
        setButtonLoading(false);
      });
  };

  /**
   * determines which annual load profile button was clicked
   * it's own separate function, so that the two buttons can share the
   * same form values
   * @param {React.FormEvent<HTMLDivElement>} e
   */
  function handleSubmitAnnualLoad(e) {
    e.preventDefault();
    if (e?.nativeEvent?.submitter?.name == "download_annual_btn")
      handleDownloadAnnualLoadProfile(e);
    else handleSaveAnnualLoadInputs(e);
  }

  function handleDownload5DaySchedule(e) {
    e.preventDefault();
    handleCloseDownloadCSV();

    //gets the battery sizing table data from indexdb and creates array of all blocks/rows that were selected on the battery page
    const batterySizingDataPromise = getLocalData("battery", "data").then(
      ({ data }) =>
        data?.blocks?.filter(
          (block) => block?.checked || block?.tableData?.checked
        )
    );
    //gets the selected feasible row from the fleet and charger sizing page

    const fleetSizingBevDataPromise = getLocalData("fleetSizing").then(
      ({ data, input }) => data?.feasible?.combos?.[input?.row]
    );
    //gets the operating_year value from the user's
    const operatingYearPromise = getLocalData("routeEnergy", "input").then(
      ({ input }) => input?.operating_year
    );

    Promise.all([
      batterySizingDataPromise,
      fleetSizingBevDataPromise,
      operatingYearPromise,
    ])
      .then(([batterySizingData, fleetSizingBevData, operating_year]) => {
        const body = {
          depot_id: batterySizingData[0].endDepot,
          charger_model: fleetSizingBevData.charger_model.model, //the currently selected FleetSizingRow's chargerModel
          battery_lifetime: operating_year, //operating year from the routeEnergy page
          blocks: batterySizingData.map((block) => ({
            //same block mapping from routeEnergy.js code
            blockId: block.blockId,
            dh_st_time: block.dh_st_time,
            dh_end_time: block.dh_end_time,
            distance: block.distance,
            startDepot: block.startDepot,
            endDepot: block.endDepot,
            vehicleEff: block.detailed_energy.updated_efficiency,
            vehicleModel: block.vehicleModel,
          })),
          //NOTE: layover_time is currently being hard-coded
          min_layover_time: fleetSizingBevData.min_layover_time,
        };

        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
          Accept: `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.five_day_schedules}`,
        };

        fetch(fiveDayScheduleURL, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        })
          .then((res) => {
            if (res.ok) {
              res.json().then(({ data }) => {
                if (unit == 1) {
                  function kmConversionHelper(el) {
                    el["Distance, max (Km)"] = unitMiles(
                      el["Distance, max (Km)"]
                    );
                    el["Distance, min (Km)"] = unitMiles(
                      el["Distance, min (Km)"]
                    );
                    el["Energy used per Km, max (kWh)"] = unitPerMile(
                      el["Energy used per Km, max (kWh)"]
                    );
                    el["Energy used per Km, min (kWh)"] = unitPerMile(
                      el["Energy used per Km, min (kWh)"]
                    );
                  }
                  data.client_five_day_schedule.forEach(kmConversionHelper);
                  data.new_five_day_schedule.forEach(kmConversionHelper);
                } else {
                  function milesConversionHelper(el) {
                    el["Distance, max (mi)"] = el["Distance, max (Km)"];
                    el["Distance, min (mi)"] = el["Distance, min (Km)"];
                    el["Energy used per mi, max (kWh)"] =
                      el["Energy used per Km, max (kWh)"];
                    el["Energy used per mi, min (kWh)"] =
                      el["Energy used per Km, min (kWh)"];

                    delete el["Distance, max (Km)"];
                    delete el["Distance, min (Km)"];
                    delete el["Energy used per Km, max (kWh)"];
                    delete el["Energy used per Km, min (kWh)"];
                  }
                  data.client_five_day_schedule.forEach(milesConversionHelper);
                  data.new_five_day_schedule.forEach(milesConversionHelper);
                }
                async function generateFiveDayBlob(data) {
                  const workbook = XLSX.utils.book_new();
                  const { worksheet, sheet_options } = initXLSX();
                  XLSX.utils.sheet_add_json(worksheet, data, sheet_options);
                  XLSX.utils.book_append_sheet(workbook, worksheet, "Alldays");
                  const workbookBuffer = XLSX.write(workbook, {
                    bookType: "xlsx",
                    type: "array",
                  });
                  return new Blob([workbookBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                  });
                }

                let zip = new JSZip();
                zip.file(
                  "New Five Day Schedule.xlsx",
                  generateFiveDayBlob(data.new_five_day_schedule)
                );
                zip.file(
                  "Client Five Day Schedule.xlsx",
                  generateFiveDayBlob(data.client_five_day_schedule)
                );

                zip
                  .generateAsync({ type: "blob" })
                  .then((blob) => saveAs(blob, "FiveDaySchedule.zip"));
              });
            } else {
              errorHandler(
                res,
                snackBarElement,
                "Failed to generate five day schedule"
              );
            }
          })
          .catch((e) => {
            console.log(e);
            snackBarElement.current.displayToast(
              "Network Error: Failed to generate five day schedule",
              "error",
              5000
            );
          });
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Failed to retrieve data to generate CSV",
          "error",
          5000
        );
      });
  }

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle setAnnualLoadInputs={setAnnualDialogInputs} />
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          <EnergyAnalysisChart data={loadProfile} />
        </Paper>
      </Container>
      <br /> <br />
      <Container fixed>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                component={Link}
                to={stepInfo[STEP_NUMBER - 1].route}
                startIcon={<ArrowBackIosNew />}
              >
                Previous Step: {stepInfo[STEP_NUMBER - 1].label}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NextPageButton
                sx={{ width: "95%" }}
                onClick={handleSubmit}
                loading={buttonLoading}
                disabled={
                  !accessRights.analysis
                    .create_financial_and_emissions_analysis ||
                  !sim ||
                  (!!sim.analysis_type_steps.depot_energy_analysis
                    ?.annual_load_profile &&
                    !annualDialogInputs)
                }
              >
                Continue to {stepInfo[STEP_NUMBER + 1].label} Inputs
              </NextPageButton>
            </Grid>
          </Grid>
          {accessRights.analysis.create_depot_energy_analysis && (
            <Button
              variant="outlined"
              className="btn"
              disabled={!loadProfile.full_power.length}
              onClick={handleOpenDownloadCSV}
              endIcon={isDownloadDropdownOpen ? <ExpandLess /> : <ExpandMore />}
            >
              Download Load Profile
            </Button>
          )}
        </Stack>
        <Menu
          open={isDownloadDropdownOpen}
          anchorEl={downloadAnchorEl}
          onClose={handleCloseDownloadCSV}
          PaperProps={{
            style: { minWidth: downloadAnchorEl?.clientWidth },
            className: "btn",
          }} // makes the dropdown the same size as the button
        >
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={() => handleDownloadCSV("minute")}
          >
            Download Daily Load Profile (1-Minute)
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={() => handleDownloadCSV("15_min")}
          >
            Download Daily Load Profile (15-Minute)
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={() => handleDownloadCSV("hour")}
          >
            Download Daily Load Profile (1-Hour)
          </MenuItem>
          {sim?.analysis_type_steps?.depot_energy_analysis
            ?.annual_load_profile && (
            <MenuItem
              sx={{ justifyContent: "center" }}
              onClick={handleOpenAnnualDownload}
              disabled={!annualDialogInputs}
            >
              Download Annual Load Profile
            </MenuItem>
          )}
          {accessRights.analysis.create_five_day_schedules && (
            <MenuItem
              sx={{ justifyContent: "center" }}
              onClick={(e) => handleDownload5DaySchedule(e)}
              disabled={!sim}
            >
              Download 5 Day Schedules
            </MenuItem>
          )}
        </Menu>
      </Container>
      <Dialog
        open={annualDownloadOpen}
        fullWidth
        maxWidth="md"
        onClose={handleCloseAnnualDownload}
        component="form"
        onSubmit={handleSubmitAnnualLoad}
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Annual Download Parameters
          <FormControlLabel
            control={
              <Checkbox
                name="weekendOperation"
                defaultChecked={Boolean(annualDialogInputs?.weekend?.operate)}
              />
            }
            label="Include Weekends"
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <AnnualLoadProfileInputs inputs={annualDialogInputs} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAnnualDownload}>Close</Button>
          <LoadingButton
            type="submit"
            name="save_annual_btn"
            value="save_annual_btn"
            loading={buttonLoading}
          >
            Save Changes
          </LoadingButton>
          <LoadingButton
            type="submit"
            name="download_annual_btn"
            value="download_annual_btn"
            loading={buttonLoading}
          >
            Download CSV
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
