import { Chip, Container } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Chart } from "chart.js";
import { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import { DataContext } from "../../../contexts/dataContext";
import { MFM_to_AMPM, unitWrapper } from "../../utils";

/**
 *
 * @param {Object} props
 * @param {Boolean} props.type used to determine the viewmode of the chart; true= full_power/unmanaged, false= low_power/managed
 * @param {[React.Dispatch<React.SetStateAction<boolean>>]} props.setType used to toggle the viewmode of the chart
 * @param {Object} props.loadProfile full_power and Low Power for the chart
 * @param {Object} props.tcoData For the managed/unmanaged transformer Capacity

 * @param {String} props.className additional props for the component
 * @param {[Boolean]} props.printOnly if true, only display component during print
 */
export function LoadProfileGraph(props) {
  const chartRef = useRef(null);
  const type = props.type; //true= full_power/unmanaged, false= low_power/managed
  const tcoData = props.tcoData;
  const [printView, setPrintView] = useState(false);
  const [showBlockCharging, setShowBlockCharging] = useState(true); // Track visibility of Block Charging

  const { logo } = useContext(DataContext);

  const handleChange = (event) => {
    props?.setType(event.target.value);
  };

  useEffect(() => {
    // fires both when clicking "generate PDF" and ctrl+p, but only affects page with ctrl+p
    function onBeforePrint() {
      //forcibly resets the zoom when generating pdf
      chartRef?.current?.resetZoom();
      // ensures that the chart is rendered before generating pdf using ctrl + p
      setPrintView(true);
    }

    function onAfterPrint() {
      setPrintView(false);
    }

    window.addEventListener("beforeprint", onBeforePrint);
    window.addEventListener("afterprint", onAfterPrint);

    return function cleanupListener() {
      window.removeEventListener("beforeprint", onBeforePrint);
      window.removeEventListener("afterprint", onAfterPrint);
    };
  }, [chartRef]);

  /** @type {import("chart.js").ChartOptions} */
  const options = {
    interaction: { intersect: false, mode: "index" }, //has the tooltip appear when mouse is anywhere on map, instead of only above specific points
    plugins: {
      legend: {
        position: "top",
        onClick: (e, legendItem) => {
          const index = legendItem.datasetIndex;
          const ci = chartRef.current;
          const meta = ci.getDatasetMeta(index);

          if (index === 1) {
            // Assuming Block Charging is the second dataset
            meta.hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;
            setShowBlockCharging(
              meta.hidden === null ? !ci.data.datasets[index].hidden : false
            );
          } else {
            meta.hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          }
          ci.update();
        },
      },
      title: { display: false, text: "Load Profile", font: { size: 19 } },
      tooltip: {
        position: "nearest", //has the tooltip appear at the point on a line closest to mouse
        callbacks: {
          title: (value) => `Time: ${value[0].label}`, //custom tooltip title to display time
          label: (value) =>
            `${value.dataset.label}: ${value.formattedValue} kW`, //customizes tooltip display for y-axis value
        },
      },
      zoom: {
        pan: { enabled: true, mode: "x", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "x" },
      },
      annotation: {
        annotations: showBlockCharging ? props.annotations : {},
      },
    },
    scales: {
      x: {
        ticks: {
          //has x-axis tick labels only appear once every 3 hours (180 minutes)
          callback: (val) => (val % 180 === 0 ? MFM_to_AMPM(val) : null),
        },
        title: { display: true, text: "Time of Day", font: { size: 19 } }, //label for x-axis
      },
      y: {
        ticks: { font: { size: 16 } },
        title: { display: true, text: ["Power", "(kW)"], font: { size: 19 } },
      },
    },
    animation: false, //disabled, as the pdf generation didn't wait for the animation to finish
  };

  const xAxis = Array.from(Array(1441).keys()).map(MFM_to_AMPM);

  /** @type {import("chart.js").ChartData} */
  const data = {
    labels: xAxis,
    datasets: [
      {
        fill: true,
        label: type ? "Unmanaged" : "Managed",
        data: type ? props.loadProfile.full_power : props.loadProfile.low_power,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        // borderColor: "rgb(255, 99, 132)", //red chart
        // backgroundColor: "rgba(255, 99, 132, 0.5)",
        pointRadius: 0, //the size of a data point's dot on the chart, made 0 to decrease width of line
        tension: 0.2,
      },
    ],
  };

  if (Object.keys(props?.annotations ?? {}).length)
    //hide the annotations label if there aren't any
    data.datasets.push({
      label: "Charging Blocked",
      data: [],
      borderColor: "rgba(255, 99, 132, 0.6)",
      backgroundColor: "rgba(255, 99, 132, 0.25)",
      pointRadius: 0,
      tension: 0,
    });

  return (
    <Box
      className={props.className}
      sx={{
        //used to hide printOnly charts unless the user is downloading a PDF
        display: props?.printOnly && !printView ? "none" : "",
        displayPrint: "block",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          backgroundColor: "#FCFCFC",
          height: "100%",
          "& .MuiBox-root": {
            justifyContent: "space-between",
            m: 3,
            "& span": {
              fontWeight: 500,
              fontSize: "150%",
              verticalAlign: "middle",
            },
          },
        }}
      >
        <Box display="flex">
          <Typography component="span">
            Minimum Recommended Transformer Capacity (
            {type ? "Unmanaged" : "Managed"}
            ):
          </Typography>
          <Typography component="span">
            {
              type
                ? tcoData?.BEV?.transformer_rating_unmanaged //full_power/uncontroled
                : tcoData?.BEV?.transformer_rating_managed //low power/managed
            }
            {unitWrapper(" kVA")}
          </Typography>
        </Box>
      </Paper>
      <br />
      <Paper
        className="chartdiv"
        sx={{
          width: "100%",
          overflow: "hidden",
          displayPrint: "block",
          backgroundColor: "#FCFCFC",
        }}
      >
        <Box m={3} display="flex" justifyContent="space-between">
          <Container sx={{ alignItems: "center", display: "flex" }}>
            <Typography variant="h5" noWrap mr="1%" sx={{ fontWeight: 500 }}>
              Load Profile
            </Typography>

            <FormControl style={{ minWidth: "15%" }} size="small">
              <Select value={type} onChange={handleChange}>
                <MenuItem value={false}>Managed</MenuItem>
                <MenuItem value={true}>Unmanaged</MenuItem>
              </Select>
            </FormControl>
          </Container>
          <Chip
            className="no-print"
            label="Reset Zoom"
            onClick={() => chartRef?.current?.resetZoom()}
          />
        </Box>
        <Line ref={chartRef} options={options} data={data} />
        <div
          style={{ bottom: "70px", right: "50px" }}
          className="watermark-container"
        >
          <img
            src={logo}
            width="auto"
            height="50px"
            className="watermark-img"
          />
        </div>
      </Paper>
    </Box>
  );
}
