// Note: this file is a copy of https://github.com/akursat/react-leaflet-cluster/blob/main/src/index.tsx
// the package no longer appears to be updated, so it was moved here as it is relatively simple, and may require updates as time goes on
import {
  createElementObject,
  createPathComponent,
  extendContext,
  LeafletContextInterface,
} from "@react-leaflet/core";
import L, { LeafletMouseEventHandlerFn } from "leaflet";
import "leaflet.markercluster";
import "./../../MarkerCluster.css";

type ClusterType = { [key in string]: any };

type ClusterEvents = {
  /** Fired when the user clicks (or taps) the layer. */
  onClick?: LeafletMouseEventHandlerFn;
  /** Fired when the user double-clicks (or double-taps) the layer. */
  onDblClick?: LeafletMouseEventHandlerFn;
  /** Fired when the user pushes the mouse button on the layer. */
  onMouseDown?: LeafletMouseEventHandlerFn;
  /** Fired when the user releases the mouse button pushed on the layer. */
  onMouseUp?: LeafletMouseEventHandlerFn;
  /** Fired when the mouse enters the layer. */
  onMouseOver?: LeafletMouseEventHandlerFn;
  /** Fired when the mouse leaves the layer. */
  onMouseOut?: LeafletMouseEventHandlerFn;
  /** Fired when the user right-clicks on the layer, prevents default browser context menu from showing if there are listeners on this event. Also fired on mobile when the user holds a single touch for a second (also called long press). */
  onContextMenu?: LeafletMouseEventHandlerFn;
};

type MarkerClusterControl = L.MarkerClusterGroupOptions & {
  children: React.ReactNode;
} & ClusterEvents;

function createMarkerClusterGroup(
  props: MarkerClusterControl,
  context: LeafletContextInterface
) {
  //get cluster events and props
  const clusterProps: ClusterType = {},
    clusterEvents: ClusterType = {};
  // Splitting props and events to different objects
  Object.entries(props).forEach(([propName, prop]) =>
    propName.startsWith("on")
      ? (clusterEvents[propName] = prop)
      : (clusterProps[propName] = prop)
  );

  const instance = new L.MarkerClusterGroup(clusterProps);

  //initializing event listeners
  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`; //cut out the "on" part of the event
    instance.on(clusterEvent, callback);
  });
  return createElementObject(
    instance,
    extendContext(context, { layerContainer: instance })
  );
}

function updateMarkerCluster(
  instance: L.MarkerClusterGroup,
  props: MarkerClusterControl,
  prevProps: MarkerClusterControl
) {
  //TODO when prop change update instance
  //   if (props. !== prevProps.center || props.size !== prevProps.size) {
  //   instance.setBounds(getBounds(props))
  // }
}

/** A leaflet.markercluster wrapper for react
 * NOTE: this typescript seems wrong. Notably, the getAllChildMarkers function, outlined in the leaflet.markercluster types file, doesn't actually seem to exist.
 * Should look into this at some point.
 */
const MarkerClusterGroup = createPathComponent<
  L.MarkerClusterGroup,
  MarkerClusterControl
>(createMarkerClusterGroup, updateMarkerCluster);

export default MarkerClusterGroup;
