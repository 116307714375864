import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { forwardRef, useImperativeHandle, useState } from "react";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * A SnackbarAlert that doesn't need the values set in useStates in every single folder,
 * but instead accepts the values at the time its called
 */
const SnackbarAlert = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({
    context: "No Message Provided",
    severity: "success",
    duration: 3000,
    secondaryToastMargin: "5%",
  });

  //todo: make the third argument an "options" object that can accept multipl different values (i.e. forceClearSnackBar and secondaryToastNumber)
  /**
   * adds a new toast message to be displayed
   * @param {import("react").ReactNode|import("react").ReactNode[]} context the toast message, use arrays for breaklines
   * @param {String} severity The type of toast message to be displayed. Default is "success"
   * - "success"
   * - "warning"
   * - "info"
   * - "error"
   * @param {number} duration the time, in milliseconds, the message is displayed. Default is 3000
   * @param {Boolean} [forceClearSnackBar=false] if true, forceably closes any open snackbar alerts
   */
  const displayToast = (
    context,
    severity = "success",
    duration = 3000,
    forceClearSnackBar = false //todo: add in a {props} sort of argument for the 4th argument, that allows various different optional values to be passed in
  ) => {
    const secondaryToastMargin = "5%";
    // secondaryToastNumber
    // ? secondaryToastNumber * 10 + "%"
    // : "5%";
    if (forceClearSnackBar) setOpen(false);
    else {
      setAlertContent({ context, severity, duration, secondaryToastMargin });
      setOpen(true);
    }
  };

  useImperativeHandle(ref, () => ({
    displayToast: displayToast,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      sx={{
        marginTop: alertContent.secondaryToastMargin,
        zIndex: 1500,
        width: "25%",
      }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={alertContent.duration ?? 1000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={alertContent.severity}
        sx={{ fontSize: "1em" }}
      >
        {Array.isArray(alertContent.context)
          ? alertContent.context.map((x) => (
              <>
                {x}
                <br />
              </>
            ))
          : alertContent.context}
      </Alert>
    </Snackbar>
  );
});

export default SnackbarAlert;
