import DragHandleIcon from "@mui/icons-material/DragHandle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import charger_multi from "../../../static/images/charger_multi.gif";
import charger_no_sharing from "../../../static/images/charger_no_sharing.gif";
import charger_sharing from "../../../static/images/charger_sharing.gif";
import { parseFromValuesOrFunc, roundNumber } from "../../utils";

const chargingOperationGraphics = [
  charger_no_sharing,
  charger_multi,
  charger_sharing,
];

/**
 *
 * @param {Object} param0
 * @param {{swap_time: Number, num_chargers: {operational_profile: Array}, num_chargers:number}} param0.chargerSharingData
 */
export default function ChargerScenarios({ chargerSharingData }) {
  /**
   * constructs the data for the page
   * @type {[{swap_time: number, num_chargers: number, operational_profile: Array, on_road: number[], using_port: number[], plugged_in: number[]}[]]}
   */
  const [portData, setPortData] = useState([]);
  const [rowSelection, setRowSelection] = useState({ 0: true }); //determines which Charger sharing scenario user has selected
  const view = Object.keys(rowSelection)[0];
  const [isGraphicExpanded, setIsGraphicExpanded] = useState(false);

  useEffect(() => {
    function fetchData() {
      const portData = chargerSharingData.map(
        ({ operational_profile, ...rest }, i) => {
          const using_port = Array(1440).fill(0);
          const plugged_in = Array(1440).fill(0);
          const on_road = Array(1440).fill(0);
          let average_charger_wait = 0;

          operational_profile.forEach(({ profile }) => {
            profile.forEach(({ start_time, end_time, status }) => {
              let new_start_time = start_time,
                new_end_time = end_time;

              if (new_start_time < 2880) new_start_time = 2880; //2880 = 2 days
              if (new_end_time > 4320) new_end_time = 4320; //4320 = 3 days
              if (
                status == "waiting_for_charger" &&
                new_start_time < 4320 &&
                new_end_time > 2880
              )
                average_charger_wait += new_end_time - new_start_time;

              // portData
              const is_using_port =
                status == "charging" ||
                status == "precondition_hvac" ||
                status == "precondition_battery";
              const is_plugged_in =
                is_using_port ||
                status == "idle_post_charging" ||
                status == "idle_init" ||
                status == "block_charging";
              const is_on_road = status == "driving";

              new_start_time = Math.floor(new_start_time);
              new_end_time = Math.floor(new_end_time);

              //loop through profile, adding 1 while in specific ranges
              for (
                let i = new_start_time - 2880;
                i < new_end_time - 2880;
                i++
              ) {
                //port data creation
                if (is_using_port) using_port[i]++;
                if (is_plugged_in) plugged_in[i]++; //DO NOT USE ELSE IF HERE!!!
                if (is_on_road) on_road[i]++;
              }
            });
          });

          return {
            ...rest,
            id: i,
            operational_profile,
            on_road,
            using_port,
            plugged_in,
            average_charger_wait: roundNumber(
              average_charger_wait / operational_profile.length,
              0
            ),
          };
        }
      );

      setPortData(portData);
    }

    if (chargerSharingData) fetchData();
  }, [chargerSharingData]);

  /**
   * defines the column structure of the table
   */
  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */
    () => [
      {
        header: "Scenario",
        id: "scenario",
        accessorFn: (row) => {
          switch (row.id) {
            case 0:
              return "No Charger Sharing";
            case 1:
              return "Multiport Sequential Charging";
            case 2:
              return `${row.swap_time}-minute${
                row.swap_time != 1 ? "s" : ""
              } Vehicle Swap Time`;
            default:
              return "Not Implemented";
          }
        },
        muiTableHeadCellProps: {
          align: "left",
          sx: { verticalAlign: "middle" },
        },
        muiTableBodyCellProps: { align: "left" },
      },
      {
        header: (
          <>
            Required
            <br />
            Charger&nbsp;Ports
          </>
        ),
        accessorKey: "num_chargers",
      },
      {
        header: (
          <>
            Charger
            <br />
            Utilization
          </>
        ),
        id: "charger_utilization",
        accessorFn: (row) =>
          Math.ceil(
            (row.using_port.reduce((sum, curr) => sum + curr, 0) /
              1440 /
              row.num_chargers) *
              100
          ) + "%",
      },
      {
        header: (
          <>
            Average&nbsp;Vehicle
            <br />
            Queue&nbsp;Time
          </>
        ),
        id: "average_charger_wait",
        accessorFn: (row) =>
          `${row.average_charger_wait} minute${
            row.average_charger_wait == 1 ? "" : "s"
          }`,
      },
      {
        header: (
          <>
            Vehicles
            <br />
            per&nbsp;Charger
          </>
        ),
        id: "vehicles_per_charger",
        accessorFn: (row) =>
          roundNumber(row.operational_profile?.length / row.num_chargers, 1),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data: portData,
    columns,
    initialState: {
      ...materialReactTableOptions().initialState,
      density: "compact",
    },
    state: {
      ...materialReactTableOptions().state,
      isLoading: !chargerSharingData,
      rowSelection,
    },
    //miscellaneous options
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableHiding: false,
    getRowId: (row) => row.id,
    enableSorting: false, //disables column sorting
    enableColumnActions: false, //disables column options dropdowns

    //column styling
    muiTableHeadCellProps: { align: "center" },
    muiTableBodyCellProps: { align: "center" },

    //move column options
    enableColumnOrdering: false,
    icons: {
      DragHandleIcon: (props) => (
        <DragHandleIcon className="rotate-icon-1" {...props} />
      ),
    },

    //localization changes (custom text)
    localization: {
      select: "", //removes the header for the radio column
      toggleSelectRow: "View Charging Scenario",
    },

    //row selection options
    enableRowSelection: true,
    enableMultiRowSelection: false, //radio mode
    positionToolbarAlertBanner: "none", //hides the "n of m rows(s) selected" banner
    onRowSelectionChange: setRowSelection,
    muiTableBodyRowProps: (params) => ({
      ...parseFromValuesOrFunc(
        materialReactTableOptions().muiTableBodyRowProps,
        params
      ),
      onClick: params.row.getIsSelected()
        ? undefined
        : params.row.getToggleSelectedHandler(), // allows user to click anywhere on row to select (and prevents user from deselecting rows)
      sx: (theme) => ({
        ...parseFromValuesOrFunc(params?.sx, theme),
        cursor: "pointer",
      }),
    }),
  });

  return (
    <>
      <DialogContent>
        <Box display="flex" justifyContent="center" m="1%">
          <Typography variant="h6">Charger Sharing Insights</Typography>
        </Box>
        <Box display="flex" justifyContent="center" m="2%">
          <Typography variant="body1" sx={{ mx: "8%", textAlign: "center" }}>
            Charger sharing allows multiple vehicles to use a limited number of
            chargers in a depot by analyzing charging patterns, demand, and
            scheduling. This optimizes charger usage, reduces infrastructure
            costs, and ensures that vehicles are charged as needed, maximizing
            efficiency in operations.
          </Typography>
        </Box>
        <Grid container alignItems="center">
          <Grid item xs={13} sm={3}>
            <Container
              onClick={() => setIsGraphicExpanded(true)}
              sx={{ cursor: "pointer", position: "relative" }}
            >
              <img
                src={chargingOperationGraphics[view]}
                width={"100%"}
                style={{ borderRadius: "10px" }}
              />
              <Box position="absolute" top={1} right={27.5}>
                <FullscreenIcon />
              </Box>
            </Container>
          </Grid>
          <Grid item xs={13} sm={9}>
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
        {/* Expanded gif dialog */}
        <Dialog
          open={isGraphicExpanded}
          onClose={() => setIsGraphicExpanded(false)}
        >
          <img src={chargingOperationGraphics[view]} width={"100%"} />
        </Dialog>
      </DialogContent>
    </>
  );
}
