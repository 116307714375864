import Typography from "@mui/material/Typography";

export default function NotFound() {
  return (
    <div className="centered">
      <Typography variant="h3" gutterBottom component="div">
        Looks like that didn't work out
      </Typography>
    </div>
  );
}
