//note: consider putting access rights in here
// Assessment-type stepInfo (note: should replace default with a variable name)
export default [
  { label: "Create Analysis", route: "/fleet-input" }, //no "/assessment", since it's technically neither assessment, nor comparison
  { label: "Fleet Operation", route: "/assessment/route-definition" },
  { label: "Route Energy", route: "/assessment/route-energy" },
  { label: "Battery Sizing", route: "/assessment/battery-sizing" },
  { label: "Fleet and Charger Sizing", route: "/assessment/fleet-charging" },
  { label: "Energy Analysis", route: "/assessment/energy-analysis" },
  { label: "Financial and Emissions", route: "/assessment/financial-analysis" },
  { label: "Analysis Summary", route: "/assessment/summary" },
];

export const assessmentStepZero = {
  0: { label: "Create Analysis", route: "/fleet-input" }, //case 0 should not occur, but is included just in case
  1: { label: "GTFS Imports", route: "/assessment/gtfs-import" },
  2: { label: "Manual Input", route: "/assessment/fleet-manual" },
  3: { label: "School Bus Import", route: "/assessment/school-bus" },
};

// Comparison-type stepInfo
export const comparisonStepInfo = [
  { label: "Overview", route: "/compare/summary" },
  { label: "Load Profile", route: "/compare/energy-analysis" },
  { label: "Financial Analysis", route: "/compare/financial-analysis" },
];

export const tariffStyleKeyLookup = {
  1: "yearly",
  2: "monthly",
  3: "detailed",
};
