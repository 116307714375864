import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { inviteRegisterURL } from "../../static/constants/backendRoutes";
import titles from "../../static/constants/honorifics.json";
import NotFound from "../secondary/notFound";
import { errorHandler, getSubdomain, useQuery } from "../utils";
import UseAuth from "./useAuth";

export default function Register() {
  const invite_key = useQuery().get("invite_key");
  const first_name = useQuery().get("first_name");
  const last_name = useQuery().get("last_name");
  const company = useQuery().get("company");

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    if (UseAuth("get")) UseAuth("remove");
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const body = {
      invite_key: invite_key,
      role: data.get("job-title"),
      title: data.get("title"),
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      password1: data.get("password1"),
      password2: data.get("password2"),
      subdomain: getSubdomain(),
    };

    if (body.password1 !== body.password2) {
      snackBarElement.current.displayToast("Passwords do not match", "warning");
      setLoading(false);
      return;
    }

    fetch(inviteRegisterURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast("Sign Up Complete");
          window.location.assign("/login");
        } else {
          errorHandler(response, snackBarElement);
          setLoading(false);
        }
      })
      .catch((err) => {
        snackBarElement.current.displayToast(String(err), "error");
        console.log(err);
        setLoading(false);
      });
  };

  return invite_key ? (
    <Container
      elevation={3}
      sx={{ mt: "3em" }}
      fixed
      maxWidth="md"
      component={Paper}
    >
      <Box p={3} component="form" onSubmit={handleSubmit}>
        <Typography component="h1" variant="h5" mb={2}>
          Create a New Account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={2}>
            <TextField
              required
              fullWidth
              name="title"
              label="Title"
              id="title"
              defaultValue="Mr."
              autoComplete="honorific-prefix"
              select
              SelectProps={{
                //set an upper limit to the height of the dropdown
                MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
              }}
            >
              {titles.map((title) => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              required
              fullWidth
              name="first_name"
              label="First Name"
              id="first_name"
              defaultValue={first_name}
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={4} sm={6}>
            <TextField
              required
              fullWidth
              name="last_name"
              label="Last Name"
              id="last_name"
              defaultValue={last_name}
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="job-title"
              label="Job Title"
              id="job-title"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Company"
              disabled
              defaultValue={company}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="password1"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password1"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="password2"
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              id="password2"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}>
            <LoadingButton
              className="btn"
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
              loadingIndicator="Registering..."
            >
              Complete Sign-Up Process
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
  ) : (
    <NotFound />
  );
}
