import MaterialTable from "@material-table/core";
import Add from "@mui/icons-material/Add";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  changeUserRoleURL,
  customerURL,
  impersonateLoginURL,
  inviteUsersURL,
  partnerURL,
  resendInviteURL,
  userRolesURL,
  usersDeactivationURL,
  usersURL,
} from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { Icons, errorHandler, useQuery } from "../utils";
import UserDialog from "./userDialog";

/**
 * TODO: Consider making this a util, once the page is finished
 * NOTE: This is made as a separate React Component to preserve the sorting of the materialtable when opening/closing the dialog box
 * @param {Object} props
 * @param {Object} props.rowData the material table's rowData
 * @param {callbackFunction} props.handleResendInvite resends the user Invite email
 * @param {callbackFunction} props.setData used to update the table's data
 * @param {Object} props.userRoles used to populate the dropdown in the dialog box
 * @returns {ReactElement} dropdown menu, for the material table
 */
function RowActionsDropdown(props) {
  const { rowData, handleResendInvite, setData, userRoles } = props;
  const { snackBarElement } = useContext(SnackBarContext);
  const { accessRights } = useContext(DataContext);
  const [anchorEl, setAnchorEl] = useState(null); //anchor element, for positioning the dropdown
  const [isChangeRoleOpen, setIsChangeRoleOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); //used to prevent users from submitting the same form twice
  const isOpen = Boolean(anchorEl);
  function actionsOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function actionsClose() {
    setAnchorEl(null);
  }
  function changeRoleOpen() {
    setIsChangeRoleOpen(true);
  }
  function changeRoleClose() {
    setIsLoading(false);
    setIsChangeRoleOpen(false);
    actionsClose();
  }

  function deleteUserClose() {
    setIsDeleteOpen(false);
    setIsLoading(false);
    actionsClose();
  }

  const actionsDropdown = {
    "View Profile": `/profile?user_id=${rowData.id}`,
    // settings: "Change Role",
    // settings2: "Disable",
    // settings3: "Delete",
  };

  /**
   * changes the user role of the selected user
   * @param {Object} newUser the rowdata of the user to be updated
   * @param {Object} oldUser the original user data
   * @returns {number} -1 for failure, 0 for success
   */
  function handleChangeUserRole(event) {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const [user_role_id] = Array.from(data.values());

    if (rowData.user_role_id == user_role_id) {
      //if the user role wasn't altered from the original, close the dialog box and cancel the function
      changeRoleClose();
      return;
    }

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({ user_id: rowData.id, user_role_id });

    fetch(changeUserRoleURL, { method: "PATCH", headers, body })
      .then((res) => {
        if (res.ok) {
          changeRoleClose();
          setData((data) => {
            const i = data.findIndex((row) => row.id == rowData.id);
            data[i].user_role_id = user_role_id;
            data[i].user_role = userRoles[user_role_id];
            return [...data];
          });
          return;
        }
        //else
        errorHandler(
          res,
          snackBarElement,
          "Something went wrong updating user role"
        );
        setIsLoading(false);
        return;
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error occurred while updating user role, try again later",
          "error",
          5000
        );
        setIsLoading(false);
        return;
      });
  }

  /**
   * signs the user in as the impersonated account, and redirects user to /projects page (will cause re-render on purpose to get new access rights)
   * @param {MouseEvent} event
   * @param {Number} user_id the id of the user to be impersonated
   */
  function handleImpersonation(event, user_id) {
    event.preventDefault();
    setIsLoading(true);
    actionsClose();
    fetch(impersonateLoginURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("true_get")}`,
      },
      body: JSON.stringify({ user_id }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            if (data?.token) {
              UseAuth("alt_set", { key: data.token, id: user_id });
            }
            window.location.assign("/project");
          });
        }
        //else
        errorHandler(response, snackBarElement);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error has occurred",
          "error"
        );
        setIsLoading(false);
      });
  }

  /**
   *
   * @param {MouseEvent} event
   * @param {Number} user_id the id of the user to deactivate
   */
  function handleDeactivate(event, user_id) {
    event.preventDefault();
    actionsClose();
    fetch(usersDeactivationURL, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify({ user_id }),
    })
      .then((response) => {
        if (response.ok) {
          setData((data) => {
            const i = data.findIndex((row) => row.id == rowData.id);
            data[i].is_active = false;
            return [...data];
          });
          return;
        }
        //else
        errorHandler(response, snackBarElement);
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error has occurred",
          "error"
        );
      });
  }

  function handleDeleteUser(event) {
    event.preventDefault();
    const body = { user_id_list: [rowData.id] };
    setIsLoading(true);
    fetch(usersURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          setData((data) => {
            const index = data.findIndex((user) => user.id == rowData.id);
            data[index].is_deleted = true;
            return [...data];
          });
          //give it a second to finish filtering before closing dialog
          deleteUserClose();
        } else {
          errorHandler(response, snackBarElement, "Error Deleting User");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error has occurred",
          "error"
        );
        setIsLoading(false);
      });
  }

  return (
    <>
      <Tooltip title="Open Actions">
        <Button
          variant="outlined"
          className="btn"
          onClick={actionsOpen}
          endIcon={
            isOpen ? (
              <ExpandLess sx={{ pointerEvents: "none" }} />
            ) : (
              <ExpandMore sx={{ pointerEvents: "none" }} />
            )
          }
        >
          Options
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={actionsClose} //causes clicking elsewhere to close box
      >
        {Object.entries(actionsDropdown).map(([label, route]) => (
          <MenuItem key={`${label}_link`} component={Link} to={route}>
            {label}
          </MenuItem>
        ))}
        <MenuItem
          key="Change_UserRole"
          onClick={changeRoleOpen}
          disabled={
            !Object.keys(userRoles).length ||
            !accessRights.admin.change_user_role ||
            rowData.is_deleted
          }
        >
          Change Role
        </MenuItem>
        <MenuItem
          key="impersonate"
          onClick={(e) => handleImpersonation(e, rowData.id)}
          disabled={!accessRights.admin.impersonation || rowData.is_deleted}
        >
          Impersonate
        </MenuItem>
        <MenuItem
          key="deactivate"
          onClick={(e) => handleDeactivate(e, rowData.id)}
          disabled={
            !rowData.is_active ||
            !accessRights.admin.deactivate_user ||
            rowData.is_deleted
          }
        >
          Deactivate
        </MenuItem>
        {/* resend invite should always be last item on list */}
        <MenuItem
          key="Resend_Invite"
          onClick={() => handleResendInvite(rowData.id)}
          disabled={
            rowData.is_active ||
            !accessRights.admin.resend_invite_user ||
            rowData.is_deleted
          }
        >
          Resend Invite
        </MenuItem>
        <MenuItem
          key="Delete_User"
          onClick={() => setIsDeleteOpen(true)}
          disabled={!accessRights.admin.delete_user || rowData.is_deleted}
        >
          Delete User
        </MenuItem>
      </Menu>
      <Dialog
        open={isChangeRoleOpen}
        component="form"
        onSubmit={handleChangeUserRole}
        onClose={changeRoleClose}
        aria-labelledby="alert-dialog-title" //accessibility stuff
        transitionDuration={{ exit: 0 }}
      >
        <DialogTitle id="alert-dialog-title">
          Change {rowData.first_name} {rowData.last_name}'s Role?
        </DialogTitle>
        {isChangeRoleOpen ? ( //this if-else statement is really only here to suppress a "defaultChecked has been altered" warning on submit
          <DialogContent>
            <RadioGroup
              name={`radiogroup_${rowData.id}`}
              defaultValue={rowData.user_role_id}
            >
              {Object.entries(userRoles).map(([role_id, role_name]) => (
                <FormControlLabel
                  key={`radiobox_${role_name}`}
                  control={<Radio value={role_id} required />}
                  label={role_name}
                />
              ))}
            </RadioGroup>
          </DialogContent>
        ) : (
          "Loading..."
        )}
        <DialogActions>
          <Button onClick={changeRoleClose}>Cancel</Button>
          <LoadingButton loading={isLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteOpen}
        component="form"
        onSubmit={handleDeleteUser}
        onClose={deleteUserClose}
      >
        <DialogTitle>
          Delete {rowData.first_name} {rowData.last_name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {rowData.first_name}'s account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteUserClose}>Cancel</Button>
          <LoadingButton loading={isLoading} type="submit">
            Delete User
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* todo: this backdrop component is on a bunch of pages, so make it its own util or component (will need to pass in stepNumber and buttonLoading) */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">Loading...</Typography>
          </Container>
        </Container>
      </Backdrop>
    </>
  );
}

export default function UserView() {
  const queryParams = useQuery();
  const OrganizationId = queryParams.get("orgId");

  const [data, setData] = useState([]);
  const [userRoles, setUserRoles] = useState({});
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false); //opens/closes the "Add new Users" dialog box
  const [isDialogSubmitting, setIsDialogSubmitting] = useState(false); //disables the inputs and sets button to "loading" while backend request is being made
  const [partnerNames, setPartnerNames] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [rerender, setRerender] = useState(0);
  const [dataFetchError, setDataFetchError] = useState(false); //displays error message in table if data failed to fetch/loading message if response not yet recieved
  const [showDeleted, toggleShowDeleted] = useState(false); // a toggle to show all users vs show only non-deleted users
  //data displayed by material table
  const tableData = showDeleted
    ? data
    : data.filter((user) => !user.is_deleted);

  const bossNames = partnerNames.concat(customerNames);

  const { accessRights } = useContext(DataContext);

  const { snackBarElement } = useContext(SnackBarContext);

  const headers = {
    Authorization: `Token ${UseAuth("get")}`,
    "Content-Type": "application/json",
  };

  //useEffect that fires on initial render/access rights
  useEffect(() => {
    //fetch the name of the viewer (on individual customer/partner view)
    async function fetchData() {
      try {
        if (accessRights.admin.read_partner) {
          //partner name fetch for invite users (when site admin) and for header name in partner view
          const { data } = await axios.get(partnerURL, { headers: headers });
          setPartnerNames(data.data);
        }

        if (accessRights.admin.read_customer) {
          //customer name fetch for invite users (when site admin/partner) and for header name in customer view
          const { data } = await axios.get(customerURL, { headers: headers });
          setCustomerNames(data.data);
        }
      } catch (e) {
        errorHandler(e, snackBarElement, "partner/customer name fetch failure");
      }
    }

    fetchData();
  }, [accessRights, OrganizationId]);

  //useEffect that fires on url change/manual refresh
  useEffect(() => {
    //get all users (to populate main table)
    fetch(`${usersURL}?organization_id_list=${OrganizationId || -1}`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then(({ data }) => {
            setData(data);
            setDataFetchError(true);
          });
        }
        //else
        errorHandler(
          res,
          snackBarElement,
          "Something went wrong getting Users data"
        );
        setDataFetchError(true);
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error occurred while getting Users data",
          "error",
          7000
        );
        setDataFetchError(true);
      });
  }, [OrganizationId, rerender]);

  useEffect(() => {
    //get user roles associated with organization (to populate user_roles dropdown in "invite users" dialog box)
    fetch(
      userRolesURL +
        (OrganizationId > 0 ? `?organization_id=${OrganizationId}` : ""),
      { method: "GET", headers: headers }
    )
      .then((res) => {
        if (res.ok) {
          return res.json().then((output) => {
            setUserRoles(
              output.data.reduce(
                (acc, current) => ({ ...acc, [current.id]: current.user_role }),
                {}
              )
            );
          });
        } else {
          errorHandler(
            res,
            snackBarElement,
            "Something went wrong getting user roles data"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error occurred while getting user Roles data",
          "error",
          7000
        );
      });
  }, [OrganizationId]);

  /**
   * gets the data from the submitted form, and sends it to onRowUpdate function using default material table functions
   * @param {Event} event the mouse click/form submission event
   * @param {[Object]} users the array of users entered in the userDialog box
   * @param {Number} parent.partner_id either an empty string, or a number
   * @param {Number} parent.customer_id either an empty string, or a number representing the customer ID
   */
  function formSubmit(event, users, parent) {
    event.preventDefault();

    setIsDialogSubmitting(true); //disables dialog box text inputs
    let body = {
      organization_id:
        parent.partner_id || parent.customer_id || OrganizationId || "",
      users: users,
    };

    fetch(inviteUsersURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((output) => {
            snackBarElement.current.displayToast(
              "Invites have been sent to the users' email"
            );
            const usersWithActivity = users.map((user) => ({
              ...user,
              is_active: false,
            }));
            setData(data.concat(usersWithActivity));
            setAddUserDialogOpen(false);
            setIsDialogSubmitting(false);
            setRerender(rerender + 1);
          });
        } else {
          errorHandler(
            res,
            snackBarElement,
            "Something went wrong inviting Users"
          );
          setIsDialogSubmitting(false);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error occurred while inviting Users",
          "error",
          7000
        );
        setIsDialogSubmitting(false);
      });
  }

  /**
   * Resends an invite to a user
   * @param {Number} user_id the id of the user to resend the invite to
   */
  function handleResendInvite(user_id) {
    const body = { user_id: user_id };

    fetch(resendInviteURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          snackBarElement.current.displayToast("Invitation has Been Sent");
        } else {
          errorHandler(
            res,
            snackBarElement,
            "Something went wrong inviting Users"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network error occurred while inviting Users",
          "error",
          7000
        );
      });
  }

  // Cancels the row edit and closes the dialog box
  function formClose() {
    //default material table edit cancel function
    // props.onEditingCanceled(props.mode, props.data);
    setAddUserDialogOpen(false);
  }

  /** @type {import("@material-table/core").Column<never>[]} */
  const columns = [
    {
      title: "Name",
      render: (rowData) => `${rowData.first_name} ${rowData.last_name}`,
      customFilterAndSearch: (term, rowData) =>
        `${rowData.first_name} ${rowData.last_name}`
          .toString()
          .toUpperCase()
          .includes(term.toUpperCase()),
      customSort: (a, b) => {
        //source: ~line 550 https://github.com/mbrn/material-table/blob/18f7cce93907ce75ead050eccfc86753f7acf00a/src/utils/data-manager.js
        if (a !== b) {
          //to find nulls
          if (!a) return -1;
          if (!b) return 1;
        }
        return `${a.first_name} ${a.last_name}` <
          `${b.first_name} ${b.last_name}`
          ? -1
          : `${a.first_name} ${a.last_name}` > `${b.first_name} ${b.last_name}`
          ? 1
          : 0;
      },
    },
    // { title: "User Id", field: "id" },
    {
      title: "Status",
      render: (rowData) =>
        rowData?.is_deleted
          ? "Deleted"
          : rowData?.is_active
          ? "Active"
          : "Inactive",
      customFilterAndSearch: (term, rowData) =>
        (rowData?.is_deleted
          ? "Deleted"
          : rowData?.is_active
          ? "Active"
          : "Inactive"
        )
          .toUpperCase()
          .includes(term.toUpperCase()),
      customSort: (a, b) => {
        //source: ~line 550 https://github.com/mbrn/material-table/blob/18f7cce93907ce75ead050eccfc86753f7acf00a/src/utils/data-manager.js
        if (a !== b) {
          //to find nulls
          if (!a) return -1;
          if (!b) return 1;
        }
        a = a?.is_deleted ? 1 : a?.is_active ? 2 : 3;
        b = b?.is_deleted ? 1 : b?.is_active ? 2 : 3;
        return a < b ? -1 : a > b ? 1 : 0;
      },
    },
    { title: "Account Type", field: "organization_type" },
    {
      title: "Role",
      field: "user_role_id",
      lookup: userRoles,
      emptyValue: (rowData) => rowData?.user_role ?? "Loading...",
    },
    {
      sorting: false,
      render: (rowData) => (
        <RowActionsDropdown
          rowData={rowData}
          handleResendInvite={handleResendInvite}
          setData={setData}
          userRoles={userRoles}
        />
      ),
    },
  ];

  return (
    <>
      <br />
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <MaterialTable
            title={
              OrganizationId > 0 ? (
                <Typography variant="h6">
                  <span className="header-green">
                    {bossNames.find((boss) => boss.id == OrganizationId)?.name}{" "}
                  </span>
                  Users
                </Typography>
              ) : (
                "Users"
              )
            }
            data={tableData}
            columns={columns}
            icons={Icons("User")}
            localization={{
              body: {
                emptyDataSourceMessage:
                  //data.length was included to account for the scenario where a user filters to the point that no data was visible
                  dataFetchError || data.length ? (
                    // if an error occurs in user fetch OR if the data array has been populated, but the user filtered out all data, display this message in the table
                    "No records to display"
                  ) : (
                    // until the point that an error occurs or the data is retrieved, display a loading message in table
                    <>
                      <CircularProgress />
                      <br />
                      Loading...
                    </>
                  ),
              },
              toolbar: { searchPlaceholder: "Filter", searchTooltip: "Filter" },
            }}
            actions={[
              {
                icon: () => (
                  <Button variant="outlined" className="btn" component="label">
                    <Add />
                    &nbsp;New User
                  </Button>
                ),
                tooltip: "Add Users",
                isFreeAction: true,
                onClick: () => setAddUserDialogOpen(true),
                hidden: !accessRights.admin.create_user,
              },
              {
                icon: () => (
                  <Button
                    variant={showDeleted ? "contained" : "outlined"}
                    className="btn"
                    component="label"
                  >
                    {showDeleted ? "Hide" : "Show"} Deleted
                  </Button>
                ),
                tooltip: `${showDeleted ? "Hide " : "Show"} Deleted Users`,
                isFreeAction: true,
                onClick: () => toggleShowDeleted(!showDeleted),
                hidden: !accessRights.admin.create_partner,
              },
            ]}
          />
        </Paper>
      </Container>

      <Dialog
        open={addUserDialogOpen}
        onClose={() => formClose()}
        fullWidth
        maxWidth="lg"
      >
        <UserDialog
          userRoles={userRoles}
          partnerNames={partnerNames}
          customerNames={customerNames}
          formClose={formClose}
          formSubmit={formSubmit}
          disableButtons={isDialogSubmitting}
        />
      </Dialog>
    </>
  );
}
