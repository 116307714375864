import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Info from "@mui/icons-material/Info";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import { DataContext } from "../../../contexts/dataContext";
import { localDb } from "../../../contexts/localDb";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import {
  annualLoadURL,
  chargerSharingReportURL,
  fiveDayScheduleURL,
  simulationURL,
} from "../../../static/constants/backendRoutes";
import stepInfo from "../../../static/constants/stepInfo";
import UseAuth from "../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import Subheader from "../../secondary/subheader";
import {
  MFM_to_AMPM,
  errorHandler,
  getLocalData,
  initXLSX,
  partialClearLocalData,
  storeLocalData,
} from "../../utils";
import { NextPageButton } from "../commonComponents.js";
import ChargerScenarios from "../dialogs/chargerScenarios.js";
import { AnnualLoadProfileInputs } from "../dialogs/editAnalysisInputs";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import DrivingActivityTimeTable, {
  colorGradient,
} from "../graphs/drivingActivityTimeTable.js";
import EnergyAnalysisChart from "../graphs/energyAnalysisChart";
import VehicleActivityTimeTable from "../graphs/vehicleActivityTimeTable.js";
import DepotSimulation, {
  DepotSimulationSubheaderCalculation,
} from "../simulationSummaryViews/depotSimulation.js";

const STEP_NUMBER = 5;

/** calculates battery charge while driving
 * @param {Object} profile (from operational profile)
 * @param {Number} currentTime the current time of day
 * @returns {{...profile, color: string, SoC: Number, timeToEnd: Number}} [Color, State of Charge, timeToEnd]
 */
export const profileHelper = (profile, currentTime) => {
  let SoC = profile.start_soc;
  const [start_time, end_time] = [
    profile.start_time - 2880,
    profile.end_time - 2880,
  ];
  //SoC is constantly changing if the vehicle is driving/charging
  if (profile.status == "driving" || profile.status == "charging")
    SoC =
      SoC -
      ((SoC - profile.end_soc) * (currentTime - start_time)) /
        (end_time - start_time);

  return {
    ...profile,
    soc_color:
      colorGradient[Math.round(((colorGradient.length - 1) * SoC) / 100)],
    soc: SoC,
  };
};

/**
 * the fleet Sizing page
 * @returns {JSX} fleet Sizing component
 */
export default function EnergyAnalysis() {
  /** @type {load_profile: {full_power: [], low_power: []}, operational_profile: {profile:{}[], vehicle_id:String}[]} */
  const [data, setData] = useState();
  const [portData, setPortData] = useState({
    using_port: [],
    plugged_in: [],
    on_road: [],
  });
  const [depotSimData, setDepotSimData] = useState(
    Array(1440).fill({
      charging_zone: [],
      waiting_zone: [],
      departing_soon: [],
      arriving_soon: [],
      "On the Road": [],
      postprocess_zone: [],
      preprocess_zone: [],
    })
  );
  const [timeOfDay, setTimeOfDay] = useState(720); //used in depot Simulation
  const [chartView, setChartView] = useState(0); //0="load profile" 1="all" 2= "Driving" 3="Charging" 4=charging&driving 5=port analysis
  //used to simplify the subheader lookup for the vehicleActivity/DrivingTimeTable
  const simpleViewValue =
    chartView >= 5 ? chartView - 3 : chartView <= 1 ? chartView : 1;
  const chartNameLookup = {
    0: "Load Profile",
    1: "Vehicle Activity",
    // 5: "Usage Analysis",
    6: "Depot Simulation (Beta)",
  };
  const [chartViewAnchorEl, setChartViewAnchorEl] = useState(null);
  const [subheaderContent, setSubheaderContent] = useState({
    0: [], // load profile
    1: [], // vehicle activity/driving stuff
    3: [], // Depot Simulation
  });

  const [buttonLoading, setButtonLoading] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const isDownloadDropdownOpen = Boolean(downloadAnchorEl);
  const [annualDownloadOpen, setAnnualDownloadOpen] = useState(false);
  //inputs are a state instead of a standard form, due to how much they affect other fields in dialog box
  const [annualDialogInputs, setAnnualDialogInputs] = useState(undefined);
  const [sim, setSim] = useState(undefined); //the indexDb sim data
  const [batteryData, setBatteryData] = useState();
  const [isChargerSharingDialogOpen, setIsChargerSharingDialogOpen] =
    useState(false);
  const [chargerSharingData, setChargerSharingData] = useState();

  const { snackBarElement } = useContext(SnackBarContext); //for the toast notifications when an error occurs
  const navigate = useNavigate();
  const { accessRights } = useContext(DataContext);

  /**
   * gets the profile (chart) data from indexDB
   */
  useEffect(() => {
    async function fetchData() {
      const sim = await getLocalData("simulation", "data").then(
        ({ data }) => data
      );
      setSim(sim);

      //normal chart data fetch and set
      const data = await getLocalData("evAssessment", "data").then(
        ({ data }) => data
      );
      setData(data);

      const rawBatteryData = await getLocalData("battery", "input");
      const batteryData = rawBatteryData?.input;
      setBatteryData(batteryData);

      const load_profile = data?.load_profile;

      //subheader data computation & fetch + set (not in separate useEffect, since the loadProfile is static on this page)
      if (load_profile?.full_power?.length && load_profile?.low_power?.length) {
        let [maxUnmanaged, maxManaged] = [
          load_profile.full_power[0],
          load_profile.low_power[0],
        ];

        const low_power = load_profile.low_power;

        load_profile.full_power.forEach((full_power, index) => {
          maxUnmanaged = Math.max(full_power, maxUnmanaged);
          maxManaged = Math.max(low_power[index], maxManaged);
        });

        //note: since we only need the input, it's faster to do just a .get("input") than to do a getLocalData (due to the large size of block's data)
        //todo: combine the below promises into a single promise
        const { input: initialDownloadParameters } = await getLocalData(
          "blocks",
          "input"
        );
        setAnnualDialogInputs(initialDownloadParameters);

        const sumEnergy =
          load_profile.full_power.reduce((prev, curr) => prev + curr, 0) / 60;

        //todo: the port data stuff here has been moved to the chargerSharingGraph file. Remove it when depot simulation is removed
        //calculate subheader, port graph, and depot simulation data
        const depotSimulationData = Array(1440)
          .fill()
          .map(() => ({
            charging_zone: [],
            waiting_zone: [],
            departing_soon: [],
            arriving_soon: [],
            "On the Road": [],
            postprocess_zone: [],
            preprocess_zone: [],
            other: [],
          }));
        const using_port = Array(1440).fill(0);
        const plugged_in = Array(1440).fill(0);
        const on_road = Array(1440).fill(0);
        let sumTimeDriving = 0;

        let does_process = false;
        let init_time = 4320;
        data.operational_profile.forEach(({ profile, vehicle_id }) => {
          profile.forEach(({ start_time, end_time, status, ...rest }) => {
            let new_start_time = start_time,
              new_end_time = end_time;
            if (new_start_time < 2880) new_start_time = 2880; //2880 = 2 days
            if (new_end_time > 4320) new_end_time = 4320; //4320 = 3 days

            // portData
            const is_using_port =
              status == "charging" ||
              status == "precondition_hvac" ||
              status == "precondition_battery";
            const is_plugged_in =
              is_using_port ||
              status == "idle_post_charging" ||
              status == "idle_init" ||
              status == "block_charging";
            const is_on_road = status == "driving";

            //detailed data
            const is_charging =
              is_using_port ||
              status == "idle_post_charging" ||
              status == "block_charging" ||
              status == "v2g" ||
              status == "idle_post_v2g";
            const is_departing = status == "pullout" || status == "plugout";
            const is_arriving = status == "pullin" || status == "plugin";
            let key;
            if (is_charging || is_arriving || is_departing)
              key = "charging_zone";
            // else if (status == "idle_init") key = "waiting_zone";//NOTE: UNUSED FIELD
            // else if (is_departing) key = "departing_soon";// moved into a 5 minute buffer zone around driving
            // else if (is_arriving) key = "arriving_soon";
            else if (is_on_road) key = "On the Road";
            else if (status == "postprocess") {
              key = "postprocess_zone";
              does_process = true;
            } else if (status == "preprocess") {
              key = "preprocess_zone";
              does_process = true;
            } else key = "other";

            new_start_time = Math.floor(new_start_time);
            new_end_time = Math.floor(new_end_time);

            //loop through profile, adding 1 while in specific ranges
            for (let i = new_start_time - 2880; i < new_end_time - 2880; i++) {
              //port data creation
              if (is_using_port) using_port[i]++;
              if (is_plugged_in) plugged_in[i]++; //DO NOT USE ELSE IF HERE!!!
              if (is_on_road) on_road[i]++;
              // depotSimData creation
              depotSimulationData[i][key].push(
                profileHelper(
                  {
                    ...rest,
                    status,
                    vehicle_id,
                    start_time: Math.floor(start_time),
                    end_time: Math.floor(end_time),
                  },
                  i
                )
              );
            }
            if (is_on_road) {
              if (new_start_time < 4320 && new_end_time > 2880)
                sumTimeDriving += new_end_time - new_start_time; //if driving, add time on road to sum value
              if (new_start_time > 2880)
                init_time = Math.min(new_start_time, init_time); // set init_time as the earliest driving start time
              for (let i = 1; i <= 5; i++) {
                const newer_start_time = new_start_time - 2880 - i,
                  newer_end_time = new_end_time - 2880 - i;
                // have a 5 minute "buffer" around the driving zones for processing (WILL OVERLAP WITH OTHER ZONES)
                if (newer_start_time >= 0 && newer_start_time < 1440)
                  depotSimulationData[newer_start_time]["departing_soon"].push(
                    profileHelper(
                      {
                        ...rest,
                        status: "pullout",
                        vehicle_id,
                        end_soc: rest.start_soc,
                        start_time: Math.max(new_start_time - 5, 2880),
                        end_time: new_start_time,
                      },
                      newer_start_time
                    )
                  );
                if (newer_end_time < 1440 && newer_end_time >= 0)
                  depotSimulationData[newer_end_time]["arriving_soon"].push(
                    profileHelper(
                      {
                        ...rest,
                        start_soc: rest.end_soc,
                        status: "pullin",
                        start_time: Math.max(new_end_time - 5, 2880),
                        end_time: new_end_time,
                        vehicle_id,
                      },
                      newer_end_time
                    )
                  );
              }
            }
          });
        });

        const averageOnRoadTime =
          (sumTimeDriving / ((data.operational_profile.length || 1) * 1440)) *
          100;
        const chargerUtilizationPercentage = Math.ceil(
          (using_port.reduce((sum, curr) => sum + curr, 0) /
            (Math.max(...plugged_in, 1) * 1440)) *
            100
        );

        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
          Accept: `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.annual_load_profile}`,
        };

        const chargerSharingData = await fetch(chargerSharingReportURL, {
          method: "POST",
          headers,
          body: JSON.stringify({
            operational_profile: data.operational_profile,
            block_charging: batteryData?.settings?.bev_settings?.block_charging,
          }),
        })
          .then((response) => {
            if (response.ok) return response.json().then(({ data }) => data);
            else if (response.status == 500) return [];
            else
              errorHandler(
                response,
                snackBarElement,
                "Failed to load charger sharing data"
              );
            return [];
          })
          .catch((e) => {
            console.log(e);
            snackBarElement?.current?.displayToast(
              "Network Error: Failed to load charger sharing data",
              "error",
              5000
            );
            return [];
          });

        const timeOfDay = Math.max(init_time - 2880 - 20, 0);
        setTimeOfDay(timeOfDay); //set initial time of day to 20 minutes before earliest driving time
        setPortData({
          using_port,
          plugged_in,
          on_road,
          does_process,
        });
        setDepotSimData(depotSimulationData);
        setChargerSharingData([
          {
            swap_time: 0,
            num_chargers: data?.num_chargers,
            operational_profile: data?.operational_profile,
          },
          ...chargerSharingData,
        ]);
        setSubheaderContent({
          0: [
            {
              value: Math.round(maxUnmanaged).toLocaleString(),
              label: "Unmanaged Charging Peak Power (kW)",
            },
            {
              value: Math.round(maxManaged).toLocaleString(),
              label: "Managed Charging Peak Power (kW)",
            },
            {
              value: Math.round(sumEnergy).toLocaleString(),
              label: "Total Daily Energy (kWh)",
            },
          ],
          1: [
            {
              value: Math.round(
                data.operational_profile?.length
              ).toLocaleString(),
              label: "Number of Vehicles",
            },
            {
              value: `${Math.round(averageOnRoadTime).toLocaleString()} %`,
              label: "Time on Road",
            },
            {
              value: Math.round(data.num_chargers).toLocaleString(),
              label: "Number of Charging Ports",
            },
            {
              value: `${Math.round(
                chargerUtilizationPercentage
              ).toLocaleString()} %`,
              label: "Time Charging",
            },
          ],
          // 2: UsageAnalysisSubheaderCalculation({ on_road }, chargerSharingData),
          3: DepotSimulationSubheaderCalculation(
            depotSimulationData[timeOfDay],
            does_process
          ),
        });
      }
    }

    fetchData();
  }, []);

  /**
   * the bare minimum of a "next page" button function.
   * changes the backend's "current_page" value, and navigates to
   * financial analysis
   * TODO: Move financial analysis' initialization request into this function, and store result on backend and indexdb
   */
  const handleSubmit = () => {
    setButtonLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const backendBody = {
      id: sim.id,
      completed: false,
      current_page: stepInfo[STEP_NUMBER + 1].route,
      steps: { tco: {} },
      // note: block input's annualDialogInputs is not included here, as it is already handled in it's own update function
    };

    fetch(simulationURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(backendBody),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(
            `${stepInfo[STEP_NUMBER].label} Analysis Complete`
          );
          navigate(stepInfo[STEP_NUMBER + 1].route);
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to update simulation"
          );
          setButtonLoading(false);
        }
      })
      .catch((e) => {
        snackBarElement.current.displayToast(
          "Failed to perform Financial Analysis",
          "error",
          7000
        );
        console.log(e);
        setButtonLoading(false);
      });
  };

  /** opens the chart selection dropdown */
  const handleOpenChartMenu = (e) => setChartViewAnchorEl(e.currentTarget);
  /** closes the chart selection dropdown */
  const handleCloseChartMenu = (e) => setChartViewAnchorEl(null);
  /** selects a chart and closes the the dropdown */
  const handleChartSelect = (e) => {
    setChartView(e.currentTarget.value);
    setChartViewAnchorEl(null);
  };

  /** opens the CSV download selection dropdown */
  const handleOpenDownloadCSV = (e) => setDownloadAnchorEl(e.currentTarget);
  /** closes the CSV download selection dropdown */
  const handleCloseDownloadCSV = (e) => setDownloadAnchorEl(null);
  /** opens the annual download load profile paramters dialog box */
  const handleOpenAnnualDownload = (e) => {
    setAnnualDownloadOpen(true);
    handleCloseDownloadCSV();
  };
  /** closes the annual download load profile paramters dialog box */
  const handleCloseAnnualDownload = (e) => setAnnualDownloadOpen(false);

  /**
   * Handles annual load profile updates made by user
   * and updates the EvAssessment's annual15 list with alterations
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  const handleSaveAnnualLoadInputs = async (event) => {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    if (newDataJSON?.start_date > newDataJSON?.end_date) {
      snackBarElement?.current?.displayToast(
        "Start Date must come before End Date",
        "warning",
        5000
      );
      return;
    }
    if (
      annualDialogInputs.start_date == newDataJSON.start_date &&
      annualDialogInputs.end_date == newDataJSON.end_date &&
      annualDialogInputs.resolution == newDataJSON.resolution &&
      annualDialogInputs.weekend.operate ==
        (newDataJSON.weekendOperation === "on" ? 1 : 0) &&
      annualDialogInputs.weekend.sat == newDataJSON?.sat &&
      annualDialogInputs.weekend.sun == newDataJSON?.sun
    ) {
      snackBarElement?.current?.displayToast(
        "Inputs not altered from last save",
        "info",
        5000
      );
      return;
    }
    setButtonLoading(true);

    const updatedAnnualDialogInputs = {
      ...annualDialogInputs, //include the original annualDialogInputs, to maintain the hvac (and any other keys we add to blocks down the line)
      weekend: {
        operate: newDataJSON.weekendOperation === "on" ? 1 : 0,
        sat: newDataJSON?.sat ? parseInt(newDataJSON.sat) : 0,
        sun: newDataJSON?.sun ? parseInt(newDataJSON.sun) : 0,
      },
      start_date: newDataJSON.start_date,
      end_date: newDataJSON.end_date,
      resolution: parseInt(newDataJSON.resolution),
    };

    //and update the blocks for it
    storeLocalData("blocks", { input: updatedAnnualDialogInputs });

    //then generate a new annual 15
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.annual_load_profile}`,
    };

    //format start and end dates for backend
    const start_date = annualDialogInputs.start_date.split("-");
    const end_date = annualDialogInputs.end_date.split("-");

    const { data: block_schedule } = await getLocalData("routeEnergy", "data");

    const body = {
      block_schedule,
      load_profile: { full_power: data?.load_profile?.full_power || [] },
      ...annualDialogInputs,
      start_date: `${start_date[1]}/${start_date[2]}/${start_date[0]}`,
      end_date: `${end_date[1]}/${end_date[2]}/${end_date[0]}`,
      resolution: 15,
    };

    fetch(annualLoadURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    }).then((res) => {
      delete headers.Accept;
      if (!res.ok) {
        errorHandler(
          response,
          snackBarElement,
          "Error saving changes to server."
        );
        return;
      }
      //else
      res.json().then(async ({ data: { annual_load, operation_days } }) => {
        const evAssessment = await localDb.transaction(
          "rw",
          localDb.evAssessment,
          localDb.tco,
          async () => {
            //clear out the future pages' frontend data
            partialClearLocalData(["tco"]);

            //update the evAssessment with the loadProfile
            // note: this can be further simplified down the line, once I've broken the indexDb tables into more detailed rows
            await localDb.evAssessment.update("data", {
              "value.load_profile.annual15": annual_load,
            });
            await localDb.evAssessment.update("data", {
              "value.operation_days": operation_days,
            });
            const { data: evAssessment } = await getLocalData(
              "evAssessment",
              "data"
            );
            return evAssessment;
          }
        );

        const backendBody = {
          id: sim.id,
          completed: false,
          current_page: stepInfo[STEP_NUMBER].route,
          steps: {
            input: { blocks: updatedAnnualDialogInputs },
            evAssessment: evAssessment, //update evAssessment with annualLoad15
            tco: {},
          },
          // note: block input's annualDialogInputs is not included here, as it is already handled in it's own update function
        };

        fetch(simulationURL, {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(backendBody),
        }).then((response) => {
          if (!response?.ok) {
            errorHandler(
              response,
              snackBarElement,
              "Error saving changes to server."
            );
          }
        });

        setAnnualDialogInputs(updatedAnnualDialogInputs);
        setButtonLoading(false);
      });
    });
  };

  /**
   * downloads a CSV of the load profile data
   * @param {String} resolution either minute, 15_min, or hour, determines how much data to return
   */
  const handleDownloadCSV = async (resolution = "minute") => {
    //per minute view
    let csvData = data.load_profile.full_power.map((full, index) => ({
      Time: resolution == "minute" ? MFM_to_AMPM(index) : undefined,
      "Full Power (kW)": full,
      "Low Power (kW)": data.load_profile.low_power[index],
    }));

    //if by the hour/15 min, average out every hour/15 min
    if (resolution === "hour" || resolution === "15_min") {
      const denom = resolution === "hour" ? 60 : 15;
      csvData = csvData.reduce(
        (averageData, minute, index) => {
          let sum = averageData.pop();
          sum = {
            "Full Power (kW)":
              sum["Full Power (kW)"] + minute["Full Power (kW)"],
            "Low Power (kW)": sum["Low Power (kW)"] + minute["Low Power (kW)"],
          };
          if (index % denom === denom - 1) {
            sum = {
              Time: MFM_to_AMPM(index + 1 - denom),
              "Full Power (kW)": sum["Full Power (kW)"] / denom,
              "Low Power (kW)": sum["Low Power (kW)"] / denom,
            };
            averageData.push(sum);
            averageData.push({ "Full Power (kW)": 0, "Low Power (kW)": 0 });
          } else {
            averageData.push(sum);
          }
          return averageData;
        },
        [{ "Full Power (kW)": 0, "Low Power (kW)": 0 }]
      );
      csvData.pop(); // removes the trailing 0
    }

    const { worksheet, sheet_options } = await initXLSX();
    XLSX.utils.sheet_add_json(worksheet, csvData, sheet_options);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Depot Energy");
    XLSX.writeFile(workbook, "Depot Energy.csv");
    handleCloseDownloadCSV();
  };

  /**
   * downloads an annual load profile CSV based off user inputs
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  const handleDownloadAnnualLoadProfile = async (event) => {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    if (newDataJSON?.start_date > newDataJSON?.end_date) {
      snackBarElement?.current?.displayToast(
        "Start Date must come before End Date",
        "warning",
        5000
      );
      return;
    }
    setButtonLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.annual_load_profile}`,
    };

    const { data: block_schedule } = await getLocalData("routeEnergy", "data");

    //format start and end dates for backend
    const start_date = newDataJSON.start_date.split("-");
    const end_date = newDataJSON.end_date.split("-");

    const body = {
      block_schedule,
      load_profile: data?.load_profile,
      weekend: {
        operate: newDataJSON.weekendOperation === "on" ? 1 : 0,
        sat: newDataJSON?.sat ? parseInt(newDataJSON.sat) : 0,
        sun: newDataJSON?.sun ? parseInt(newDataJSON.sun) : 0,
      },
      start_date: `${start_date[1]}/${start_date[2]}/${start_date[0]}`,
      end_date: `${end_date[1]}/${end_date[2]}/${end_date[0]}`,
      resolution: parseInt(newDataJSON.resolution),
    };

    fetch(annualLoadURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(async ({ data: responseData }) => {
            const resolution = body.resolution;
            const time = moment(`20230101`, "YYYYMMDD");
            time.subtract(resolution, "minutes");
            let transposedJSON = responseData.annual_load.map((power) => ({
              DateTime: time
                .add(resolution, "minutes")
                .format("M/D/YY h:mm:ss A"),
              "Power (kW)": power,
            }));
            const { worksheet, sheet_options } = await initXLSX();
            XLSX.utils.sheet_add_json(worksheet, transposedJSON, {
              ...sheet_options,
              header: ["DateTime", "Power (kW)"],
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Depot Energy");
            XLSX.writeFile(workbook, "Depot Energy.csv");
            setButtonLoading(false);
          });
        } else {
          errorHandler(response, snackBarElement, "Something went wrong");
          setButtonLoading(false);
        }
      })
      .catch((e) => {
        snackBarElement.current.displayToast(
          "Failed to generate annual Depot Energy CSV",
          "error",
          7000
        );
        console.log(e);
        setButtonLoading(false);
      });
  };

  /**
   * determines which annual load profile button was clicked
   * it's own separate function, so that the two buttons can share the
   * same form values
   * @param {React.FormEvent<HTMLDivElement>} e
   */
  function handleSubmitAnnualLoad(e) {
    e.preventDefault();
    if (e?.nativeEvent?.submitter?.name == "download_annual_btn")
      handleDownloadAnnualLoadProfile(e);
    else handleSaveAnnualLoadInputs(e);
  }

  function handleDownload5DaySchedule(e) {
    e.preventDefault();
    handleCloseDownloadCSV();

    //gets the battery sizing table data from indexdb and creates array of all blocks/rows that were selected and feasible on the battery page
    const batterySizingPromise = getLocalData("battery", "all").then(
      ({ data, input }) => ({
        data: data?.blocks?.filter(
          (block) => block?.checked && !block?.feasible
        ),
        input,
      })
    );

    //gets the selected feasible row from the fleet and charger sizing page
    const fleetSizingBevDataPromise = getLocalData("fleetSizing").then(
      ({ data, input }) => data?.feasible_blocks?.[input?.row]
    );
    //gets the operating_year value from the user's
    const operatingYearPromise = getLocalData("routeEnergy", "input").then(
      ({ input }) => input?.operating_year
    );

    Promise.all([
      batterySizingPromise,
      fleetSizingBevDataPromise,
      operatingYearPromise,
    ])
      .then(
        ([
          { data: batterySizingData, input: batterySizingInputs },
          fleetSizingBevData,
          operating_year,
        ]) => {
          const body = {
            depot_id: batterySizingData[0].endDepot,
            charger_models: [fleetSizingBevData.charger_model.model], //the currently selected FleetSizingRow's chargerModel
            settings: {
              bev_settings: {
                battery_lifetime: operating_year, //operating year from the routeEnergy page
                ...batterySizingInputs?.settings?.bev_settings,
              },
            },
            bev_model_charger_model_map:
              fleetSizingBevData.fleet.model_breakdown.map((fleet) => ({
                vehicle_model: fleet.model,
                charger_model: fleetSizingBevData.charger_model.model,
              })),
            bev_inventory: fleetSizingBevData.fleet.model_breakdown.map(
              (fleet) => ({
                vehicle_model: fleet.model,
                num_vehicles: fleet.ev_fleet_size,
              })
            ),
            blocks: batterySizingData.map((block) => ({
              //same block mapping from routeEnergy.js code
              blockId: block.blockId,
              dh_st_time: block.dh_st_time,
              dh_end_time: block.dh_end_time,
              distance: block.distance,
              startDepot: block.startDepot,
              endDepot: block.endDepot,
              vehicleEff: block.detailed_energy.updated_efficiency,
              vehicleModel: block.vehicleModel,
            })),
            //NOTE: layover_time is currently being hard-coded
            min_layover_time: fleetSizingBevData.min_layover_time,
          };
          const headers = {
            Authorization: `Token ${UseAuth("get")}`,
            "Content-Type": "application/json",
            Accept: `application/json; version=${sim.analysis_type_steps.depot_energy_analysis.five_day_schedules}`,
          };

          fetch(fiveDayScheduleURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
          })
            .then((res) => {
              if (res.ok) {
                res.json().then(({ data }) => {
                  async function generateFiveDayBlob(data) {
                    const workbook = XLSX.utils.book_new();
                    const { worksheet, sheet_options } = await initXLSX();
                    XLSX.utils.sheet_add_json(worksheet, data, sheet_options);
                    XLSX.utils.book_append_sheet(
                      workbook,
                      worksheet,
                      "Alldays"
                    );
                    const workbookBuffer = XLSX.write(workbook, {
                      bookType: "xlsx",
                      type: "array",
                    });
                    return new Blob([workbookBuffer], {
                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                    });
                  }

                  let zip = new JSZip();
                  zip.file(
                    "BEV 5 Day Charging Schedule.xlsx",
                    generateFiveDayBlob(data.bev_charging_schedule)
                  );
                  zip.file(
                    "BEV 5 Day Driving Schedule.xlsx",
                    generateFiveDayBlob(data.bev_driving_schedule)
                  );

                  zip
                    .generateAsync({ type: "blob" })
                    .then((blob) => saveAs(blob, "FiveDaySchedule.zip"));
                });
              } else {
                errorHandler(
                  res,
                  snackBarElement,
                  "Failed to generate five day schedule"
                );
              }
            })
            .catch((e) => {
              console.log(e);
              snackBarElement.current.displayToast(
                "Network Error: Failed to generate five day schedule",
                "error",
                5000
              );
            });
        }
      )

      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Failed to retrieve data to generate CSV",
          "error",
          5000
        );
      });
  }

  const blockTime = batteryData?.settings?.bev_settings?.block_charging || [];

  // Generate annotations based on block_time array
  const annotations = useMemo(() => {
    return blockTime.reduce((acc, timeSlot, index) => {
      acc[`blockCharging${index}`] = {
        type: "box",
        xMin: timeSlot.start, // Start time in minutes
        xMax: timeSlot.end, // End time in minutes
        backgroundColor: "rgba(255, 99, 132, 0.25)",
        borderColor: "rgba(255, 99, 132, 0.6)",
        borderWidth: 1,
      };
      return acc;
    }, {});
  }, [blockTime]);

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle setAnnualLoadInputs={setAnnualDialogInputs} />
        <Chip
          sx={{
            mr: "10%",
            backgroundColor: "#38AECC40",
            borderRadius: "10px",
            //below .every hides the button if all num_chargers are equal
            display:
              (!(chargerSharingData?.length > 1) &&
                chargerSharingData?.every(
                  (val) =>
                    val.num_chargers == chargerSharingData[0].num_chargers
                )) ||
              chartView != 1
                ? "none"
                : undefined,
          }}
          variant="outlined"
          onClick={() => setIsChargerSharingDialogOpen(true)}
          label="Charger&nbsp;Sharing&nbsp;Insights"
          icon={<Info fontSize="small" />}
        />
        {/* chipView dropdown */}
        <Chip
          label={
            chartView == 0 || chartView >= 5
              ? chartNameLookup[chartView]
              : chartNameLookup[1]
          }
          onClick={handleOpenChartMenu}
          sx={{ minWidth: "12rem" }}
        />
        <Menu
          open={Boolean(chartViewAnchorEl)}
          anchorEl={chartViewAnchorEl}
          onClose={handleCloseChartMenu}
          PaperProps={{
            style: { minWidth: chartViewAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
            className: "btn",
          }}
        >
          {Object.entries(chartNameLookup).map(([key, label]) => (
            <MenuItem
              key={`Chart#${key}Option`}
              value={key}
              onClick={handleChartSelect}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent[simpleViewValue]} />
          {chartView === 0 ? (
            <EnergyAnalysisChart
              data={data?.load_profile ?? { full_power: [], low_power: [] }}
              annotations={annotations} // Pass annotations here
            />
          ) : chartView === 6 ? (
            <DepotSimulation
              data={depotSimData}
              allData={data}
              portData={portData}
              timeOfDay={timeOfDay}
              setTimeOfDay={setTimeOfDay}
              setSubheaderContent={setSubheaderContent}
            />
          ) : chartView === 1 ? (
            <VehicleActivityTimeTable
              data={data?.operational_profile ?? []}
              view={chartView}
              setView={setChartView}
            />
          ) : (
            <DrivingActivityTimeTable
              data={data?.operational_profile ?? []}
              view={chartView}
              setView={setChartView}
            />
          )}
        </Paper>
      </Container>
      <br /> <br />
      <Container fixed>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                component={Link}
                to={stepInfo[STEP_NUMBER - 1].route}
                startIcon={<ArrowBackIosNew />}
              >
                Previous Step: {stepInfo[STEP_NUMBER - 1].label}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                disabled={
                  !data?.load_profile?.full_power?.length ||
                  !accessRights.analysis.create_depot_energy_analysis
                }
                onClick={handleOpenDownloadCSV}
                endIcon={
                  isDownloadDropdownOpen ? <ExpandLess /> : <ExpandMore />
                }
              >
                Download Load Profile
              </Button>
            </Grid>
          </Grid>
          <NextPageButton
            onClick={handleSubmit}
            loading={buttonLoading}
            disabled={
              !accessRights.analysis.create_financial_and_emissions_analysis ||
              !sim ||
              !annualDialogInputs
            }
          >
            Continue to {stepInfo[STEP_NUMBER + 1].label} Inputs
          </NextPageButton>
        </Stack>
        <Menu
          open={isDownloadDropdownOpen}
          anchorEl={downloadAnchorEl}
          onClose={handleCloseDownloadCSV}
          PaperProps={{
            style: { minWidth: downloadAnchorEl?.clientWidth },
            className: "btn",
          }} // makes the dropdown the same size as the button
        >
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={() => handleDownloadCSV("minute")}
          >
            Download Daily Load Profile (1-Minute)
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={() => handleDownloadCSV("15_min")}
          >
            Download Daily Load Profile (15-Minute)
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={() => handleDownloadCSV("hour")}
          >
            Download Daily Load Profile (1-Hour)
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "center" }}
            onClick={handleOpenAnnualDownload}
            disabled={!annualDialogInputs}
          >
            Download Annual Load Profile
          </MenuItem>
          {accessRights.analysis.create_five_day_schedules && (
            <MenuItem
              sx={{ justifyContent: "center" }}
              onClick={(e) => handleDownload5DaySchedule(e)}
              disabled={!sim}
            >
              Download 5 Day Schedules
            </MenuItem>
          )}
        </Menu>
      </Container>
      {/* todo: combine this dialog with the matching one in edit Analysis inputs */}
      <Dialog
        open={annualDownloadOpen}
        fullWidth
        maxWidth="md"
        onClose={handleCloseAnnualDownload}
        component="form"
        onSubmit={handleSubmitAnnualLoad}
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Annual Download Parameters
          <FormControlLabel
            control={
              <Checkbox
                name="weekendOperation"
                defaultChecked={Boolean(annualDialogInputs?.weekend?.operate)}
              />
            }
            label="Include Weekends"
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <AnnualLoadProfileInputs inputs={annualDialogInputs} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAnnualDownload}>Close</Button>
          <LoadingButton
            type="submit"
            name="save_annual_btn"
            value="save_annual_btn"
            loading={buttonLoading}
          >
            Save Changes
          </LoadingButton>
          <LoadingButton
            type="submit"
            name="download_annual_btn"
            value="download_annual_btn"
            loading={buttonLoading}
          >
            Download CSV
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Usage and Charger Sharing Analysis Dialog */}
      <Dialog
        open={isChargerSharingDialogOpen}
        onClose={() => setIsChargerSharingDialogOpen(false)}
        maxWidth="lg"
      >
        <ChargerScenarios chargerSharingData={chargerSharingData} />
        <DialogActions>
          <Button onClick={() => setIsChargerSharingDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
