//bases
const backendURL = process.env.REACT_APP_BACKEND_URL ?? "https://backendMock";
const authLink = backendURL + "/auth/";
const apiLink = backendURL + "/api/";
const dataLink = backendURL + "/data/";
const supplementaryLink = backendURL + "/supplementary/";

//auth urls
/** /auth/access-rights/ */
const accessRightsURL = authLink + "access-rights/";
/** /auth/partners/ */
const partnerURL = authLink + "partners/";
/** /auth/partners/activate-deactivate/ */
const partnerActivationToggleURL = authLink + "partners/activate-deactivate/";
/** /auth/customers/ */
const customerURL = authLink + "customers/";
/** /auth/customers/activate-deactivate/ */
const customerActivationToggleURL = authLink + "customers/activate-deactivate/";
/** /auth/users/ */
const usersURL = authLink + "users/";
/** /auth/users/terms-of-service/ */
const termsOfServiceURL = authLink + "users/terms-of-service/";
/** /auth/users/deactivate/ */
const usersDeactivationURL = authLink + "users/deactivate/";
/** /auth/users/profile/ */
const userProfileURL = authLink + "users/profile/";
/** /auth/users/change-role/ */
const changeUserRoleURL = authLink + "users/change-role/";
/** /auth/users/impersonated-login/ */
const impersonateLoginURL = authLink + "users/impersonated-login/";
/** /auth/users/impersonated-logout/ */
const impersonateLogoutURL = authLink + "users/impersonated-logout/";
/** /auth/users/invite-users/ */
const inviteUsersURL = authLink + "users/invite-users/";
/** /auth/complete-user-signup/ */
const inviteRegisterURL = authLink + "complete-user-signup/";
/** /auth/users/send-invite/ */
const resendInviteURL = authLink + "users/send-invite/";
/** /auth/users/onboarding-tour/ */
const onboardingFlagURL = authLink + "users/onboarding-tour/";
/** /auth/countries/ */
const countriesURL = authLink + "countries/";
/** /auth/states/ */
const statesURL = authLink + "states/";
/** /auth/cities/ */
const citiesURL = authLink + "cities/";
/** /auth/organizations/ */
const organizationURL = authLink + "organizations/";
/** /auth/organizations/profile/ */
const organizationProfileURL = authLink + "organizations/profile/";
/** /auth/organizations/user-roles/ */
const userRolesURL = authLink + "organizations/user-roles/";
/** /auth/organizations/brand/ */
const brandURL = authLink + "organizations/brand/";
/** /auth/organizations/brand/status/ */
const brandStatusURL = authLink + "organizations/brand/status/"; // unused
/** /auth/organizations/brand/activation-deactivation/ */
const brandActivationToggleURL =
  authLink + "organizations/brand/activation-deactivation/";
/** /auth/organizations/logo/ */
const logoURL = authLink + "organizations/logo/";
/** /auth/registration/ */
const registerURL = authLink + "registration/";
/** /auth/login/ */
const loginURL = authLink + "login/";
/** /auth/logout/ */
const logoutURL = authLink + "logout/";
/** /auth/password/change/ */
const changePasswordURL = authLink + "password/change/";
/** /auth/password/reset/ */
const passwordResetURL = authLink + "password/reset/";
/** /auth/password/reset/confirm/ */
const passwordResetConfirmURL = authLink + "password/reset/confirm/";

//api urls
/** /api/gtfs/ */
const gtfsURL = apiLink + "gtfs/";
// /** /api/block-scheduling-assignment/ */
// const blockSchedulingURL = apiLink + "block-scheduling-assignment/";
// /** /api/charging-process-simulation/ */
// const chargingSimulationURL = apiLink + "charging-process-simulation/";
/** /api/download/ */
const downloadURL = apiLink + "download/";
// /** /api/routeenergymodeler/ */
// const routeEnergyURL_deprecated = apiLink + "routeenergymodeler/";
/** /api/rem/ */
const routeEnergyURL = apiLink + "rem/";
/** /api/battery/ */
const batteryURL = apiLink + "battery/";
/** /api/fleet-and-charger-sizing/ */
const fleetSizingURL = apiLink + "fleet-and-charger-sizing/";
// /** /api/load-profile */
// const loadProfileURL = apiLink + "load-profile";
/** /api/five-day-schedules/ */
const fiveDayScheduleURL = apiLink + "five-day-schedules/";
/** /api/ev-assessment/ */
const evAssessmentURL = apiLink + "ev-assessment/";
/** /api/tco/ */
const tcoURL = apiLink + "tco/";
/** /api/gtfs/schedule/ */
const gtfsCalendarURL = apiLink + "gtfs/schedule/";
/** /api/gtfs/schedule/ */
const annualLoadURL = apiLink + "annual-load/";
/** /api/combine-blocks/ */
const combineBlocksURL = apiLink + "combine-blocks/";
/** /api/schoolbus/ */
const schoolBusURL = apiLink + "schoolbus/";
/** /api/charger-sharing/report/ */
const chargerSharingReportURL = apiLink + "charger-sharing/report/";

//data urls
/** /data/projects/ */
const projectURL = dataLink + "projects/";
/** /data/projects/move/ */
const moveProjectURL = dataLink + "projects/move/";
/** /data/depots/ */
const depotURL = dataLink + "depots/";
/** /data/resources/ */
const resourceURL = dataLink + "resources/";
/** /data/chargers/ */
const chargerURL = dataLink + "chargers/";
/** /data/vehicles/ */
const vehicleURL = dataLink + "vehicles/";
/** /data/emissions/ */
const emissionsURL = dataLink + "emissions/";
/** /data/simulations/ */
const simulationURL = dataLink + "simulations/";
/** /data/simulations/clone/ */
const cloneSimulationURL = dataLink + "simulations/clone/";
/** /data/simulations/lock-unlock/ */
const toggleLockSimulationURL = dataLink + "simulations/lock-unlock/";
/** /data/design-case */
const designCaseURL = dataLink + "design-case/";
/** /data/analysis-types/ */
const analysisTypesURL = dataLink + "analysis-types/";
/** /data/tariffs/ */
const tariffURL = dataLink + "tariffs/";

//supplementary urls
/** /supplementary/download/school/ */
const schoolBusDownloadURL = supplementaryLink + "download/school/";
/** /supplementary/download/manual/ */
const fleetManualDownloadURL = supplementaryLink + "download/manual/";
/** /supplementary/create-ticket/ */
const createTicketURL = supplementaryLink + "create-ticket/";

export {
  accessRightsURL,
  analysisTypesURL,
  annualLoadURL,
  batteryURL,
  brandActivationToggleURL,
  brandStatusURL,
  brandURL,
  changePasswordURL,
  changeUserRoleURL,
  chargerSharingReportURL,
  chargerURL,
  citiesURL,
  cloneSimulationURL,
  combineBlocksURL,
  countriesURL,
  createTicketURL,
  customerActivationToggleURL,
  customerURL,
  depotURL,
  designCaseURL,
  // blockSchedulingURL,
  // chargingSimulationURL,
  downloadURL,
  emissionsURL,
  evAssessmentURL,
  // loadProfileURL,
  fiveDayScheduleURL,
  fleetManualDownloadURL,
  fleetSizingURL,
  gtfsCalendarURL,
  gtfsURL,
  impersonateLoginURL,
  impersonateLogoutURL,
  inviteRegisterURL,
  inviteUsersURL,
  loginURL,
  logoURL,
  logoutURL,
  moveProjectURL,
  onboardingFlagURL,
  organizationProfileURL,
  organizationURL,
  partnerActivationToggleURL,
  partnerURL,
  passwordResetConfirmURL,
  passwordResetURL,
  projectURL,
  registerURL,
  resendInviteURL,
  resourceURL,
  // routeEnergyURL_deprecated,
  routeEnergyURL,
  schoolBusDownloadURL,
  schoolBusURL,
  simulationURL,
  statesURL,
  tariffURL,
  tcoURL,
  termsOfServiceURL,
  toggleLockSimulationURL,
  userProfileURL,
  userRolesURL,
  usersDeactivationURL,
  usersURL,
  vehicleURL,
};

