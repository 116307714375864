import AirportShuttle from "@mui/icons-material/AirportShuttle";
import BatteryChargingFull from "@mui/icons-material/BatteryChargingFull";
import BatteryFull from "@mui/icons-material/BatteryFull";
import DirectionsBusFilledTwoTone from "@mui/icons-material/DirectionsBusFilledTwoTone";
import EvStation from "@mui/icons-material/EvStation";
import FastForward from "@mui/icons-material/FastForward";
import Fullscreen from "@mui/icons-material/Fullscreen";
import Login from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import OfflineBolt from "@mui/icons-material/OfflineBolt.js";
import Pause from "@mui/icons-material/Pause";
import PlayArrow from "@mui/icons-material/PlayArrow";
import RotateLeft from "@mui/icons-material/RotateLeft";
import RotateRight from "@mui/icons-material/RotateRight";
import Sort from "@mui/icons-material/Sort.js";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Cookie from "js-cookie";
import { Fragment, useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import renewableEnergyIcon from "../../../static/images/renewable-energy.png";
import {
  MFM_to_AMPM,
  MFM_to_TimeRemaining,
  roundNumber,
  stringCapitalize,
} from "../../utils";
import {
  PureVehicleActivityTimeTable,
  status_label_lookup,
} from "../graphs/vehicleActivityTimeTable.js";
import { profileHelper } from "../pages/energyAnalysis";

/** used to capitalize the subtitle's label for depot simulation views */
const titleCreator = (title) =>
  title.split("_").map(stringCapitalize).join(" ");

/**
 * calculates the subheader for the depot simulation view
 * @param {{charging_zone: Array, "On the Road": Array, preprocess_zone: Array, postprocess_zone: Array}} data an object of all the profile data at a given time of day
 * @param {Boolean} [does_process=true] boolean denoting if there is/isnt pre/post processing
 * @returns {{value: string, label: string}[]}
 */
export function DepotSimulationSubheaderCalculation(data, does_process = true) {
  return Object.entries(data)
    .filter(([key]) =>
      [
        "charging_zone",
        does_process && "preprocess_zone",
        does_process && "postprocess_zone",
        "On the Road",
      ].includes(key)
    )
    .map(([key, vehicleList]) => ({
      value: `${vehicleList.length} Vehicle${
        vehicleList.length != 1 ? "s" : ""
      }`,
      label: titleCreator(key),
    }));
}

//plugin to add data to the center of a doughnut chart
/** @type {import("chart.js").Plugin<"doughnut", AnyObject>} */
export const doughnutHoleText = {
  id: "doughnutHoleText",
  afterDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      options,
      data,
      chartArea: { left, right, top, bottom, width, height }, //get chart canvas' dimensional values
    } = chart;
    ctx.font = "1em Verdana";
    ctx.textBaseline = "middle";
    const { value, label } = data.datasets[0].centerText,
      fontHeight =
        ctx.measureText(label).fontBoundingBoxAscent +
        ctx.measureText(label).fontBoundingBoxDescent,
      offsetY =
        (Math.abs(width - bottom * 2) <= 2 ? height : bottom / 2) -
        (fontHeight * 3) / 4; // if the circle is a semi-circle, don't divide the height by 2

    let textX = Math.round((width - ctx.measureText(value).width) / 2);
    let textY = Math.round(offsetY);
    ctx.fillText(value, textX, textY);
    ctx.font = "0.75em Verdana";
    textX = Math.round((width - ctx.measureText(label).width) / 2);
    textY = Math.round(offsetY + (fontHeight * 3) / 4);
    ctx.fillText(label, textX, textY);
  },
};

export default function DepotSimulation({
  data,
  allData,
  portData,
  timeOfDay,
  setTimeOfDay,
  setSubheaderContent,
}) {
  /** @type {[boolean|string]} the string key of the zone that is open, if any */
  const [dialogOpenKey, setDialogOpenKey] = useState(false);
  const [simulationSpeed, setSimulationSpeed] = useState(0); // 0 is pause, 1 is 1x speed, 2 is 2x speed, etc.
  const [vehActivityTableData, setVehActivityTableData] = useState(undefined);
  const [visibleVehicles, setVisibleVehicles] = useState([]);
  const [sort, setSort] = useState(0);

  const sortOptions = [
    {
      label: "Start Time",
      order: "asc",
      func: (a, b) => a.start_time - b.start_time,
    },
    {
      label: "Start Time",
      order: "desc",
      func: (a, b) => b.start_time - a.start_time,
    },
    {
      label: "End Time",
      order: "asc",
      func: (a, b) => a.end_time - b.end_time,
    },
    {
      label: "End Time",
      order: "desc",
      func: (a, b) => b.end_time - a.end_time,
    },
    {
      label: "State of Charge",
      order: "asc",
      func: (a, b) => a.soc - b.soc,
    },
    {
      label: "State of Charge",
      order: "desc",
      func: (a, b) => b.soc - a.soc,
    },
  ];

  const chartRef = useRef();

  const simulationSpeedIconLookup = [
    <PlayArrow fontSize="large" color="secondary" />,
    <FastForward fontSize="large" color="secondary" />,
    "2x",
    "3x",
    "4x",
  ];

  /**
   * updates the time of day (and subheader) to the desired value
   * @param {Number|String} newTimeOfDay the new time of day, in Minutes From Midnight (MFM)
   */
  function handleChangeTimeOfDay(newTimeOfDay) {
    if (newTimeOfDay >= 1440) setSimulationSpeed(0);
    newTimeOfDay = newTimeOfDay % 1440; // prevents time of day from going into next
    setTimeOfDay(newTimeOfDay);
    setSubheaderContent((orig) => ({
      ...orig,
      3: DepotSimulationSubheaderCalculation(
        data[newTimeOfDay],
        portData.does_process
      ),
    }));
  }

  /**
   * updates the time of day (and subheader) to the desired value, and sets the simulation speed to zero
   * @param {Number|String} newTimeOfDay the new time of day, in Minutes From Midnight (MFM)
   */
  function handleUserChangeTimeOfDay(newTimeOfDay) {
    setSimulationSpeed(0);
    handleChangeTimeOfDay(newTimeOfDay);
  }

  //useEffect is used to pause/play the time of day
  useEffect(() => {
    let intervalTimer;
    if (simulationSpeed) {
      let counter = 0;
      intervalTimer = setInterval(() => {
        counter++;
        handleChangeTimeOfDay(timeOfDay + counter * simulationSpeed);
      }, Math.floor(200));
    }

    return () => {
      clearInterval(intervalTimer);
    };
  }, [simulationSpeed]);

  let fullPowerLimit = Math.max(...(allData?.load_profile?.full_power || [0]));

  const maxPower = roundNumber(fullPowerLimit / 0.8 / 0.9, 2);

  const marks = Array(9)
    .fill(0)
    .map((_e, i) => ({ value: i * 180, label: MFM_to_AMPM(i * 180) }));

  /**
   * made as a nested component to reduce lag
   * @param {string} param0.vehicle
   */
  function VehicleCard({ vehicle }) {
    const { profile } = allData.operational_profile.find(
      ({ vehicle_id }) => vehicle_id == vehicle.vehicle_id
    );
    const profileData = profile.find(
      (prof) =>
        prof.start_time - 2880 <= timeOfDay && prof.end_time - 2880 >= timeOfDay
    );
    const currentVehicleData = profileHelper(profileData, timeOfDay);

    return (
      <Box p={1}>
        <Paper
          style={{
            margin: 3,
            height: 30,
            borderRadius: 50,
            background: "white",
            border: "2px solid lightgray",
            paddingLeft: 5,
            paddingRight: 5,
            textAlign: "left",
          }}
        >
          <Box
            display="flex"
            width={`${
              currentVehicleData.status != "charging"
                ? Math.abs(
                    100 -
                      ((currentVehicleData.end_time - 2880 - timeOfDay) /
                        (currentVehicleData.end_time -
                          currentVehicleData.start_time)) *
                        100
                  )
                : currentVehicleData.soc
            }%`}
          >
            <div
              style={{
                width: "100%",
                backgroundImage: `linear-gradient(to right, white,${currentVehicleData.soc_color})`,
                marginTop: 10,
                height: 10,
              }}
            />

            {currentVehicleData.status == "charging" ||
            currentVehicleData.status == "precondition_battery" ||
            currentVehicleData.status == "precondition_hvac" ? (
              <img
                src={renewableEnergyIcon}
                width="24px"
                height="24px"
                style={{ marginTop: "3px", marginLeft: "-12px" }}
              />
            ) : currentVehicleData.status == "block_charging" ? (
              <Pause
                sx={{
                  ml: "-10px",
                  mt: "1px",
                  border: "2px solid",
                  borderRadius: 90,
                }}
              />
            ) : currentVehicleData.status == "idle_post_charging" ? (
              <OfflineBolt sx={{ ml: "-5px", mt: "3px" }} />
            ) : (
              <AirportShuttle sx={{ ml: "-1px", mt: "4px" }} />
            )}
          </Box>
        </Paper>
        <Paper
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 3,
            backgroundColor: "#e2dee6",
            borderRadius: 10,
            color: "black",
          }}
        >
          <span
            style={{
              width: 100,
              textAlign: "left",
              backgroundColor: "#c1c1c1",
              padding: 7,
              borderRadius: 10,
              clipPath:
                "polygon(0% 100%, 100% 100%, calc(100% - 10px) 0%, 0% 0%, calc(100% - 10px) 0%, 0% 0%)",
            }}
          >
            Vehicle ID:
          </span>
          <Box whiteSpace="nowrap" style={{ padding: 7, fontWeight: "bold" }}>
            {vehicle.vehicle_id}
          </Box>
        </Paper>
        <Grid
          justifyContent="space-between"
          style={{
            display: "flex",
            flexDirection: "row",
            borderRadius: 10,
            fontSize: 12,
            marginTop: 5,
            marginLeft: 5,
          }}
        >
          <Typography variant="caption" height={1} noWrap fontWeight="bold">
            {status_label_lookup[currentVehicleData.status].label} End Time:
          </Typography>
          <Typography variant="caption" height={1}>
            &nbsp;{MFM_to_AMPM(currentVehicleData.end_time)}
          </Typography>
        </Grid>
        <Box
          alignItems="center"
          justifyContent="space-between"
          style={{
            display: "flex",
            flexDirection: "row",
            borderRadius: 10,
            fontSize: 12,
            marginLeft: 5,
          }}
        >
          <Typography variant="caption" height={1} noWrap fontWeight="bold">
            Current SoC:
          </Typography>
          <div
            style={{
              borderRadius: 10,
              width: 40,
              height: 18,
              fontSize: 13,
              margin: 2,
              textAlign: "center",
              borderStyle: "solid",
              borderColor: currentVehicleData.soc_color,
              borderTopWidth: currentVehicleData.soc >= 25 ? "2px" : 0,
              borderRightWidth: currentVehicleData.soc >= 50 ? "2px" : 0,
              borderBottomWidth: currentVehicleData.soc >= 75 ? "2px" : 0,
              borderLeftWidth: currentVehicleData.soc >= 95 ? "2px" : 0,
            }}
          >
            {Math.round(currentVehicleData.soc)}%
          </div>
        </Box>
      </Box>
    );
  }

  const VehicleIcon = (vehicle) => (
    <>
      {!dialogOpenKey && (
        <LinearProgress
          variant="determinate"
          value={vehicle.soc}
          sx={{
            ".MuiLinearProgress-bar1Determinate": {
              backgroundColor: vehicle.soc_color,
            },
            my: 1,
            width: "70%",
          }}
        />
      )}
      <DirectionsBusFilledTwoTone
        color="inherit"
        sx={{ mx: 2 }}
        fontSize="inherit"
      />
    </>
  );

  const CustomChargingBatteryIcon = ({ vehicle: { status }, props }) => {
    switch (status) {
      case "charging":
      case "precondition_hvac":
      case "precondition_battery":
        return (
          <BatteryChargingFull
            className="rotate-icon-2"
            {...props}
            sx={{ fontSize: "80%", color: "rgb(54, 162, 235)", ...props?.sx }}
          />
        );
      case "block_charging":
      case "idle_post_charging":
      case "idle_init":
        return (
          <BatteryFull
            className="rotate-icon-2"
            {...props}
            sx={{ fontSize: "80%", color: "rgb(255, 99, 132)", ...props?.sx }}
          />
        );
      default:
        //otherwise, display a blank space where the icon (and the linearProgress) would be
        return <Icon {...props} sx={{ fontSize: "80%", ...props?.sx }} />;
    }
  };

  /**
   * dialog content, done in a separate function to prevent the sorting from occuring on all components/running everywhere always
   * TECHNICALLY, defining a nested component like this is bad practice (according to SonarCloud), but I'm doing it anyway, because I want to.
   * @param {Array} data
   */
  function FullScreenDialogContent({ data }) {
    if (!data.length) return "No Vehicles in Zone at Current Time";
    data = [...data].sort(sortOptions[sort].func); //sort a shallow copy of the array, so that it doesn't affect the original
    return data.map((vehicle) => (
      <Box
        key={vehicle.vehicle_id}
        borderRadius={2}
        textAlign="center"
        m="2px"
        pb={1}
        width="calc(20% - 4px)"
        sx={{
          background: `linear-gradient(to top, ${vehicle.soc_color} ${
            vehicle.soc
          }%, rgba(0,0,0,0.25) ${vehicle.soc + 1}%)`,
        }}
        color={vehicle.soc >= 95 ? "white" : undefined}
      >
        <Typography
          display="flex"
          justifyContent="center"
          variant="h6"
          height={2}
        >
          {status_label_lookup[vehicle.status]?.label || "Unknown"}
          {vehicle.status == "driving" && " on"}
        </Typography>
        {vehicle.status == "driving" && (
          <>
            <br />
            <Typography
              display="flex"
              justifyContent="center"
              variant="subtitle1"
              height={2}
            >
              {vehicle.block}
            </Typography>
          </>
        )}
        <Box fontSize="50px">
          <br />
          {VehicleIcon(vehicle)}
        </Box>
        {vehicle.vehicle_id}
        <br />
        {MFM_to_TimeRemaining(vehicle.end_time - 2880 - timeOfDay)}
      </Box>
    ));
  }

  function DisplayCard(icon, key) {
    const clickHandler = (vehicle) => {
      const data = allData.operational_profile.find(
        ({ vehicle_id }) => vehicle_id == vehicle.vehicle_id
      );
      const data_segments = data.profile.map((val) => ({
        x: [val.start_time, val.end_time],
        y: data.vehicle_id,
        ...val,
      }));
      setVehActivityTableData({
        data: [data],
        data_segments: data_segments,
        vehicle,
      });
      setTimeout(() => {
        chartRef?.current?.update();
      }, [10]);
    };
    const displayData = data[timeOfDay][key].filter(
      ({ vehicle_id }) =>
        !visibleVehicles.length || visibleVehicles.includes(vehicle_id)
    );
    return (
      <Card sx={{ width: "48%", m: "1%" }}>
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between">
              <span>
                {icon} {titleCreator(key)}
              </span>
              <IconButton onClick={() => setDialogOpenKey(key)}>
                <Fullscreen />
              </IconButton>
            </Box>
          }
        />
        <CardContent sx={{ height: "8rem", overflowY: "auto" }}>
          <Stack flexWrap="wrap" direction="row">
            {displayData.map((vehicle) => (
              <Fragment key={vehicle.vehicle_id}>
                <Tooltip arrow title={<VehicleCard vehicle={vehicle} />}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ fontSize: "36px", cursor: "pointer" }}
                    color={vehicle.soc_color}
                    onClick={() => clickHandler(vehicle)}
                  >
                    {key == "charging_zone" && (
                      <CustomChargingBatteryIcon vehicle={vehicle} />
                    )}
                    {VehicleIcon(vehicle)}
                    <Divider orientation="horizontal" sx={{ width: "100%" }} />
                  </Box>
                </Tooltip>
                <Divider orientation="vertical" flexItem />
              </Fragment>
            ))}
          </Stack>
        </CardContent>
        <Dialog
          fullWidth
          maxWidth="md"
          open={dialogOpenKey == key}
          onClose={() => setDialogOpenKey(false)}
        >
          <DialogTitle display="flex" justifyContent="space-between">
            <span>
              {icon} {titleCreator(key)}
            </span>
            {MFM_to_AMPM(timeOfDay)}
            <Select
              size="small"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            >
              {sortOptions.map((value, index) => (
                <MenuItem key={`${value.label}_${value.order}`} value={index}>
                  <ListItemIcon>
                    <Sort
                      fontSize="small"
                      className={value.order === "asc" ? "rotate-icon-3" : ""}
                    />
                  </ListItemIcon>
                  {value.label}
                </MenuItem>
              ))}
            </Select>
          </DialogTitle>
          <DialogContent>
            <Stack flexWrap="wrap" direction="row">
              <FullScreenDialogContent data={displayData} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpenKey(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }

  /** @type {import("chart.js").ChartData} */
  const loadProfileChartData = {
    datasets: [
      {
        label: "Current Power",
        backgroundColor: ["rgb(54, 162, 235)", "lightgrey"],
        data: [
          allData?.load_profile?.full_power?.[timeOfDay],
          maxPower - allData?.load_profile?.full_power?.[timeOfDay],
        ],
        centerText: {
          value:
            Math.round(allData?.load_profile?.full_power?.[timeOfDay]) + " kW",
          label: "Depot Power",
        },
      },
      {
        label: "Percent Ranges",
        weight: 0.25,
        backgroundColor: ["green", "rgb(247,207,107)", "red"],
        data: [60, 20, 20],
      },
    ],
  };

  /** @type {import("chart.js").ChartOptions} */
  const loadProfileChartOptions = {
    cutout: 45, // the percentage of the center of the chart to cut out to make pie chart into a doughnut chart (default is "50%")
    rotation: -90,
    circumference: 180,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: { tooltip: { enabled: false } },
  };

  /** @type {import("chart.js").ChartData} */
  const chargerChartData = {
    labels: ["Charging", "Blocked", "Available"],
    datasets: [
      {
        data: [
          portData.using_port[timeOfDay],
          portData.plugged_in[timeOfDay] - portData.using_port[timeOfDay],
          allData?.num_chargers - portData.plugged_in[timeOfDay],
        ],
        centerText: { value: allData?.num_chargers, label: "Chargers" },
      },
    ],
  };

  /** @type {import("chart.js").ChartOptions} */
  const chargerChartOptions = {
    cutout: 60, // the percentage of the center of the chart to cut out to make pie chart into a doughnut chart (default is 50)
    plugins: {
      tooltip: {
        callbacks: {
          title: () => null,
          label: (val) => ` ${val.formattedValue} ${val.label}`,
        },
      },
      legend: { position: "right", labels: { boxWidth: 13 } },
    },
  };

  return (
    <>
      <br />
      <Box display="flex" justifyContent="space-evenly">
        <IconButton
          sx={{
            width: "51px",
            height: "51px",
            ml: "1%",
            backgroundColor: "lightgrey",
            color: "blue",
          }}
          onClick={() =>
            setSimulationSpeed(
              (prev) => (prev + 1) % simulationSpeedIconLookup.length
            )
          }
        >
          {simulationSpeedIconLookup[simulationSpeed]}
        </IconButton>
        <Slider
          sx={{ maxWidth: "100%", mx: "3em" }} // make sure to give left/rightmost labels room to display full time
          color="secondary"
          value={timeOfDay}
          min={0}
          max={1440}
          marks={marks}
          valueLabelDisplay="auto"
          valueLabelFormat={MFM_to_AMPM}
          onChange={(e) => handleUserChangeTimeOfDay(e.target.value)}
        />
      </Box>
      <Grid container alignItems="stretch">
        <Grid item xs={12} sm={9} display="flex" flexWrap="wrap">
          {DisplayCard(<EvStation />, "charging_zone")}
          {portData.does_process && (
            <>
              {DisplayCard(<RotateRight />, "postprocess_zone")}
              {DisplayCard(<RotateLeft />, "preprocess_zone")}
            </>
          )}
          {DisplayCard(
            <>
              <Logout />
              <AirportShuttle />
            </>,
            "departing_soon"
          )}
          {DisplayCard(
            <>
              <AirportShuttle />
              <Login />
            </>,
            "arriving_soon"
          )}
          {DisplayCard(<AirportShuttle />, "On the Road")}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card sx={{ height: "97.5%", m: "3%" }}>
            <CardHeader title={Cookie.get("depot_name")} />
            <CardContent sx={{ p: 0, height: "calc(100% -  1.5em - 32px)" }}>
              <FormControl
                sx={{ m: 1, minWidth: "75%", maxWidth: "calc(100% - 16px)" }}
                size="small"
              >
                <InputLabel
                  id="vehicle-select-label"
                  sx={{ alignItems: "center" }}
                >
                  Filter by Vehicle(s)
                </InputLabel>
                <Select
                  labelId="vehicle-select-label"
                  label="Filter by Vehicle(s)"
                  value={visibleVehicles}
                  onChange={(e) =>
                    setVisibleVehicles(
                      typeof e.target.value == "string"
                        ? e.target.value.split(",")
                        : e.target.value
                    )
                  }
                  multiple
                  MenuProps={{ PaperProps: { sx: { maxHeight: "60%" } } }}
                >
                  {allData?.operational_profile?.map(({ vehicle_id }) => (
                    <MenuItem key={vehicle_id} value={vehicle_id}>
                      {vehicle_id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
                maxWidth="100%"
                height="100%"
              >
                <Tooltip
                  title={`Transformer Rating: ${
                    Math.ceil(maxPower / 50) * 50
                  } kVA`}
                >
                  <Box>
                    <Doughnut
                      data={loadProfileChartData}
                      options={loadProfileChartOptions}
                      plugins={[doughnutHoleText]}
                    />
                  </Box>
                </Tooltip>
                <Box ml={1}>
                  <Doughnut
                    data={chargerChartData}
                    options={chargerChartOptions}
                    plugins={[doughnutHoleText]}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* selected Vehicle's vehicleActivityTimeTable */}
      {vehActivityTableData && (
        <Grid container maxHeight={130}>
          <Grid item xs={3} minHeight={30 + 6 + 30 + 12 + 5 + 12 + 5}>
            <VehicleCard vehicle={vehActivityTableData.vehicle} />
          </Grid>
          <Grid item xs={9} maxHeight="100%" width="100%">
            <PureVehicleActivityTimeTable
              {...vehActivityTableData}
              timeOfDay={timeOfDay}
              view={1}
              options={{
                maintainAspectRatio: false,
                scales: {
                  x: { ticks: { display: false }, title: { display: false } },
                  y: { ticks: { display: false }, title: { display: false } },
                },
                layout: { padding: {} },
                onClick: (event, _elements, { chartArea }) =>
                  handleUserChangeTimeOfDay(
                    Math.round(
                      ((event.x - chartArea.left) / chartArea.width) * 1440
                    )
                  ),
                plugins: {
                  legend: { display: false },
                  tooltip: { yAlign: "bottom", animation: false }, //disable animation to prevent lag during time of day progression
                  annotation: {
                    annotations: {
                      line1: {
                        type: "line",
                        xMax: timeOfDay + 2880,
                        xMin: timeOfDay + 2880,
                      },
                    },
                  },
                },
              }}
              props={{ ref: chartRef }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
