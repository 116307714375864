import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from "@mui/icons-material/Cancel";
import Edit from "@mui/icons-material/Edit";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  DialogContentText,
  IconButton,
  InputAdornment,
  Switch,
  Tooltip,
  lighten,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataContext } from "../../../contexts/dataContext";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import {
  depotURL,
  gtfsURL,
  resourceURL,
  routeEnergyURL,
  simulationURL,
  vehicleURL,
} from "../../../static/constants/backendRoutes";
import { defaultBlocksInput } from "../../../static/constants/defaultInputs";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import stepInfo, {
  assessmentStepZero,
} from "../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitLargeMap,
  unitSmallMap,
} from "../../../static/constants/systems_of_measurement";
import UseAuth from "../../auth/useAuth";
import MRT_DownloadButton from "../../secondary/mrtDownloadButton";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import Subheader from "../../secondary/subheader";
import {
  getUnits,
  unitFeet,
  unitMiles,
  unitPerMile,
} from "../../secondary/unitConversions";
import {
  MFM_to_Military,
  Military_to_AMPM,
  Military_to_MFM,
  errorHandler,
  getLocalData,
  parseFromValuesOrFunc,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import { BlockInputsForm, hvacOptions } from "../dialogs/editAnalysisInputs";
import NestableList from "../dialogs/nestableList";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import RouteDefinitionGraph from "../graphs/routeDefinitionGraph";
import RouteDefinitionTimeTable from "../graphs/routeDefinitionTimeTable";
import RouteDefinitionGroupEdit from "../tables/routeDefinitionGroupEdit";

/** @typedef {import("material-react-table")} MRT */

const STEP_NUMBER = 1;

//keys are based off project types
const initialBlockInputLookup = {
  1: {
    hvac: { heating: true, cooling: true },
    weekend: { operate: 1, sat: 60, sun: 40 },
    start_date: "2023-01-01",
    end_date: "2023-12-31",
    resolution: 15,
  },
  2: defaultBlocksInput,
  3: defaultBlocksInput,
  4: {
    hvac: { heating: true, cooling: true },
    weekend: { operate: 0, sat: 0, sun: 0 },
    start_date: "2023-08-15",
    end_date: "2024-05-31",
    resolution: 15,
  },
  5: defaultBlocksInput,
  6: defaultBlocksInput,
};

/**
 * converts a table data row into the format necessary for the material-react-table
 */
export const rowDataConversion = (row) => {
  //convert distance into correct units (note: size is not converted, since that is editted using dropdowns)
  row.distance = unitMiles(row.distance);
  //convert MFM to Military, so that the material-react-table edits can work without too much extra handling of values
  row.dh_st_time = MFM_to_Military(row.dh_st_time);
  // row.startTime = MFM_to_Military(row.startTime);
  // row.endTime = MFM_to_Military(row.endTime);
  row.dh_end_time = MFM_to_Military(row.dh_end_time);
  return row;
};

/**
 * converts a table data row from material-react-table format back into the format used for storage and backend operations
 * @param {JSON} row an individual row of data
 * @param {JSON} [rowSelection={}] the rowSelection state
 */
function rowDataReversion(row, rowSelection = {}) {
  //set startDepot equal to endDepot
  row.startDepot = row.endDepot;
  //convert distance (note: size does not need to be converted, as it is editted via a dropdown)
  row.distance = unitPerMile(row.distance);
  //convert times
  row.dh_end_time = Military_to_MFM(row.dh_end_time); //gets time data in MFM format
  row.dh_st_time = Military_to_MFM(row.dh_st_time);
  if (row.dh_st_time > row.dh_end_time) row.dh_end_time += 1440;
  // row.endTime = Military_to_MFM(row.endTime);
  // row.startTime = Military_to_MFM(row.startTime);
  //since rowDataReversion is only used when storing data in steps, bring checked status up to date as well
  row.checked = Boolean(rowSelection[row.id]);
  return row;
}

export default function RouteDefinition() {
  const [data, setData] = useState([]);
  const [depotData, setDepotData] = useState([]);
  const [depotLookup, setDepotLookup] = useState({});
  /** @type {[{depot_id: {veh_type: {size: "model"}}}]} */
  const [vehicleLookup, setVehicleLookup] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // represents the location of the "battery sizing inputs" item after clicking the options button
  const [blockInputsDialogOpen, setBlockInputsDialogOpen] = useState(false);
  const [depotWarningDialogOpen, setDepotWarningDialogOpen] = useState(false);
  /** @type {[{hvac: {heating: Boolean, cooling: Boolean}, weekend: {operate: 0|1, sat:Number, sun: Number}, start_date:String, end_date: String, resolution:15|60}|undefined]} */
  const [blockInputs, setBlockInputs] = useState();
  const [showTable, setShowTable] = useState(0); ///0 for display table, 1 for display chart, 2 for timetable
  const viewNameLookup = { 0: "Table", 1: "Chart", 2: "Timetable" };
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [currentProject, setCurrentProject] = useState();
  const [groupEditOpen, setGroupEditOpen] = useState(false); //opens the dialog for alternate bulk edit by vehicle model
  const [combineBlocksDialogOpen, setCombineBlocksDialogOpen] = useState(false);
  const [dataFetchError, setDataFetchError] = useState(false);
  const [subheaderContent, setSubheaderContent] = useState([]);

  const originalColumnVisibility = useRef(null); //used to restore column visibility after edit, if an editable column was hidden
  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [validationErrors, setValidationErrors] = useState({}); //used to display table errors for cells on attempted edit of rows
  const [sim, setSim] = useState();
  const [groupEditValidationErrors, setGroupEditValidationErrors] = useState(
    {}
  );
  const [columnVisibility, setColumnVisibility] = useState({
    //initially hidden columns
    block_id_original: false,
  });
  const [enableHiding, setEnableHiding] = useState(true); // used to prevent users from hiding editable columns during edits
  /** @type {[MRT.MRT_Row<MRT.TData> | null]} */
  const [editingRow, setEditingRow] = useState(null);
  /** @type {[MRT.RowSelectionState]} */
  const [rowSelection, setRowSelection] = useState({});
  //used to ensure that all selected rows share the same depot (without having to check the entire table every re-render)
  const [isValidSelection, setIsValidSelection] = useState(false);

  const { accessRights, analysisStepsViewMemo } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const units = getUnits();

  function updateSubheader(currentBlocks) {
    let [avg, max, min] = [
      0,
      currentBlocks[0]?.distance,
      currentBlocks[0]?.distance,
    ];
    currentBlocks.forEach((row) => {
      avg += row.distance;
      max = Math.max(row.distance, max);
      min = Math.min(row.distance, min);
    });
    avg = avg / currentBlocks.length;
    setSubheaderContent([
      {
        value: `~ ${Math.round(avg).toLocaleString()}`,
        label: `Average Distance (${unitLargeMap[units]})`,
      },
      {
        value: `~ ${Math.round(min).toLocaleString()}`,
        label: `Minimum Distance (${unitLargeMap[units]})`,
      },
      {
        value: `~ ${Math.round(max).toLocaleString()}`,
        label: `Maximum Distance(${unitLargeMap[units]})`,
      },
      { value: currentBlocks.length, label: "Number of Blocks" },
    ]);
  }

  useEffect(() => {
    if (data.length) updateSubheader(data);
  }, [data]);

  useEffect(() => {
    /**
     * retrieves the blocks and project from the local DB and then
     * fetches and stores all the depots, vehicles, and chargers
     * that are associated with the selected project from the backend
     * and does something else?
     */
    async function fetchData() {
      try {
        if (!UseAuth("get")) {
          window.location.assign("/login"); ///TODO: should display error page
          return;
        }

        //retrieves blocks from the localDb
        const { data: currentBlocks, input } = await getLocalData("blocks");
        //retrieves the project JSON from the localDb
        const { data: currentProject } = await getLocalData("project", "data");
        const { data: sim } = await getLocalData("simulation", "data"); // used to check that the selected depot matches the analysis depot; also, for the auto-select rows if no prior selected are found
        setCurrentProject(currentProject);
        setBlockInputs(input || initialBlockInputLookup[currentProject.type]);
        setSim(sim);

        if (!currentBlocks) {
          setDataFetchError(true); //if no indexDb data is found
          return;
        }

        let rowSelection = {};

        currentBlocks.forEach((row, index) => {
          if (isNaN(row?.id)) row.id = index;
          rowDataConversion(row);
          // deprecated row.tableData.checked: left in for original functionality, replaced with row.checked
          if (row?.tableData?.checked || row?.checked)
            rowSelection[row.id] = true;
          delete row.tableData; //delete tableData, if there was any
        });

        //perform header update after unit conversion
        updateSubheader(currentBlocks);

        if (!Object.keys(rowSelection).length) {
          // if (!currentBlocks.filter((i) => i.checked).length) {
          //   //if there are no previously saved selected rows,
          //   // auto - select based on the depot selected on the selected depot
          //   //overwrite the currentBlocks and selectedRows with checked rows
          currentBlocks.forEach(
            (row) =>
              row.endDepot == sim?.depot_id &&
              unitPerMile(row.distance) > 1 &&
              (rowSelection[row.id] = true)
          );
          setIsValidSelection(true);
          setRowSelection(rowSelection);
        } else {
          //if there were pre-selected rows, check if they all share a depot (and disable progression if they don't)
          const selectedRowIds = Object.keys(rowSelection);
          const endDepot = currentBlocks.find(
            (row) => row.id == selectedRowIds[0]
          ).endDepot;
          setIsValidSelection(
            selectedRowIds.every(
              (rowId) =>
                currentBlocks[rowId].endDepot == endDepot &&
                unitPerMile(currentBlocks[rowId].distance) > 1
            )
          );
          setRowSelection(rowSelection);
        }

        //if it is in the localDb, then store it in frontend
        setData(currentBlocks);

        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };

        //fetches all the depots associated with the selected project
        fetch(`${depotURL}?project_id_list=${currentProject.id}`, {
          method: "GET",
          headers: headers,
        })
          .then((res) => {
            if (res.ok) {
              return res.json().then(({ data: depots }) => {
                //populates depot_lookup
                const depot_lookup = {};
                depots.forEach((val) => (depot_lookup[val.id] = val.name));
                setDepotData(depots);
                setDepotLookup(depot_lookup);
                return depots;
              });
            }
            //else
            errorHandler(res, snackBarElement);
            setDataFetchError(true);
            return [];
          })
          .catch((err) => {
            console.log(String(err));
            setData([]);
            setDepotLookup({});
            setDataFetchError(true);
            snackBarElement.current.displayToast(
              "Something went wrong while fetching depots",
              "error",
              7000
            );
            return [];
          });

        //gets all the vehicles associated with the selected project
        const vehicleResourcePromise = fetch(
          `${resourceURL}?project_id=${currentProject.id}&type=2`,
          {
            method: "GET",
            headers: headers,
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json().then(({ data }) => data);
            }
            //else
            errorHandler(res, snackBarElement);
            return [];
          })
          .catch((err) => {
            console.log(String(err));
            setDataFetchError(true);
            setData([]);
            return [];
          });

        const allVehiclesPromise = axios.get(vehicleURL, { headers: headers });

        //creates a lookup hash of vehicles, for use in edits and energy analysis
        Promise.all([vehicleResourcePromise, allVehiclesPromise]).then(
          ([
            vehicleResource,
            {
              data: { data: allVehicles },
            },
          ]) => {
            //make an object of {depotId: {veh_type: {size: model}}}, so that the code doesn't need to re-computer this for every single cell edit during bulk edits
            const vehicleLookup = {};

            vehicleResource.forEach((resource) => {
              const vehicleInfo = allVehicles.find(
                (vehicle) => vehicle.model == resource.resource_id
              );
              if (!(resource.depot_id in vehicleLookup))
                vehicleLookup[resource.depot_id] = {};
              if (!(vehicleInfo.type in vehicleLookup[resource.depot_id]))
                vehicleLookup[resource.depot_id][vehicleInfo.type] = {};
              //each vehicle resource will always be a new entry in the table, so no need for if statement
              vehicleLookup[resource.depot_id][vehicleInfo.type][
                vehicleInfo.size
              ] = {
                model: vehicleInfo.model,
                vehicleEff: vehicleInfo.efficiency,
              };
            });

            setVehicleLookup(vehicleLookup);
          }
        );

        setDataFetchError(true);
      } catch (e) {
        if (e.response)
          errorHandler(e, snackBarElement, "Failed to get vehicle data");
        else {
          console.log(e);
          snackBarElement.current.displayToast(
            "Something went wrong, try again later",
            "error"
          );
        }
        setDataFetchError(true);
      }
    }
    fetchData();
  }, []);

  /**
   *
   * @param {{cell: import("material-react-table").MRT_Cell<never, unknown>,column: import("material-react-table").MRT_Column<never, unknown>,row: import("material-react-table").MRT_Row<never>,table: import("material-react-table").MRT_TableInstance<never>}} param0
   * @returns {import("@mui/material").TextFieldProps}
   */
  const muiEditTextFieldPropsStandard = ({ table, row, cell }) => ({
    error: !!validationErrors[cell.id],
    helperText: validationErrors[cell.id],
    onChange: (e) => {
      row._valuesCache[cell.column.id] = e.target.value;
      if (isBulkEdit) table.setEditingCell(cell);
    },
    onBlur: () =>
      setValidationErrors({ ...validationErrors, [cell.id]: undefined }),
  });

  /**
   *
   * @param {cell: import("material-react-table").MRT_Cell<never, unknown>,column: import("material-react-table").MRT_Column<never, unknown>,row: import("material-react-table").MRT_Row<never>,table: import("material-react-table").MRT_TableInstance<never>} param0
   * @returns {import("@mui/material").TextFieldProps}
   */
  const muiEditTextFieldPropsTime = ({ table, row, cell }) => {
    const start = row.getValue("dh_st_time");
    const end = row.getValue("dh_end_time");
    return {
      ...muiEditTextFieldPropsStandard({ table, row, cell }),
      type: "time",
      className: cell.getValue() ? "has-value" : "",
      onBlur: () => {
        if (!end || !start || start > end)
          setValidationErrors({
            ...validationErrors,
            [`${row.index}_dh_st_time`]: `Must be less than Depot Arrive Time`,
            [`${row.index}_dh_end_time`]: `Must be greater than Depot Depart Time`,
          });
        else if (start <= "00:10")
          setValidationErrors({
            ...validationErrors,
            [`${row.index}_dh_st_time`]: `Must be greater than 12:10 AM`,
          });
        else
          setValidationErrors({
            ...validationErrors,
            [`${row.index}_dh_st_time`]: undefined,
            [`${row.index}_dh_end_time`]: undefined,
          });
      },
    };
  };

  /**
   * should fire when closing EITHER row edit or bulk edit,
   * resets the column visibility to state that it was prior to edit
   */
  function generalEditClose() {
    setEnableHiding(true); //prevents users from hiding editable columns during edits
    if (originalColumnVisibility.current != null) {
      //if the column visisbility was automatically altered to include hidden editable columns, restore it to original form
      setColumnVisibility(originalColumnVisibility.current);
      originalColumnVisibility.current = null;
    }
  }

  /**
   * should fire when opening either bulk or row edit,
   * ensures all editable columns are visible, and if they're not,
   * it also saves the original visibility status for when edit is closed
   * @param {MRT.MRT_Row<MRT.TData> | null | undefined} [row] optional param used in row edit change, to determine that the row wasn't just closed
   */
  function generalEditOpen(row = undefined, isBulk = true) {
    if (row !== null || isBulk) setEnableHiding(false); //prevents users from hiding editable columns during edits, use if statement to avoid running when canceling a row edit
    const editableCols = columns.filter((col) => col.enableEditing != false);
    if (
      editableCols.some(
        (col) => columnVisibility?.[col.accessorKey] == false
      ) &&
      row !== null
    ) {
      originalColumnVisibility.current = { ...columnVisibility };
      setColumnVisibility((prev) => ({
        ...prev,
        ...editableCols.reduce(
          (colVis, col) => ({ ...colVis, [col.accessorKey]: true }),
          {}
        ),
      }));
    }
  }

  function onEditingRowClose() {
    generalEditClose();
    setEditingRow(null);
    setValidationErrors({});
  }

  /**
   * Generates a validation errors (that aren't previously handled by a cell's props) for a row
   * returns an object containing any new validation errors
   * @param {*} row
   * @param {*} oldValidationErrors
   */
  function handleSaveRowChangesValidation(
    row,
    oldValidationErrors = validationErrors
  ) {
    //note: similar implementation in group edit's save
    //validate new row data (some validation done in columns)
    const updatedValidationErrors = {
      ...oldValidationErrors,
      // depot validation
      [`${row.id}_endDepot`]:
        !(row.endDepot in vehicleLookup) && "Invalid input",
      //type validation
      [`${row.id}_veh_type`]:
        !(row.veh_type in (vehicleLookup?.[row.endDepot] ?? {})) &&
        "Type not in depot",
      //size validation
      [`${row.id}_size`]:
        !(row.size in (vehicleLookup?.[row.endDepot]?.[row.veh_type] ?? {})) &&
        "Size not in depot's types",
    };
    return updatedValidationErrors;
  }

  /**
   *
   * @param {import('@tanstack/react-table').OnChangeFn<import("material-react-table").MRT_Row<never> | null} row
   */
  function onEditingRowChange(row) {
    generalEditOpen(row, false);
    setEditingRow(row ? { ...row } : null); // allow for editingRow to be null
  }

  /**
   * @param {{ exitEditingMode: () => void, row: import("material-react-table").MRT_Row<never>, table: MRT_TableInstance<never>, values: Record<string & Record<never, never>, any>}} props
   */
  async function onEditingRowSave({ exitEditingMode, row, table, values }) {
    if (
      row.getValue("endDepot") == row.original.endDepot &&
      row.getValue("veh_type") == row.original.veh_type &&
      row.getValue("size") == row.original.size &&
      row.getValue("distance") == roundNumber(row.original.distance) && // todo: double check that the distance won't cause a misfire here due to rounding
      row.getValue("dh_end_time") == row.original.dh_end_time &&
      row.getValue("dh_st_time") == row.original.dh_st_time
      // row.getValue("endTime") == row.original.endTime &&
      // row.getValue("startTime") == row.original.startTime
    ) {
      //checks if the new block Data is the same as the old block, and
      //doesn't send a new PATCH if the data is unaltered
      snackBarElement.current.displayToast(
        "data was not altered from Original",
        "info"
      );
      return;
    }

    let newRow = { ...row.original, ...row._valuesCache };

    const validationErrorObject = handleSaveRowChangesValidation(newRow);

    if (Object.values(validationErrorObject).some((error) => error)) {
      //if any rows have invalid data, display message, and stop save
      setValidationErrors(validationErrorObject);
      return;
    }

    // update the vehicleEff and vehicle model
    newRow.distance = +newRow?.distance; //make sure distance is stored as a Number, not a string
    newRow.vehicleEff =
      vehicleLookup[newRow.endDepot][newRow.veh_type][newRow.size].vehicleEff;
    newRow.vehicleModel =
      vehicleLookup[newRow.endDepot][newRow.veh_type][newRow.size].model;

    // call the API if and only if the "endDepot" changes.
    // In all other situations, "assume the user knows what they are doing" and only update the values locally
    if (
      analysisStepsViewMemo?.state?.input_method == 1 &&
      row.original.endDepot != newRow.endDepot &&
      row.original.veh_type == newRow.veh_type &&
      row.original.size == newRow.size &&
      roundNumber(row.original.distance, 2) ==
        roundNumber(newRow.distance, 2) &&
      row.original.dh_end_time == newRow.dh_end_time &&
      row.original.dh_st_time == newRow.dh_st_time
    ) {
      //updates the backend with the modified block
      const formData = new FormData();
      const x = rowDataReversion({ ...newRow });
      formData.append("patch_input", JSON.stringify(x));

      fetch(gtfsURL, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${UseAuth("get")}`,
          Accept: `application/json; version=${sim.analysis_type_steps.create_simulation.import_gtfs_fleet_data}`,
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(({ block }) => {
              const index = data.findIndex((x) => x.id == block.id); //note: technically, ID should already be the index for routeDefinition
              data[index] = rowDataConversion({ ...block });
              data[index].vehicleEff =
                vehicleLookup[newRow.endDepot][newRow.veh_type][
                  newRow.size
                ].vehicleEff;
              let currentRowSelection = rowSelection;
              if (rowSelection[data[index].id]) {
                delete rowSelection[data[index].id];
                currentRowSelection = handleSelectionChange(rowSelection);
              }
              updateSubheader(data);
              setData([...data]);
              //NOTE: MAYBE don't update indexDb every time a row changes
              storeLocalData("blocks", {
                data: JSON.parse(JSON.stringify(data)).map(
                  (row) => rowDataReversion(row, currentRowSelection) //deep copy before reverting, so as to not affect the table's render data
                ),
              });
              exitEditingMode(); //for when the edit row is successful
              onEditingRowClose();
            });
          } else
            errorHandler(response, snackBarElement, "Failed to update data");
        })
        .catch((err) => {
          console.log(String(err));
          snackBarElement.current.displayToast(
            "Looks like that didn't work",
            "error"
          );
        });
    } else {
      let currentRowSelection = rowSelection;
      if (
        row.original.endDepot != newRow.endDepot &&
        rowSelection[row.original.id]
      ) {
        //if row was selected, but the depot changed, deselect that row
        currentRowSelection = handleSelectionChange({
          ...rowSelection,
          [row.original.id]: false,
        });
      }
      //once all checks pass, update the data to match (don't use the valuesParsed, as that data has been converted)
      const index = data.findIndex((x) => x.id == newRow.id); //note: technically, for routeDefinition, row id should match the index, but use findIndex for consistensy with other pages, and just to be safe
      data[index] = newRow;
      updateSubheader(data);
      //deep copy & convert data back into standard units and store
      storeLocalData("blocks", {
        data: JSON.parse(JSON.stringify(data)).map((row) =>
          rowDataReversion(row, currentRowSelection)
        ),
      });
      setData([...data]);
      exitEditingMode(); //for when the edit row is successful
      onEditingRowClose();
    }
  }

  /** closes the bulk edit functionality */
  function handleBulkEditClose() {
    setIsBulkEdit(false);
    setGroupEditOpen(false);
    onEditingRowClose();
  }

  function handleBulkEditSave(e) {
    e.preventDefault();

    let oldData = [];
    let newData = [];

    table.getCoreRowModel().rows.forEach((row) => {
      oldData.push(row.original);
      newData.push(
        Object.keys(row.original).reduce(
          (rowData, colKey) => ({
            ...rowData,
            [colKey]: row._valuesCache?.[colKey] ?? row.original[colKey],
          }),
          {}
        )
      );
    });

    const alteredRows = newData.filter(
      (row, index) =>
        row.endDepot != oldData[index].endDepot ||
        row.veh_type != oldData[index].veh_type ||
        row.size != oldData[index].size ||
        row.distance != oldData[index].distance || //note: distance isn't rounded here, since if it is unmodified, the newData's value will be the same as the original
        row.dh_end_time != oldData[index].dh_end_time ||
        row.dh_st_time != oldData[index].dh_st_time
    );

    if (!alteredRows.length) {
      //if no data was changed from original, display an error message, and return
      snackBarElement.current.displayToast(
        "data was not altered from Original",
        "info"
      );
      return;
    }

    //validate altered table data rows (some validation done in columns)
    const validationErrorObject = alteredRows.reduce(
      (newValidationErrors, row) =>
        handleSaveRowChangesValidation(row, newValidationErrors),
      validationErrors
    );
    if (Object.values(validationErrorObject).some((error) => error)) {
      //if any cells have invalid data, display message, and stop save
      snackBarElement?.current?.displayToast(
        "Invalid cell data detected",
        "error",
        5000
      );
      setValidationErrors(validationErrorObject);
      return;
    }

    alteredRows.forEach((row) => {
      // update the vehicleEff and vehicle model
      row.distance = +row?.distance;
      row.vehicleEff =
        vehicleLookup[row.endDepot][row.veh_type][row.size].vehicleEff;
      row.vehicleModel =
        vehicleLookup[row.endDepot][row.veh_type][row.size].model;
      //replace existing table data with new row values
      const index = data.findIndex((oldRow) => oldRow.id == row.id);
      data[index] = row;
    });

    // if any depots were changed, ensure they are no longer selected
    alteredRows.forEach((row) => {
      if (
        rowSelection[row.id] &&
        row.endDepot != oldData.find((oldRow) => oldRow.id == row.id).endDepot
      )
        rowSelection[row.id] = false;
    });
    const currentRowSelection = handleSelectionChange(rowSelection);
    updateSubheader(data);
    //deep copy & convert data back into standard units and store
    storeLocalData("blocks", {
      data: JSON.parse(JSON.stringify(data)).map((row) =>
        rowDataReversion(row, currentRowSelection)
      ),
    });
    setData([...data]);
    handleBulkEditClose();
  }

  /**
   * Saves group bulkedit
   * @param {SubmitEvent} event
   */
  function handleGroupEditSave(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    //gets the status of the isAllRows toggle, and then deletes the value from the form
    const isAllRows = Boolean(formData.get("isAllRows"));
    formData.delete("isAllRows");

    //create a lookup table of old data values to an object containing all altered values
    const depot_type_size_old_new_lookup = {};
    formData
      .entries()
      .filter(([_keys, val]) => val) //removes unaltered values (empty strings)
      .forEach(([keys, val]) => {
        //splits key into `depot,type,size` and key (i.e. endDepot, distance, veh_type, or size)
        const [old_depotTypeSize, newKey] = keys.split("_:");
        depot_type_size_old_new_lookup[old_depotTypeSize] = {
          ...depot_type_size_old_new_lookup[old_depotTypeSize],
          [newKey]: isNaN(val) ? val : +val, //if it's a number, convert it to a number
        };
      });

    //todo: consider altering so that instead of duplicating all the data into newData, you just use tha alteredRows, similar to how it is done in the bulkEditSave
    //combines the original data with newly editted fields
    const newData = data.map((row) => {
      const old_depotTypeSize = `${row.endDepot},${row.veh_type},${row.size}`;
      let newRow = row;
      if (
        old_depotTypeSize in depot_type_size_old_new_lookup &&
        (isAllRows || rowSelection[row.id]) //(if user only wants to alter selected rows) don't update rows that aren't selected
      )
        newRow = {
          ...newRow,
          ...depot_type_size_old_new_lookup[old_depotTypeSize], //overwrites fields with any that were editted
        };

      // assign the associated vehicleEff and vehicleModel for any and all potentially altered rows
      newRow.vehicleEff =
        vehicleLookup?.[newRow.endDepot]?.[newRow.veh_type]?.[
          newRow.size
        ]?.vehicleEff;
      newRow.vehicleModel =
        vehicleLookup?.[newRow.endDepot]?.[newRow.veh_type]?.[
          newRow.size
        ]?.model;
      return newRow;
    });

    //note: can probably be combined with the handleSaveRowChangesValidation
    const newGroupEditValidationErrors = data.reduce(
      (newGroupEditValidationErrors, oldRow, index) => {
        //checks that there are no invalid values in the new data
        const newRow = newData[index];
        return {
          ...newGroupEditValidationErrors,
          // depot validation
          [`${oldRow.endDepot},${oldRow.veh_type},${oldRow.size}_:endDepot`]:
            !(newRow.endDepot in vehicleLookup) && "Invalid input",
          //type validation
          [`${oldRow.endDepot},${oldRow.veh_type},${oldRow.size}_:veh_type`]:
            !(newRow.veh_type in (vehicleLookup?.[newRow.endDepot] ?? {})) &&
            "Type not in depot",
          //size validation
          [`${oldRow.endDepot},${oldRow.veh_type},${oldRow.size}_:size`]:
            !(
              newRow.size in
              (vehicleLookup?.[newRow.endDepot]?.[newRow.veh_type] ?? {})
            ) && "Size not in depot's types",
        };
      },
      groupEditValidationErrors /// carries over any pre-existing invalidated values (primarily, distance)
    );

    if (Object.values(newGroupEditValidationErrors).some((i) => i)) {
      //if there are any invalid values in the modified data, display the associated warnings, and cancel the save
      setGroupEditValidationErrors(newGroupEditValidationErrors);
      return;
    }

    //deselect any rows with altered endDepots
    let currentRowSelection = { ...rowSelection };
    newData.forEach((row, index) => {
      if (currentRowSelection[row.id] && data[index].endDepot != row.endDepot)
        currentRowSelection[row.id] = false;
    });

    currentRowSelection = handleSelectionChange(
      currentRowSelection,
      newData.map((row) => row.endDepot)
    );

    //save the new data, and close the dialog
    setData(newData);
    storeLocalData("blocks", {
      data: JSON.parse(JSON.stringify(newData)).map((row) =>
        rowDataReversion(row, currentRowSelection)
      ),
    });
    handleBulkEditClose();
  }

  /**
   * handles submission of the "Route Energy Inputs"
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  function handleBlockInputsSubmit(event) {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());
    const newBlockInputs = {
      ...blockInputs,
      hvac: hvacOptions[newDataJSON.hvacKey].hvac,
    };
    storeLocalData("blocks", { input: newBlockInputs });
    setBlockInputs(newBlockInputs);
    setBlockInputsDialogOpen(false);
  }

  function handleRouteEnergyCalcPreCheck(e) {
    e.preventDefault();
    if (!Object.values(rowSelection).some((i) => i)) {
      //technically, this check should never fire, as button is disabled if no rows are selected
      snackBarElement.current.displayToast("No Rows Selected", "warning");
      return;
    }
    //check if the analysis' selected depot matches the depot of the selected rows; and display warning message if it doesn't
    const selectedDepotId = table
      .getSelectedRowModel()
      .rows[0].getValue("endDepot");
    if (sim.depot_id != selectedDepotId) {
      setDepotWarningDialogOpen(true);
      return;
    }
    handleRouteEnergyCalc(e);
  }

  /** "run block scheduling" button-
   * gathers all the selected input blocks (rows) and
   * sends them to the backend API for energy calculation
   * and stores the response (routeEnergy) into indexDb
   * and navigates to /route-energy page
   */
  const handleRouteEnergyCalc = async (e) => {
    setButtonLoading(true);

    //convert data back into correct formatting to send to routeEnergyModeler
    const revertedData = JSON.parse(JSON.stringify(data)).map((row) =>
      rowDataReversion(row, rowSelection)
    );

    //filter out non-selected rows, and remove the checked status from those rows (for sending to backend)
    const selectedRows = revertedData.filter((row) => {
      delete row.checked; //delete checked status, so that it doesn't get sent to next page
      return rowSelection[row.id];
    });

    //grab the selected row's depot, for climateData
    const depot = depotData.find(
      (depot) => depot.id == selectedRows[0]?.endDepot
    );

    const body = JSON.stringify({
      block_schedule: selectedRows,
      climate: depot.climate,
      hvac: blockInputs?.hvac,
    });

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.fleet_operation.route_energy_analysis}`,
    };

    fetch(routeEnergyURL, {
      method: "POST",
      headers: headers,
      body: body,
    })
      .then((response) => {
        delete headers["Accept"];
        if (response.ok) {
          response.json().then((responseData) => {
            const input =
              blockInputs || initialBlockInputLookup[currentProject.type];

            //updates the checked row status, so that when it gets saved to indexDb, checked rows aren't lost
            revertedData.forEach((row) => (row.checked = rowSelection[row.id]));
            storeLocalData("blocks", { data: revertedData, input: input });
            storeLocalData("routeEnergy", { data: responseData });

            //Save the data on the backend Body
            const backendBody = {
              id: sim.id, //The simulation ID
              current_page: stepInfo[STEP_NUMBER + 1].route,
              // project_id: sim.project_id,
              depot_id: responseData[0]?.endDepot, //include depot_id, to ensure
              steps: {
                blocks: revertedData,
                routeEnergy: responseData,
                input: { blocks: input },
                //clear out future pages' backend data
                battery: {},
                fleetSizing: {},
                evAssessment: {},
                financial: {},
                tco: {},
              },
              completed: false,
            };

            //clear out the future pages' frontend data
            partialClearLocalData([
              "battery",
              "fleetSizing",
              "evAssessment",
              "financial",
              "tco",
            ]);

            fetch(simulationURL, {
              method: "PATCH",
              headers: headers,
              body: JSON.stringify(backendBody),
            })
              .then((response) => {
                if (response.ok) {
                  //if PATCH succeeded, and sim's depot_id was altered, update it in steps
                  if (sim.depot_id != responseData[0]?.endDepot)
                    storeLocalData("simulation", {
                      data: {
                        ...sim,
                        depot_id: Number(responseData[0]?.endDepot),
                      },
                    });
                  snackBarElement.current.displayToast(
                    `${stepInfo[STEP_NUMBER].label} Calculation Complete`
                  );
                  navigate(stepInfo[STEP_NUMBER + 1].route);
                } else {
                  errorHandler(
                    response,
                    snackBarElement,
                    "Error Sending Data to Server"
                  );
                  setButtonLoading(false);
                }
              })
              .catch((e) => {
                snackBarElement.current.displayToast(
                  "Error Sending Data to Server",
                  "error"
                );
                console.log("error", e);
                setButtonLoading(false);
              });
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            `Failed to run ${stepInfo[STEP_NUMBER + 1].label} Analysis`
          );
          setButtonLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          `Network Error: Failed to run ${
            stepInfo[STEP_NUMBER + 1].label
          } Analysis`,
          "error",
          5000
        );
        setButtonLoading(false);
      });
  };

  /**
   * this function is used to validate both
   * - manual change in selection (fired by user checking/unchecking a box)
   * - user changing the depot location (via saving an editted row)
   * @param {Object} newSelectedRows map of row Ids to current selection status
   * @param {Number[]} [altDataDepots] if, for some reason, you can't use the table instance to get most recent depot values, provide them here
   */
  function handleSelectionChange(newSelectedRows, altDataDepots = undefined) {
    //filter out any false selections (if any, since generally, there aren't any)
    const newSelectedRowIds = Object.entries(newSelectedRows)
      .filter(([_i, selected]) => selected)
      .map(([i]) => i);
    //note: must get the endDepot data from the table ref, to ensure it is up-to-date with recent edits
    const currentEndDepots =
      altDataDepots ||
      table
        .getCoreRowModel()
        .rows.map(
          (row) => row._valuesCache?.endDepot ?? row.original?.endDepot
        );
    const endDepot = currentEndDepots[newSelectedRowIds[0]];
    const { rowsById } = table.getCoreRowModel();
    //ensure that the new selection doesn't have any rows with conflicting depots
    if (
      newSelectedRowIds.some(
        (i) =>
          endDepot != currentEndDepots[i] ||
          unitPerMile(rowsById[i].getValue("distance")) <= 1
      )
    )
      setIsValidSelection(false);
    else setIsValidSelection(true);

    setRowSelection(newSelectedRows);
    return newSelectedRows;
  }

  function handleSelectionChangeTable(selectionUpdate) {
    //get the new row selection
    const newSelectedRows = selectionUpdate(rowSelection);

    handleSelectionChange(newSelectedRows);
  }

  /**
   * defines the column structure of the table
   */
  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */
    () => {
      const vehTypeFilterOptionsSet = new Set(data.map((row) => +row.veh_type)); // create a set of all veh_types, for use in the filter of vehicle type dropdown
      return [
        {
          header: "Route ID",
          accessorKey: "block_id_original",
          visibleInShowHideMenu: data.some((i) => i.block_id_original),
          enableEditing: false,
        },
        {
          header: "Block ID",
          accessorKey: "blockId",
          enableEditing: false,
        },
        {
          header: "Depot Depart Time",
          accessorKey: "dh_st_time",
          enableHiding, // prevents users from hiding columns during edits
          Cell: ({ cell }) => Military_to_AMPM(cell.getValue()),
          muiEditTextFieldProps: muiEditTextFieldPropsTime,
          filterFn: (row, columnId, filterValue) =>
            Military_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
          // filterVariant: "time-range",
        },
        // {
        //   header: "Block Start Time",
        //   accessorKey: "startTime",
        //   Cell: ({ cell }) =>
        //     isNaN(cell.getValue()) ? "N/A" : Military_to_AMPM(cell.getValue()),
        //   muiEditTextFieldProps: muiEditTextFieldPropsTime,
        // },
        // {
        //   header: "Block End Time",
        //   accessorKey: "endTime",
        //   Cell: ({ cell }) =>
        //     isNaN(cell.getValue()) ? "N/A" : Military_to_AMPM(cell.getValue()),
        //   muiEditTextFieldProps: muiEditTextFieldPropsTime,
        // },
        {
          header: "Depot Arrive Time",
          accessorKey: "dh_end_time",
          enableHiding, // prevents users from hiding columns during edits
          Cell: ({ cell }) => Military_to_AMPM(cell.getValue()),
          muiEditTextFieldProps: muiEditTextFieldPropsTime,
          filterFn: (row, columnId, filterValue) =>
            Military_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
        },
        {
          header: "Distance",
          accessorKey: "distance",
          accessorFn: (row) => roundNumber(row.distance),
          enableHiding, // prevents users from hiding columns during edits
          units: unitLargeAbbr[units], //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper(unitLargeAbbr[units])}
            </>
          ),
          muiEditTextFieldProps: ({ table, row, cell }) => ({
            ...muiEditTextFieldPropsStandard({ table, row, cell }),
            type: "number",
            InputProps: {
              inputProps: { max: 1000, min: 0.01, step: 0.01 },
              endAdornment: (
                <InputAdornment position="end">
                  {unitLargeAbbr[units]}
                </InputAdornment>
              ),
            },
            onBlur: () =>
              setValidationErrors({
                ...validationErrors,
                [cell.id]: !(cell.getValue() > 0) //note: cell.id == ${row.index}_distance`
                  ? "Must be greater than 0"
                  : cell.getValue() >= 1000
                  ? "Must be less than 1000"
                  : undefined,
              }),
          }),
        },
        {
          header: "Depot Location",
          accessorKey: "endDepot",
          enableHiding, // prevents users from hiding columns during edits
          Cell: ({ cell }) =>
            depotLookup[cell.getValue()]
              ? depotLookup[cell.getValue()]
              : "Loading...",
          editVariant: "select",
          editSelectOptions: Object.entries(depotLookup).map(
            ([value, label]) => ({ value, label })
          ),
          muiEditTextFieldProps: ({ table, row, cell }) => ({
            ...muiEditTextFieldPropsStandard({ table, row, cell }),
            onFocus: () => onEditingRowChange(row),
          }),
          filterVariant: "autocomplete",
          filterSelectOptions: Object.entries(depotLookup).map(
            ([value, label]) => ({ value, label })
          ),
        },
        {
          header: "Vehicle Type",
          accessorKey: "veh_type",
          enableHiding, // prevents users from hiding columns during edits
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          editVariant: "select",
          editSelectOptions: ({ row }) => {
            const depot = row.getValue("endDepot");
            return depot in vehicleLookup
              ? Object.keys(vehicleLookup[depot]).map((veh_type) => ({
                  label: TYPE_STRINGS.VEHICLE_TYPE[veh_type],
                  value: veh_type,
                }))
              : [];
          },
          muiEditTextFieldProps: ({ table, row, cell }) => ({
            ...muiEditTextFieldPropsStandard({ table, row, cell }),
            onFocus: () => onEditingRowChange(row),
          }),
          filterVariant: "autocomplete",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
          filterFn: "equals", // prevents fuzzy matches like "Box Truck" (2) = "Tractor Trailer" (12)
        },
        {
          header: "Size",
          accessorKey: "size",
          enableHiding, // prevents users from hiding columns during edits
          // type: "numeric",
          Cell: ({ row, cell }) =>
            row.getValue("veh_type") == 1 ? (
              <>
                {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
              </>
            ) : row.getValue("veh_type") == 4 ? (
              //if selected vehicle type is a schoolbus, display Vehicle Type Character instead
              `Type ${cell.getValue()}`
            ) : (
              `Class ${cell.getValue()}`
            ),
          editVariant: "select",
          editSelectOptions: ({ row }) => {
            const depot = row.getValue("endDepot");
            const veh_type = row.getValue("veh_type");
            return depot in vehicleLookup && veh_type in vehicleLookup[depot]
              ? Object.keys(vehicleLookup[depot][veh_type]).map((size) => ({
                  label:
                    veh_type == 1 ? (
                      <>
                        {unitFeet(size)} {unitWrapper(unitSmallMap[units])}
                      </>
                    ) : veh_type == 4 ? (
                      `Type ${size}`
                    ) : (
                      `Class ${size}`
                    ),
                  value: size,
                }))
              : [];
          },
          muiEditTextFieldProps: muiEditTextFieldPropsStandard,
          filterFn: (row, columnId, filterValue) =>
            (row.getValue("veh_type") == 1
              ? unitFeet(row.getValue(columnId))
              : row.getValue("veh_type") == 4
              ? `Type ${row.getValue(columnId)}`
              : `Class ${row.getValue(columnId)}`
            )
              .toString()
              .indexOf(filterValue) != -1,
          // filterVariant: "autocomplete",
          // filterSelectOptions: Object.entries()
          //   .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
          //   .map(([value, label]) => ({ value, label })),
        },
      ];
    },
    [depotLookup, vehicleLookup, editingRow, validationErrors, enableHiding] //todo: maybe add data to this dependency array
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data,
    columns,
    state: {
      ...materialReactTableOptions().state,
      rowSelection,
      showAlertBanner: Object.values(rowSelection).some((i) => i),
      isLoading: !dataFetchError,
      editingRow,
      columnVisibility,
    },
    //miscellaneous options
    onColumnVisibilityChange: setColumnVisibility,
    //selection settings
    enableRowSelection: true,
    getRowId: (row) => row.id,
    onRowSelectionChange: handleSelectionChangeTable,
    renderToolbarAlertBannerContent: (props) => {
      const { rows: selectedRowList } = props.table.getSelectedRowModel();
      return (
        <Box display="flex" justifyContent="space-between">
          {materialReactTableOptions().renderToolbarAlertBannerContent(props)}
          {!isValidSelection && (
            <Box display="flex" alignContent="center" alignItems="center">
              <ErrorOutline sx={{ px: "1rem" }} fontSize="small" />
              {selectedRowList.some(
                (row) => unitPerMile(row.getValue("distance")) <= 1
              ) ? (
                <>
                  All&nbsp;blocks&nbsp;must&nbsp;have&nbsp;distance&nbsp;greater&nbsp;than&nbsp;
                  {roundNumber(unitMiles(1), 1)}&nbsp;
                  {unitWrapper(unitLargeAbbr[units])}
                </>
              ) : (
                <>
                  All&nbsp;blocks&nbsp;must&nbsp;have&nbsp;same&nbsp;depot&nbsp;location
                </>
              )}
            </Box>
          )}
        </Box>
      );
    },
    muiToolbarAlertBannerProps: (rest) => {
      const props = parseFromValuesOrFunc(
        materialReactTableOptions().muiToolbarAlertBannerProps,
        rest
      );
      return {
        ...props,
        sx: (theme) => {
          const sxProps = parseFromValuesOrFunc(props?.sx, theme);
          return {
            ...sxProps,
            //turns "n rows selected" red if selected rows don't all share a depot
            color: isValidSelection ? sxProps?.color : theme.palette.error.main,
            backgroundColor: isValidSelection
              ? sxProps?.backgroundColor
              : lighten(theme.palette.error.light, 0.85),
          };
        },
      };
    },
    //data edit settings
    enableEditing: true,
    editDisplayMode: isBulkEdit ? "table" : "row",
    // row edit settings
    onEditingRowChange: onEditingRowChange,
    onEditingRowSave: onEditingRowSave,
    onEditingRowCancel: onEditingRowClose,
    //bulk edit settings
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <span style={{ width: "100%" }} />
        {isBulkEdit ? (
          <>
            <Button
              className="btn"
              variant="outlined"
              onClick={() => setGroupEditOpen(true)}
              sx={{ px: 3 }}
            >
              Group Edit
            </Button>
            <Tooltip title="Cancel Edit All">
              <IconButton
                onClick={() => {
                  setData(
                    table.getCoreRowModel().rows.map((row) => row.original)
                  ); //resets the data to what it was before the edit (note: could possibly be done without the setData)
                  handleBulkEditClose();
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save Edit All">
              <IconButton
                disabled={!Object.keys(vehicleLookup).length}
                onClick={handleBulkEditSave}
              >
                <SaveIcon color="info" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Edit All">
            <IconButton
              onClick={() => {
                setIsBulkEdit(true);
                generalEditOpen();
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        <MRT_DownloadButton
          table={table}
          downloadOptions={{ "EVopt Template": true }}
          fileName={stepInfo[STEP_NUMBER].label}
          disabled={!Object.keys(depotLookup).length || !data.length}
        />
      </>
    ),
  });

  const isNavDisabled = isBulkEdit || Boolean(table?.getState()?.editingRow);

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle setBlockInputs={(value) => setBlockInputs(value)} />
        <Chip
          label={viewNameLookup[showTable]}
          onClick={(e) => setViewAnchorEl(e.currentTarget)}
          sx={{ minWidth: "6rem" }}
        />
        <Menu
          open={Boolean(viewAnchorEl)}
          anchorEl={viewAnchorEl}
          onClose={() => setViewAnchorEl(null)}
          PaperProps={{
            style: { minWidth: viewAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
            className: "btn",
          }}
        >
          {Object.entries(viewNameLookup).map(([key, label]) => (
            <MenuItem
              key={`View#${key}Option`}
              value={key}
              onClick={(e) => {
                setShowTable(e.currentTarget.value);
                setViewAnchorEl(null);
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          {showTable === 0 ? (
            <>
              <MaterialReactTable table={table} />
            </>
          ) : showTable === 1 ? (
            // creates a graph with all selected rows (if no rows are selected, then graph contains all rows)
            <RouteDefinitionGraph
              data={
                Object.values(rowSelection).some((i) => i)
                  ? data.filter((row) => rowSelection[row.id])
                  : data
              }
              updateSubheader={updateSubheader}
            />
          ) : (
            <RouteDefinitionTimeTable
              data={
                Object.values(rowSelection).some((i) => i)
                  ? data.filter((row) => rowSelection[row.id])
                  : data
              }
            />
          )}
        </Paper>
      </Container>
      <br />
      <br />
      <Container>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
        >
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                component={Link}
                to={
                  assessmentStepZero[
                    analysisStepsViewMemo.state.input_method || 0
                  ].route
                }
                startIcon={<ArrowBackIosNew />}
                disabled={isNavDisabled}
              >
                Previous Step:{" "}
                {
                  assessmentStepZero[
                    analysisStepsViewMemo.state.input_method || 0
                  ].label
                }
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                onClick={(e) =>
                  currentProject?.type == 4 || currentProject?.type == 6
                    ? setAnchorEl(e.currentTarget)
                    : setBlockInputsDialogOpen(true)
                }
              >
                {currentProject?.type == 4 || currentProject?.type == 6
                  ? "Options"
                  : "Route Energy Input"}
              </Button>
              {/* The dropdown options, used when project is a schoolbus type */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{
                  horizontal: "center",
                  vertical: "center",
                }}
                PaperProps={{ style: { minWidth: anchorEl?.clientWidth } }} // makes the dropdown the same size as the button
              >
                <MenuItem
                  onClick={() => {
                    setBlockInputsDialogOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Route Energy Input
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCombineBlocksDialogOpen(true);
                    setAnchorEl(null);
                  }}
                  disabled={isNavDisabled}
                >
                  Link Blocks
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <span>
            <NextPageButton
              sx={{ width: "97.5%" }}
              onClick={handleRouteEnergyCalcPreCheck}
              disabled={
                //check that at least 1 selected row is true
                !Object.values(rowSelection).some((i) => i) ||
                //check that all selected rows match each other
                !sim ||
                !isValidSelection ||
                !depotData.length ||
                isNavDisabled ||
                !accessRights.analysis.create_route_energy_analysis
              }
              loading={buttonLoading}
            >
              Run {stepInfo[STEP_NUMBER + 1].label} Analysis
            </NextPageButton>
          </span>
        </Stack>
      </Container>
      {/* Block Inputs Dialog Box */}
      <Dialog
        component="form"
        onSubmit={handleBlockInputsSubmit}
        open={blockInputsDialogOpen}
        onClose={() => setBlockInputsDialogOpen(false)}
      >
        <DialogTitle>Route Energy Input</DialogTitle>
        <DialogContent style={{ paddingTop: "1rem" }}>
          <BlockInputsForm inputs={blockInputs} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBlockInputsDialogOpen(false)}>
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>

      {/* warning that appears if selected row's depot_id does not match the analysis' selected depot */}
      <Dialog
        fullWidth
        open={depotWarningDialogOpen}
        onClose={() => setDepotWarningDialogOpen(false)}
      >
        <DialogTitle>Alternate Depot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The depot of the selected rows (
            {depotLookup?.[
              table?.getSelectedRowModel()?.rows?.[0]?.getValue("endDepot")
            ] ?? "Not Found"}
            ) does not match the depot selected for the analysis (
            {depotLookup?.[sim?.depot_id]}).
            <br />
            <br />
            If you continue, the selected depot for the analysis will change to
            match the depot of the selected rows.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDepotWarningDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleRouteEnergyCalc}
            loading={buttonLoading}
          >
            Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Edit All dialog for editing rows by vehicle model */}
      <Dialog
        component="form"
        open={groupEditOpen}
        onClose={() => setGroupEditOpen(false)}
        onSubmit={handleGroupEditSave}
        maxWidth="xl"
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Edit rows by group
          <span style={{ display: "flex", alignItems: "center" }}>
            <Typography>Edit Selected Rows</Typography>
            <Switch name="isAllRows" />
            <Typography>Edit All Rows</Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <RouteDefinitionGroupEdit
            data={data}
            rowSelection={rowSelection}
            depotLookup={depotLookup}
            depot_type_size_lookup={vehicleLookup}
            validationErrors={groupEditValidationErrors}
            setValidationErrors={setGroupEditValidationErrors}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroupEditOpen(false)}>Cancel</Button>
          <Button type="submit">Save Group Edit</Button>
        </DialogActions>
      </Dialog>

      {/* Combine Blocks Dialog box */}
      <Dialog
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown={true}
        open={combineBlocksDialogOpen}
        onClose={() => setCombineBlocksDialogOpen(false)}
        PaperProps={{ id: "link-blocks-dialog" }}
      >
        <NestableList
          items={data}
          setData={setData}
          setDialogOpen={setCombineBlocksDialogOpen}
          rowSelection={rowSelection}
          handleSelectionChange={handleSelectionChange}
        />
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={buttonLoading}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">
              <b>Sending data over to {stepInfo[STEP_NUMBER + 1].label}</b>
            </Typography>
          </Container>
        </Container>
      </Backdrop>
    </div>
  );
}
