import { Box, Chip, Stack, TablePagination, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { DataContext } from "../../../contexts/dataContext";
import { MFM_to_AMPM, roundNumber } from "../../utils";
import { LegendChip } from "../commonComponents";

const stepSize = 180; //the time, in minutes, between each step

//common legend also used in drivingActivityTimetable
export const status_label_lookup = {
  driving: { label: "Driving", color: "#0096c7" },
  charging: { label: "Charging", color: "#58bc08" },
  preprocess: { label: "Preprocessing", color: "orange" },
  postprocess: { label: "Postprocessing", color: "orange" },
  plugin: { label: "Plug-in", color: "#f1da7a" }, // sandy
  plugout: { label: "Plug-out", color: "#f1da7a" }, // sandy
  pullin: { label: "Pull-in", color: "yellow" },
  pullout: { label: "Pull-out", color: "yellow" },
  precondition_battery: { label: "Battery Preconditioning", color: "#fe4b03" }, //blood orange
  block_charging: { label: "High Tariff (Not Charging)", color: "black" },
  v2g: { label: "V2G Window", color: "#72ba38" },
  idle_post_v2g: { label: "Bypass Lane (High SoC)", color: "lightskyblue" },
  idle_post_charging: { label: "Idle at Charger", color: "LightGrey" },
  idle_init: { label: "Waiting for Dispatch", color: "LightGrey" },
  precondition_hvac: { label: "Cabin Preconditioning", color: "#ca6641" }, //terracota
};

//common legend clickables also used in drivingActivityTimetable
export const legendClickables = (view, setView) => ({
  onHover: (event, legendItem, _legendElement) => {
    if (
      status_label_lookup.driving.label == legendItem.text ||
      status_label_lookup.charging.label == legendItem.text ||
      "All Other Statuses" == legendItem.text
    )
      event.native.target.style.cursor = "pointer";
    else event.native.target.style.cursor = "default";
    return null;
  },
  onClick: (_event, legendItem, _legendElement) => {
    //disables the legend item toggle clicks (since they don't work with the label override anyway)
    if ("All Other Statuses" == legendItem.text)
      if (view == 2 || view == 3 || view == 4) setView(1);
      else setView(4);
    else if (status_label_lookup.driving.label == legendItem.text)
      if (view == 2) setView(1);
      else if (view == 4) setView(3);
      else if (view == 3) setView(4);
      else setView(2);
    else if (status_label_lookup.charging.label == legendItem.text)
      if (view == 3) setView(1);
      else if (view == 4) setView(2);
      else if (view == 2) setView(4);
      else setView(3);
    return null;
  },
});

/**
 *
 * @param {Object} param0
 * @param {{profile:[]}[]} param0.data
 * @param {1|2|3|4} param0.view 0="load profile" 1="all" 2= "Driving" 3="Charging" 4=Both
 * @param {} param0.setView
 */
export default function VehicleActivityTimeTable({
  data: allData,
  view,
  setView,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(allData?.length || 10);

  const { logo } = useContext(DataContext);
  const chartRef = useRef();

  const data = allData
    .filter((row) => row.profile.length > 1)
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  useEffect(() => {
    //re-renders the chart so that the gradient appears
    function updateChart() {
      chartRef?.current?.update();
    }

    updateChart();
  }, [page, rowsPerPage, view]);

  const dataKeys = {}; //used to filter out the unused legend labels
  //see https://mui.com/material-ui/react-pagination/#TablePagination.js for more info on pagination
  /** changes the page the user is on
   * @param {React.MouseEvent<HTMLButtonElement> | null} e event
   * @param {number} newPage the page to turn to
   */
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  /** increases the number of rows per page shown
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e event
   * @param {number} newPage the page to turn to
   */
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0); // reset the page number to 0
  };

  const data_segments = [];

  //construct dataset
  for (const vehicleIndex in data) {
    const vehicle_data = data[vehicleIndex];
    const vehicle_id = vehicle_data.vehicle_id;
    for (const profileIndex in vehicle_data.profile) {
      const { status, start_time, end_time, start_soc, end_soc, block } =
        vehicle_data.profile[profileIndex];
      if (!dataKeys[status]) dataKeys[status] = true; //used to filter legend labels
      data_segments.push({
        x: [start_time, end_time],
        y: vehicle_id,
        status,
        start_soc,
        end_soc,
        block,
      });
    }
  }

  return (
    <div className="chartdiv">
      <Stack
        sx={{ justifyContent: "space-between", margin: "1%" }}
        direction="row"
        spacing={1}
      >
        <Typography variant="h6">Vehicle Activity Timeline</Typography>
        <Stack direction="row" spacing={1}>
          <LegendChip data={allData} />
          <Chip
            label="Reset Zoom"
            onClick={() => {
              chartRef?.current?.resetZoom();
              chartRef?.current?.update();
            }}
          />
        </Stack>
      </Stack>
      <PureVehicleActivityTimeTable
        data={data}
        data_segments={data_segments}
        view={view}
        setView={setView}
        props={{ ref: chartRef }}
      />
      <div
        style={{ bottom: "100px", right: "100px" }}
        className="watermark-container"
      >
        <img src={logo} width="auto" height="50px" className="watermark-img" />
      </div>

      <Box display="flex" justifyContent="right">
        <TablePagination
          component="div" //avoids a warning message regarding <td>
          count={allData.filter((row) => row.profile.length > 1).length}
          page={page}
          onPageChange={handlePageChange}
          showFirstButton
          showLastButton
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[
            10,
            20,
            40,
            80,
            { label: "All", value: allData.length },
          ]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </div>
  );
}

/**
 * @param {Object} param0
 * @param {import("chart.js").ChartOptions} [param0.options] any additional custom ChartJSOptions
 * @param {import("react-chartjs-2").Bar} param0.props
 */
export function PureVehicleActivityTimeTable({
  data,
  data_segments,
  view,
  setView = () => null,
  options = undefined,
  props = undefined,
}) {
  /** @type {import("chart.js").ChartOptions} */
  const chartOptions = {
    ...options,
    indexAxis: "y",
    interaction: { intersect: false, mode: "nearest", ...options?.interaction },
    animations: false,
    scales: {
      ...options?.scales,
      x: {
        ticks: {
          font: { size: 16 },
          callback: (val) => MFM_to_AMPM(val),
          stepSize: stepSize,
        },
        title: { display: true, text: "Time of Day", font: { size: 16 } }, //label for x-axis
        min: 2880,
        max: 4320,
        stacked: true,
        ...options?.scales?.x,
      },
      y: {
        ticks: { maxTicksLimit: 20 },
        title: { display: true, text: "Vehicle ID", font: { size: 16 } },
        stacked: true,
        ...options?.scales?.y,
      },
    },
    layout: { padding: { right: 100 }, ...options?.layout },
    plugins: {
      legend: {
        ...legendClickables(view, setView),
        labels: {
          //filters out labels not included in datasets
          filter: (item) =>
            [
              status_label_lookup.charging.label,
              status_label_lookup.driving.label,
              "All Other Statuses",
            ].includes(item.text),
          boxWidth: 13,
        },
      },
      zoom: {
        pan: { enabled: true, mode: "y", modifierKey: "ctrl" },
        zoom: { drag: { enabled: true }, mode: "y" },
      },
      ...options?.plugins,
      tooltip: {
        intersect: true, // despite claiming that this is the default, tooltip doesn't work properly unless it's explicitly defined
        position: "mouse",
        callbacks: {
          title: (value) => `Vehicle Model: ${value[0].label}`,
          label: (value) => {
            const label = [
              `${MFM_to_AMPM(value.raw.x[0])} - ${MFM_to_AMPM(value.raw.x[1])}`,
              `${status_label_lookup[value.raw.status].label} ${
                value.raw.status == "driving" ? `on ${value.raw.block}` : ""
              }`,
            ];
            if (value.raw.status == "driving" || value.raw.status == "charging")
              label.push(
                `Charge: ${roundNumber(
                  value.raw.start_soc,
                  0
                )}% - ${roundNumber(value.raw.end_soc, 0)}%`
              );
            return label;
          },
        },
        yAlign: "top",
        ...options?.plugins?.tooltip,
      },
    },
  };

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    labels: data?.map((block) => block.vehicle_id),
    datasets: [
      ...[
        status_label_lookup.driving,
        status_label_lookup.charging,
        { label: "All Other Statuses", color: "white", borderColor: "black" },
      ].map(({ label, color, borderColor }) => ({
        //generates the legend for the values with the proper colors
        label: label,
        data: [],
        borderWidth: 1,
        borderColor: borderColor ?? color,
        backgroundColor: color,
      })),
      {
        label: "Time of Day",
        data: data_segments,
        borderRadius: 10, //curves the bars
        borderColor: "white",
        borderWidth: 1,
        borderSkipped: false, //curve affects both sides
        backgroundColor: (context) => {
          const { ctx, chartArea } = context?.chart;
          //creates a gradient color transition for the line's background (gradient is top left to bottom right)
          let gradient;
          if (context?.raw?.status == "charging") {
            gradient = ctx.createLinearGradient(
              context?.element?.base || 0,
              Math.ceil(context?.element?.y) || 0,
              Math.ceil(context?.element?.base + context?.element?.width) ||
                2000,
              Math.ceil(context?.element?.height + context?.element?.y - 25) ||
                500
            );
            gradient.addColorStop(
              1,
              status_label_lookup[context?.raw?.status]?.color
            );
            gradient.addColorStop(
              0,
              status_label_lookup[context?.raw?.status]?.color + "70"
            );
          } else if (context?.raw?.status == "driving") {
            gradient = ctx.createLinearGradient(
              context?.element?.base || 0,
              Math.ceil(context?.element?.y) || 0,
              Math.ceil(
                context?.element?.base + context?.element?.width + 100
              ) || 2000,
              Math.ceil(context?.element?.height + context?.element?.y - 25) ||
                500
            );
            gradient.addColorStop(
              0,
              status_label_lookup[context?.raw?.status]?.color
            );
            gradient.addColorStop(1, "#fffe");
          } else gradient = status_label_lookup[context?.raw?.status]?.color;

          return gradient;
        },
      },
    ],
  };

  return <Bar {...props} data={chartData} options={chartOptions} />;
}
