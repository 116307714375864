import { createContext, useRef } from "react";
import SnackbarAlert from "../components/secondary/snackBar";

export const SnackBarContext = createContext();

const SnackBarContextProvider = (props) => {
  const snackBarElement = useRef();

  return (
    <>
      <SnackBarContext.Provider value={{ snackBarElement }}>
        {props.children}
      </SnackBarContext.Provider>
      <SnackbarAlert ref={snackBarElement} />
    </>
  );
};

export default SnackBarContextProvider;
