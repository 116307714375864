import Typography from "@mui/material/Typography";

export default function NotAuthorized() {
  return (
    <div className="centered">
      <Typography variant="h3" gutterBottom component="div">
        You don't have adequate permissions to do that.
      </Typography>
    </div>
  );
}
