import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import Empty from "./empty";

/** 10-second Cicular Progress Bar that throws an error upon timeout
 * @deprecated -- instead, display a loading message until response is fetched/error message if fetch fails
 * @param {Object} props
 * @param {string} props.text - the message to display on timeout
 * @param {number} [props.delay] - optional parameter, allows for custom wait time
 */
const TimedCircularProgress = (props) => {
  const [loading, setLoading] = useState(true); //if it ever reaches false, then display the error message

  useEffect(() => {
    const delay = props.delay || 5000; //allows for custom times

    const timerId = setTimeout(() => timerCallback(), delay);
    const timerCallback = () => setLoading(false); //fires when time runs out, if loading bar is still visible

    return () => clearTimeout(timerId); //fires on unmount of component (i.e. if page is left before timeout )
  }, []);

  return (
    <div>
      {loading ? (
        <Fade in={loading}>
          <div className="centered">
            <CircularProgress />
          </div>
        </Fade>
      ) : (
        <Empty text={props.text} />
      )}
    </div>
  );
};

export default TimedCircularProgress;
