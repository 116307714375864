import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export default function Empty(props) {
  return (
    <Container>
      <div className="centered">
        {" "}
        <Typography variant="h3" gutterBottom component="div">
          {props.text}
        </Typography>
      </div>
    </Container>
  );
}
