import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  brandActivationToggleURL,
  brandURL,
} from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { errorHandler } from "../utils";

/**
 * The branding create/update/delete/activate/deactivate menuItems,
 * dialogs, and associated functions
 * @param {Object} props
 * @param {Object} props.rowData
 * @param {import("react").Dispatch<import("react").SetStateAction<never[]>>} props.setData
 * @param {Function} props.actionsClose
 */
export default function BrandingOptions(props) {
  const { rowData, setData, actionsClose } = props;

  const [isModifyBrandDialog, setIsModifyBrandDialog] = useState(0); //0 for closed, 1 for creating brand, 2 for updating brand
  const [isDeleteBrandDialog, setIsDeleteBrandDialog] = useState(false);
  const [isToggleStatusDialog, setIsToggleStatusDialog] = useState(false);
  const [subdomain, setSubdomain] = useState(rowData.subdomain || "");
  const [loading, setLoading] = useState(false);

  const { snackBarElement } = useContext(SnackBarContext);
  const { accessRights } = useContext(DataContext);

  const brandModificationText = { 0: "Unknown", 1: "Create", 2: "Update" }[
    isModifyBrandDialog
  ];

  const brandStatusText = () => {
    switch (rowData.brand_status) {
      // display "Enable" if does not exist, or disabled/disabling
      case 0:
      case 5:
      case 6:
        return "Enable";
      default:
        return "Disable";
    }
  };

  function handleCloseDialog() {
    setIsModifyBrandDialog(0);
    setIsDeleteBrandDialog(false);
    setIsToggleStatusDialog(false);
    actionsClose();
  }

  function handleSaveChanges(res) {
    res.json().then((response) =>
      setData((data) => {
        const index = data.findIndex((row) => row.id == response.data.id);
        data[index] = { ...data[index], ...response.data };
        return [...data];
      })
    );
    handleCloseDialog();
  }

  /**
   * Create/Update brand
   * @param {SubmitEvent} event
   */
  function handleModify(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = {
      organization_id: rowData.id,
      subdomain: formJSON.subdomain,
    };

    setLoading(true);

    fetch(brandURL, {
      method: isModifyBrandDialog == 1 ? "POST" : "PATCH",
      headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) handleSaveChanges(res);
        else
          errorHandler(
            res,
            snackBarElement,
            `Failed to ${brandModificationText} brand`
          );
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          `Error: Failed to ${brandModificationText.toLocaleLowerCase()} brand`,
          "error",
          5000
        );
      })
      .finally(() => setLoading(false));
  }

  /**
   * Delete brand
   * @param {SubmitEvent} event
   */
  function handleDelete(event) {
    event.preventDefault();
    setLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({ organization_id: rowData.id });

    fetch(brandURL, { method: "DELETE", headers, body })
      .then((res) => {
        if (res.ok) handleSaveChanges(res);
        else errorHandler(res, snackBarElement, "Failed to delete brand");
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network Error occured while deleting brand",
          "error",
          7000
        );
      })
      .finally(() => setLoading(false));
  }

  /**
   * Activate/Deactivate brand
   * @param {SubmitEvent} event
   */
  function handleEnabling(event) {
    event.preventDefault();
    setLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({ organization_id: rowData.id });

    fetch(brandActivationToggleURL, { method: "POST", headers, body })
      .then((res) => {
        if (res.ok) handleSaveChanges(res);
        else
          errorHandler(res, snackBarElement, "Failed to toggle brand status");
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network Error occured while toggling brand status",
          "error",
          7000
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      {/* MenuOptions */}
      {accessRights.admin.create_brand && rowData.brand_status == 0 && (
        <MenuItem onClick={() => setIsModifyBrandDialog(1)}>
          Create Brand
        </MenuItem>
      )}
      {accessRights.admin.update_brand && rowData.brand_status == 2 && (
        <MenuItem
          onClick={() => setIsModifyBrandDialog(2)}
          disabled={rowData.brand_status == 0}
        >
          Update Brand
        </MenuItem>
      )}
      {accessRights.admin.activate_deactivate_brand &&
        (rowData.brand_status == 2 || rowData.brand_status == 6) && (
          <MenuItem
            onClick={() => setIsToggleStatusDialog(true)}
            disabled={!(rowData.brand_status == 2 || rowData.brand_status == 6)}
          >
            {brandStatusText()} Brand
          </MenuItem>
        )}
      {accessRights.admin.delete_brand &&
        (rowData.brand_status == 2 || rowData.brand_status == 6) && (
          <MenuItem
            onClick={() => setIsDeleteBrandDialog(true)}
            disabled={rowData.brand_status == 0}
          >
            Delete Brand
          </MenuItem>
        )}
      {/* create/update brand dialog */}
      <Dialog
        fullWidth
        component="form"
        open={Boolean(isModifyBrandDialog)}
        onSubmit={handleModify}
        onClose={handleCloseDialog}
        transitionDuration={{ exit: 0 }}
      >
        <DialogTitle>{brandModificationText} Brand</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <TextField
            name="organization_id"
            label="Organization"
            value={rowData.name}
            inputProps={{ disabled: true }}
          />
          <TextField
            required
            name="subdomain"
            label="Subdomain"
            value={subdomain}
            onChange={(e) =>
              setSubdomain(
                e.target.value
                  .toLocaleLowerCase()
                  .replaceAll(" ", "_")
                  .replaceAll(".", "-")
              )
            }
            helperText={`${subdomain || "*"}.evopt.com`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton loading={loading} type="submit">
            {brandModificationText}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* delete brand dialog */}
      <Dialog
        component="form"
        open={isDeleteBrandDialog}
        onSubmit={handleDelete}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Delete Brand</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {rowData.name}'s brand?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton loading={loading} type="submit">
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* toggle brand status dialog */}
      <Dialog
        component="form"
        open={isToggleStatusDialog}
        onSubmit={handleEnabling}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{brandStatusText()} Brand</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {brandStatusText().toLocaleLowerCase()}{" "}
            {rowData.name}'s brand?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton loading={loading} type="submit">
            {brandStatusText()}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
